import React from 'react';

import banner from '../../images/alumni-impact-program-banner.png';

import AlumniImpactProgramSlider from './AlumniImpactProgramSlider';
import RightSideSlider from './RightSideSlider';
import LeftSideSlider from './LeftSideSlider';

import CountUp from 'react-countup';

import VideoModalPopup from '../VideoModalPopup';
import VideoImgModalPopup from '../VideoImgModalPopup';


function AlumniImpactProgram() {
  return (
    <>
      <section className="container-fluid headerBanner alumni_impact_banner" id="headerBanner">
            <div className="w-1210 mx-auto pt-3 mb-5 mb-lg-0">
                <div className="row g-4">
                  <div className="col-md-12">
                    <div className="w-1080 mx-auto">
                        <div className="row g-5 align-items-center">
                            <div className="col-md" data-aos="fade-left" data-aos-delay="200">
                                <h1>Pioneering India’s Most Comprehensive Alumni Networking & Management Solution</h1>                                  
                                <VideoModalPopup className="btn-bookdemo grdGreenBlue video-btn border-0" videoId="w7f7A8IkGsE" title="Book A Demo"/>
                            </div>
                            <div className="col-md-auto text-center">                               
                                <VideoImgModalPopup className="video-btn" videoId="w7f7A8IkGsE" banner={banner} imgWidth={506}/>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div className="col-md-12" data-aos="fade-up" data-aos-delay="200">
                    <div className="horizontal-scroll">
                        <ul className="banner-white-block-wrap">
                            <li>
                                <div className="banner-white-block" >
                                    <div className="icon me-3"><img src="images/backpack.svg" alt=""/></div>
                                    <div className="blockBrife counter-box">
                                        <div className="number count counter-value count-title" data-count="350"><CountUp end={350} duration={2}>
      {({ countUpRef }) => (
        <span ref={countUpRef} />
      )}
    </CountUp> <span>+</span></div>
                                        <div className="title"> Educational Institutes </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="banner-white-block">
                                    <div className="icon me-3"><img src="images/video-conference.svg" alt=""/></div>
                                    <div className="blockBrife counter-box">
                                        <div className="number count counter-value count-title" data-count="100000"><CountUp end={100000} duration={2}>
      {({ countUpRef }) => (
        <span ref={countUpRef} />
      )}
    </CountUp> <span>+</span></div>
                                        <div className="title">Alumni Journeys</div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="banner-white-block">
                                    <div className="icon me-3"><img src="images/consultation.svg" alt=""/></div>
                                    <div className="blockBrife counter-box">
                                        <div className="number count counter-value count-title" data-count="40000"><CountUp end={40000} duration={2}>
      {({ countUpRef }) => (
        <span ref={countUpRef} />
      )}
    </CountUp> <span>+</span></div>
                                        <div className="title">Students Guided</div>
                                    </div>
                                </div>
                            </li>
                           
                        </ul>
                    </div>
                    
                  </div>
                </div>
            </div>
        </section>

        <section className="container-fluid alumniImpactProgram" id="alumniImpactProgram" data-aos="fade-up" data-aos-delay="200">
            <div className="w-1200 mx-auto" >
                <h2>Why Choose Alumni Impact Program</h2>
                
                <p>The Alumni Impact Program is a one-of-a-kind alumni networking and management solution for schools.<br className="d-none d-lg-block" /> This Software as a Service model allows schools to successfully build a thriving alumni network.<br className="d-none d-lg-block" /> This network is then utilised for marketing and new admissions.</p>
                <div data-aos="fade-left" data-aos-delay="600">
                    
                    <AlumniImpactProgramSlider/>
                </div>
            </div>
        </section>

        <section className="trustedCompanies" id="trustedCompanies">

            <h2 data-aos="fade-up" data-aos-delay="200">Our Partner Schools</h2>
            <p>We work with 350+ Top Educational Institutes to enable Alumni Networking & Management</p>

            <div data-aos="fade-left" data-aos-delay="200">
                <LeftSideSlider/>
            </div>
            

            <div data-aos="fade-right" data-aos-delay="200">
                <RightSideSlider/>
            </div>
            
            
        </section>

        <section className="container-fluid faq" id="faq">
            <div className="w-1200 mx-auto">
                
                
                <div className="row g-4">
                    <div className="col-md-4">
                        <h2 data-aos="fade-left" data-aos-delay="600">Frequently Asked Questions</h2>
                        <p className="text-darkblue" data-aos="fade-left" data-aos-delay="600">Here’s the answer to some of your burning questions about Alumni Impact Program</p>
                    </div>
                    <div className="col-md-8">
                        <div className="accordion" id="accordionFaq" data-aos="fade-right" data-aos-delay="600">
                            <div className="accordion-item">
                              <h2 className="accordion-header">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqOne" aria-expanded="true" aria-controls="faqOne">
                                    What is alumni management software?
                                </button>
                              </h2>
                              <div id="faqOne" className="accordion-collapse collapse show" data-bs-parent="#accordionFaq">
                                <div className="accordion-body">
                                  <p>An alumni management software helps in collecting, storing, and tracking alumni data of an institution. Having an easy-to-use and accessible alumni management software is crucial to build an alumni network. However, having software alone does not build a community. With Univariety’s Alumni Impact Program, you get access to alumni management software as well as support for data collection and utilisation of the data for school success. </p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqTwo" aria-expanded="false" aria-controls="faqTwo">
                                    What is Alumni Impact Program and how does it benefit schools?
                                </button>
                              </h2>
                              <div id="faqTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                <div className="accordion-body">
                                    <p>Alumni Impact Program is a comprehensive Alumni Networking and Management solution for schools. It allows schools to build a digital database of their alumni, connect with them and track their success journey. These alumni success stories are then utilised for school marketing and brand building. The school can leverage its alumni’s expertise to provide guidance and mentorship for current students as well.</p>
                                  </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqThree" aria-expanded="false" aria-controls="faqThree">
                                    What is an Alumni Database and what can you do with it?
                                </button>
                              </h2>
                              <div id="faqThree" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                <div className="accordion-body">
                                    
                                    <p>Alumni database is a digital file containing all relevant information regarding the alumni of a school - college admission, scholarships, career achievements, and more. The database is built and accessible on the dedicated alumni management software of the institution.</p>
                                    <p>With our Alumni Impact Program, we offer schools alumni management software as well as build their alumni database. An alumni database ensures your school’s most important assets are utilised for marketing, admission, and brand-building purposes. </p>
                                  </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqFour" aria-expanded="false" aria-controls="faqFour">
                                    How does the Alumni Impact Program help schools manage alumni?
                                  </button>
                                </h2>
                                <div id="faqFour" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                  <div className="accordion-body">
                                      <p>The Alumni Impact Program connects the school, its students, and alumni in one ecosystem. The digital platform allows schools to manage all alumni information in a secure centralised database. Schools can keep track of their alumni, connect with them for school events and activities, and enable student guidance. </p>
                                    </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqFive" aria-expanded="false" aria-controls="faqFive">
                                    How do I create an Alumni Database?
                                  </button>
                                </h2>
                                <div id="faqFive" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                  <div className="accordion-body">
                                      <p>With the Alumni Impact Program, we help schools build a digital alumni database with up-to-date information that they can access in a few clicks. The data can be filtered based on different parameters and generate reports. We use both offline and digital engagement to connect with the school’s alumni to gain information.</p>
                                    </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqSix" aria-expanded="false" aria-controls="faqSix">
                                    What is the pricing for the Alumni Impact Program?
                                  </button>
                                </h2>
                                <div id="faqSix" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                  <div className="accordion-body">
                                      <p>Alumni Impact Program follows a self-sustainable business model. The price depends on the number of students and alumni onboarding. To know more about the pricing of the Alumni Impact Program, connect with our experts.</p>
                                    </div>
                                </div>
                              </div>
                          </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default AlumniImpactProgram
