import React from 'react'
import FormRegisterPopUp from '../FormRegisterPopUp'

function Preferences() {
  return (
    <>
      <div className="preferences-detail tab-white-block">
                            {/* <a className="pencil-edit" href="javascript:;" data-bs-toggle="modal" data-bs-target="#basic_info_popup"></a> */}
                        <FormRegisterPopUp className="pencil-edit"/>
                            <div className="customRow">
                               <div className="customRow-header">
                                    <div className="icon icon-book"></div>
                                    <div className="customRow-header-title">
                                        <span className="font-20 font-500">Course</span>
                                    </div>
                               </div>
                               <div className="customRow-detail">
                                    <div className="row g-3">
                                        <div className="col-md-4">
                                            <div className="d-flex flex-column">
                                                <div className="font-16 font-400 lineHeight-22 mb-1">First Preference</div>
                                                <div className="font-18 font-500 lineHeight-22">Architecture-Architecture &amp; Planning Studies</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="d-flex flex-column">
                                                <div className="font-16 font-400 lineHeight-22 mb-1">Second Preference</div>
                                                <div className="font-18 font-500 lineHeight-22">Engineering &amp; Technology Mechanical &amp; Industrial Engineering</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="d-flex flex-column">
                                                <div className="font-16 font-400 lineHeight-22 mb-1">Third Preference</div>
                                                <div className="font-18 font-500 lineHeight-22">-</div>
                                            </div>
                                        </div>
                                    </div>
                               </div>
                            </div>

                            <div className="customRow">
                                <div className="customRow-header">
                                     <div className="icon icon-global"></div>
                                     <div className="customRow-header-title">
                                         <span className="font-20 font-500">Country</span>
                                     </div>
                                </div>
                                <div className="customRow-detail">
                                     <div className="row g-3">
                                         <div className="col-md-4">
                                             <div className="d-flex flex-column">
                                                 <div className="font-16 font-400 lineHeight-22 mb-1">First Preference</div>
                                                 <div className="font-18 font-500 lineHeight-22">Germany</div>
                                             </div>
                                         </div>
                                         <div className="col-md-4">
                                             <div className="d-flex flex-column">
                                                 <div className="font-16 font-400 lineHeight-22 mb-1">Second Preference</div>
                                                 <div className="font-18 font-500 lineHeight-22">India</div>
                                             </div>
                                         </div>
                                         <div className="col-md-4">
                                             <div className="d-flex flex-column">
                                                 <div className="font-16 font-400 lineHeight-22 mb-1">Third Preference</div>
                                                 <div className="font-18 font-500 lineHeight-22">-</div>
                                             </div>
                                         </div>
                                     </div>
                                </div>
                             </div>

                             <div className="customRow">
                                <div className="customRow-header">
                                     <div className="icon icon-university-college"></div>
                                     <div className="customRow-header-title">
                                         <span className="font-20 font-500">College</span>
                                     </div>
                                </div>
                                <div className="customRow-detail">
                                     <div className="row g-3">
                                         <div className="col-md-4">
                                             <div className="d-flex flex-column">
                                                 <div className="font-16 font-400 lineHeight-22 mb-1">First Preference</div>
                                                 <div className="font-18 font-500 lineHeight-22">IIIT Bangalore</div>
                                             </div>
                                         </div>
                                         <div className="col-md-4">
                                             <div className="d-flex flex-column">
                                                 <div className="font-16 font-400 lineHeight-22 mb-1">Second Preference</div>
                                                 <div className="font-18 font-500 lineHeight-22">A V College of Arts, Science and Commerce</div>
                                             </div>
                                         </div>
                                         <div className="col-md-4">
                                             <div className="d-flex flex-column">
                                                 <div className="font-16 font-400 lineHeight-22 mb-1">Third Preference</div>
                                                 <div className="font-18 font-500 lineHeight-22">-</div>
                                             </div>
                                         </div>
                                     </div>
                                </div>
                             </div>

                             <div>
                                <span>Planning to join in :</span> <span className="font-18 font-500">Sep-Dec 2023</span>
                             </div>                           
                        </div>
    </>
  )
}

export default Preferences
