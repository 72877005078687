import React, { useState } from 'react';

import PlaneImg from '../../../images/plane-img.png';
import AddedImgLg from '../../../images/added-img-lg.png';

import videoIcon from '../../../images/video-svg.png';

import CustomisedFormPopup from '../../Blog/CustomisedFormPopup';

function EditVersion() {

    const [shade, setShade] = useState(false);

    const handleDarkShade = () => {
      setShade(current => !current);
    };
  
  return (
    <>
      <main className="page-wrap bg-gray-f5 template_4">
                <div className="container-fluid">
                    <div className="container max-1210 py-100">
                        <div className="row">
                    
                            <div className="col-lg-6 col-md-7 mb-5 mb-md-0">
                                <div className="pe-md-4">
                                    <h1 className="title mb-2 mb-sm-4" contenteditable="true">Taking A Leaf<br className="d-none d-lg-block"/> Out Of the Indigo Airlines Approach</h1>
                                    <p contenteditable="true">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus, ac blandit elit tincidunt id. Sed rhoncus, tortor sed eleifend tristique, tortor mauris molestie elit, et lacinia ipsum quam nec dui. Quisque nec mauris sit amet elit iaculis pretium sit amet quis magna. Aenean velit odio, elementum in tempus ut, vehicula eu diam. </p>
                                    <p contenteditable="true">Pellentesque rhoncus aliquam mattis. Ut vulputate eros sed felis sodales nec vulputate justo hendrerit. Vivamus varius pretium ligula, a aliquam odio euismod sit amet. Quisque laoreet sem sit amet orci ullamcorper at ultricies metus viverra. Pellentesque arcu mauris, malesuada quis ornare accumsan, blandit sed diam.</p>
                                   
                                    <div className="d-flex flex-wrap align-items-center pt-2">
                                      <a className="btn btn-green" href="javascript:;" > <span> Stories From My School</span></a>
                                      
                                      <CustomisedFormPopup className="btn btn-customize"  />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5">
                              <div className="position-relative" data-img="480px * 501px">
                                <img src={PlaneImg} className="img-fluid" width="570"/>
                                <label className="upload-img position-absolute" for="upload-img">
                                    <span className="btn-browse"><i className="me-2"><img src={videoIcon}/></i>Upload Image</span>
                                    
                                    <input className="d-none" type="file" id="upload-img"/>
                                  </label>
                            </div>
                            </div>
                        </div>
                    </div>
              
                </div>
                <div className="fold-wrap">     
                  <div className="fold-section p-0">
                    <button className="btn btn-danger"> <span className="btn-close btn-close-white"></span> Delete Fold</button>
                
                    <div className="fold-section-ui">
                        <div className="row">
                            <div className="col-md-6 mb-4 mb-md-0 text-center" data-img="550px * 450px">
                                <img src={AddedImgLg} className="img-fluid" width="550"/>
                            </div>
                       
                            <div className="col-md-6">
                                <h1 contenteditable="true">Heading Goes Here</h1>
                                <p contenteditable="true">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus…</p>
                                <ul className="listed-points" contenteditable="true">
                                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                                    <li>Lorem ipsum dolor sit amet, consectetur.</li>
                                    <li>Lorem ipsum dolor sit amet, consectetur.</li>
                                </ul>
                                
                                <div className="d-flex flex-wrap align-items-center pt-2">
                                  <a className="btn-green" href="javascript:;" >CTA BUTTON</a>
                                  
                                  <CustomisedFormPopup className="btn btn-customize"/>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                  </div>
    
            </div>
    
        
        
                
            </main>
    </>
  )
}

export default EditVersion;
