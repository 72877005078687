import React from 'react';
import BlogListing from './BlogListing';



function BlogList() {
  return (
    <>
      <div className="mainbanner">           
            <span className="caption">School Success Story</span>
        </div>

        <div className="page-content">
            <div className="categories-tab">
                <ul className="nav nav-tabs nav-pills-hor" id="personalityTypes-Tab" role="tablist">
                    <li className="nav-item active" role="presentation">
                        <a className="nav-link font-14 font-500 active" id="analyst-tab" data-bs-toggle="tab" href="#analyst" type="button" role="tab" aria-controls="PersonalityTypesTab" aria-selected="true">All Categories</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link font-14 font-500" id="diplomats-tab" data-bs-toggle="tab" href="#diplomats" type="button" role="tab" aria-controls="PersonalityTypesTab" aria-selected="false">Alumni Engagement</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link font-14 font-500" id="sentinels-tab" data-bs-toggle="tab" href="#sentinels" type="button" role="tab" aria-controls="PersonalityTypesTab" aria-selected="false">School Branding &amp; Admissions</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link font-14 font-500" id="explorer-tab" data-bs-toggle="tab" href="#explorer" type="button" role="tab" aria-controls="PersonalityTypesTab" aria-selected="false">Students Guidance</a>
                    </li>
                </ul>
            </div>

            <div className="blogWrap">
                <BlogListing/>
            </div>

        </div>
    </>
  )
}

export default BlogList;
