import React from 'react';

import YouTubeThumb from '../../../images/black-banner.png';
import VideoImgModalPopup from '../../VideoImgModalPopup';

import DummyAddImg from '../../../images/added-img.png';
import DummyAddImgLg from '../../../images/added-img-lg.png';

import RecentImgThumba1 from '../../../images/rectangle-12885.png';
import RecentImgThumba2 from '../../../images/rectangle-12887.png';
import RecentImgThumba3 from '../../../images/rectangle-12889.png';
import SingleColForm from '../../SingleColForm';

function BlogTemplate1() {
  return (
    <>
      <div className="mainbanner realGullyBoyBanner">
        <span className="caption">
          The Real Gully Boy
          <span className="sub-caption">
            Five Star Chef From Dharavi - An Alumni Story
          </span>
          <span className="date">Aug 3, 2022</span>
        </span>
        <div className="scrolldown d-flex justify-content-center align-items-center">
          <b className="float_animate">#</b>
        </div>
      </div>

      <div className="bg-white container-fluid py-100">
        <div className="max-1140 blog-detail-page mx-auto">
          <div className="row">
            <div className="col-md-12 page-title mb-3 mb-sm-5">
              <h1 className="title mb-2 mb-sm-4">Dinesh Narasimha Kansane</h1>
              <p className="sub-tite mb-3">Student of SSRVM, Dharavi</p>
            </div>
            <div className="col-lg">
              <div className="pe-md-5">
                <p className='text-start'>
                  For more than a decade in any student’s life, the school
                  serves as the second home and teachers and other staff members
                  play the role of a guardian. The impact they have on students
                  is something that stays with them long after they have
                  graduated from school.
                </p>
                <p className='text-start'>
                  For more than a decade in any student’s life, the school
                  serves as the second home and teachers and other staff members
                  play the role of a guardian. The impact they have on students
                  is something that stays with them long after they have
                  graduated from school.
                </p>
                <a className="btn-green" href="javascript:;" data-bs-toggle="modal" data-bs-target="#downloadForm">
                  Stories From My School
                </a>
              </div>
            </div>
            <div className="col-lg-auto">
              <div className="videoSection blackBanner mt-5 mt-lg-0 d-flex justify-content-center">
                <VideoImgModalPopup className="video-btn" videoId="w7f7A8IkGsE" banner={YouTubeThumb} imgWidth={523}/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-gray-f3">
        <div className="row">
          <div className="col-lg-6">
            <div className="row">
              <div className="leftPaneImg"></div>
            </div>
          </div>
          <div className="col-lg-6 d-flex flex-column justify-content-center">
            <div className="row">
              <div className="col-xl-7 col-lg-12 col-md-12 py-5">
                <div className='rightpane'>
                  <h2>Lorem ipsum dolor sit amet</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                    quam velit, vulputate eu pharetra nec, mattis ac neque. Duis
                    vulputate commodo lectus,
                  </p>
                  <ul>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </li>
                    <li>Lorem ipsum dolor sit amet, consectetur.</li>
                    <li>Lorem ipsum dolor sit consectetur adipiscing elit.</li>
                  </ul>
                  <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#downloadBrochure" className="btn-download">
                    <i></i>
                    Download Brochure
                  </a>
                </div>
                
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row fold-wrap">
              <div className="fold-section p-0">
                <div className="fold-section-ui">
                  <div className="row">
                    <div className="col-md-6 text-center" data-img="550px * 306px">
                      <img className="img-fluid" src={DummyAddImg} width="550"/>
                    </div>
                    <div className="col-md-6">
                      <div className="videoSection blackBanner mt-5 mt-md-0 d-flex justify-content-center">
                      <VideoImgModalPopup className="video-btn" videoId="w7f7A8IkGsE" banner={YouTubeThumb} imgWidth={506}/>
                      </div>
                    </div>
                    <div className="col-md-12 mt-4">
                      <h1>Heading Goes Here</h1>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nulla quam velit, vulputate eu pharetra nec, mattis ac
                        neque. Duis vulputate commodo lectus, vulputate eu
                        pharetra nec, mattis ac neque. Duis vulputate commodo
                        lectus…
                      </p>
                      <ul className="listed-points">
                        <li>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </li>
                        <li>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </li>
                        <li>Lorem ipsum dolor sit amet, consectetur.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur.</li>
                      </ul>
                      <a className="btn-green" href="javascript:;" data-bs-toggle="modal" data-bs-target="#downloadForm">
                        CTA BUTTON
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="fold-section p-0">
                <div className="fold-section-ui">
                  <div className="row">
                    <div className="col-md-6 mb-4 mb-md-0 text-center" data-img="550px * 450px">
                      <img className="img-fluid" src={DummyAddImgLg} width="550"/>
                    </div>

                    <div className="col-md-6">
                      <h1>Heading Goes Here</h1>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nulla quam velit, vulputate eu pharetra nec, mattis ac
                        neque. Duis vulputate commodo lectus, vulputate eu
                        pharetra nec, mattis ac neque. Duis vulputate commodo
                        lectus…
                      </p>
                      <ul className="listed-points">
                        <li>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </li>
                        <li>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </li>
                        <li>Lorem ipsum dolor sit amet, consectetur.</li>
                        <li>Lorem ipsum dolor sit amet, consectetur.</li>
                      </ul>
                      <a className="btn-green" href="javascript:;" data-bs-toggle="modal" data-bs-target="#downloadForm">
                        CTA BUTTON
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 bg-white py-80">
            <div className="row justify-content-end">
              <div className="col-xl-auto col-lg-12 col-md-12 leftPane">
                <h2>Recent Stories</h2>
                <ul className="recent_stories">
                  <li>
                    <div className="recent_stories_thumb" data-img="191px * 134px">
                      <img src={RecentImgThumba1}/>
                    </div>
                    <div className="recent_stories_detail">
                      Educators, Master the Art of Online Teaching with “Teach
                      Online”
                      <span className="date">Aug 3, 2022</span>
                    </div>
                    <a href="javascript:;" className="recentLink"></a>
                  </li>
                  <li>
                    <div className="recent_stories_thumb" data-img="186px * 130px">
                      <img src={RecentImgThumba2}/>
                    </div>
                    <div className="recent_stories_detail">
                      Let The Prep Begin! 6 Tips to Ace Your College Admission
                      Interview!
                      <span className="date">Aug 3, 2022</span>
                    </div>
                    <a href="javascript:;" className="recentLink"></a>
                  </li>
                  <li>
                    <div className="recent_stories_thumb" data-img="196px * 137px">
                      <img src={RecentImgThumba3}/>
                    </div>
                    <div className="recent_stories_detail">
                      What It Will Take For Your Winning School Election Next
                      Year!
                      <span className="date">Aug 3, 2022</span>
                    </div>
                    <a href="javascript:;" className="recentLink"></a>
                  </li>
                </ul>
                <a href="javascript:;" className="btn-download">View More</a>
              </div>
            </div>
          </div>
          <div className="col-md-6 bg-form py-80" id="alumnistories">
            <div className="row">
              <div className="col-xl-auto col-lg-12 col-md-12 rightpane">
                <h3>
                  DISCOVER YOUR
                  <br className="d-none d-lg-block"/>
                  OWN ALUMNI STORIES
                </h3>
                <div className="rightSideForm">
                  <SingleColForm/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BlogTemplate1;
