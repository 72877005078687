import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CustomisedFormPopup from './CustomisedFormPopup';

import CloudComputer from '../../images/cloud-computing.svg';
import videoIcon from '../../images/video-svg.png';
import FoldImg from '../../images/gallery.png';
import HeadingImg from '../../images/_x33_5.Heading1.svg';
import ParaImg from '../../images/paragraph.svg';
import ListImg from '../../images/list-interface-symbol.svg';
import ButtonImg from '../../images/button_icon.svg';

import AddImg from '../../images/34-Add-image.svg';
import VideCameraImg from '../../images/video-camera.svg';

function FoldModal(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  }

  const handleShow = (event) => {
    setShow(true);
  }

  return (
    <>
        <a href="javascript:;"  className={props.className} onClick={handleShow} >
          ADD FOLD
        </a>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size=" max-1366"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="customize-form"
      >
       
        <Modal.Body className='p-0'>
     
        <div className="modal-content border-radius-10 overflow-hidden">
          <div className="modal-header p-40 py-3 bg-liteblue border-0">
            <h5 className="modal-title font-24 font-600" id="exampleModalToggleLabel">
              Add Fold
            </h5>
            <button type="button" className="btn-close btn-close-small position-static" onClick={handleClose}></button>
          </div>
          <div className="modal-body p-0">
            <div className="fold-section">
              <div className="fold-links">
                <div className="link-options">
                  <input type="checkbox" id="imgoption" className="d-none" />
                  <label className="" htmlFor="imgoption">
                    <b className="icon"><img src={FoldImg}/></b>
                    <span>Image</span>
                    <i></i>
                  </label>
                </div>
                <div className="link-options">
                  <input type="checkbox" id="video-option" className="d-none" />
                  <label className="" htmlFor="video-option">
                    <b className="icon"><img src={videoIcon}/></b>
                    <span>Video</span>
                    <i></i>
                  </label>
                </div>
                <div className="link-options">
                  <input type="checkbox" id="heading-option" className="d-none" />
                  <label className="" htmlFor="heading-option">
                    <b className="icon"><img src={HeadingImg}/></b>
                    <span>Heading</span>
                    <i></i>
                  </label>
                </div>
                <div className="link-options">
                  <input type="checkbox" id="paragraph-option" className="d-none" />
                  <label className="" htmlFor="paragraph-option">
                    <b className="icon"><img src={ParaImg}/></b>
                    <span>Paragraph</span>
                    <i></i>
                  </label>
                </div>
                <div className="link-options">
                  <input type="checkbox" id="list-option" className="d-none" />
                  <label className="" htmlFor="list-option">
                    <b className="icon">
                      <img src={ListImg}/>
                    </b>
                    <span>List</span>
                    <i></i>
                  </label>
                </div>
                <div className="link-options">
                  <input type="checkbox" id="button-option" className="d-none" />
                  <label className="" htmlFor="button-option">
                    <b className="icon"><img src={ButtonImg}/></b>
                    <span>Button</span>
                    <i></i>
                  </label>
                </div>
              </div>
              <div className="fold-section-ui">
                <div className="row">
                  <div className="col-md-6">
                    <div className="added-img">
                      <img src={AddImg} width="85"/>
                      <div className="img-options">
                        <div className="img-upload">
                          <label className="upload-img" htmlFor="upload-img">
                            <span className="btn-browse">
                              <i className="me-2">
                                <img src={CloudComputer}/>
                              </i>
                              Upload Image
                            </span>

                            <input className="d-none" type="file" id="upload-img"/>
                          </label>
                        </div>
                        <div className="img-alignment">
                          <select className="form-select" aria-label="Default select example">
                            <option selected="">Align Left</option>
                            <option value="1">Align Left</option>
                            <option value="2">Align Right</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="added-video">
                      <img src={VideCameraImg} width="100"/>
                      <div className="input-group video-link">
                        <span className="input-group-text" id="basic-video">
                          <i><img src={videoIcon}/></i>
                          ADD VIDEO LINK
                        </span>
                        <input type="text" className="form-control" id="basic-url" aria-describedby="basic-addon3"/>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-4">
                    <h1 contenteditable="true">Heading Goes Here</h1>
                    <p contenteditable="true">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nulla quam velit, vulputate eu pharetra nec, mattis ac
                      neque. Duis vulputate commodo lectus, vulputate eu
                      pharetra nec, mattis ac neque. Duis vulputate commodo lectus…</p>
                    <ul className="listed-points" contenteditable="true">
                      <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                      <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                      <li>Lorem ipsum dolor sit amet, consectetur.</li>
                      <li>Lorem ipsum dolor sit amet, consectetur.</li>
                    </ul>
                    <div className="d-flex flex-wrap">
                      <a className="btn btn-green" href="javascript:;">
                        CTA BUTTON
                      </a>
                      <CustomisedFormPopup className="btn btn-customize" />
                    </div>
                  </div>
                  <div className="col-md-12 mt-4 pt-4 border-top">
                    <a href="javascript:;" className="btn btn-addTemplate">
                      ADD TO TEMPLATE
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
                
                
        </Modal.Body>
        
      </Modal>
    </>
  );
}

export default FoldModal;