import React, { useState } from 'react';

import YouTubeThumb from '../../../images/black-banner.png';
import VideoImgModalPopup from '../../VideoImgModalPopup';

import RecentImgThumba1 from '../../../images/rectangle-12885.png';
import RecentImgThumba2 from '../../../images/rectangle-12887.png';
import RecentImgThumba3 from '../../../images/rectangle-12889.png';

import CloudComputer from '../../../images/cloud-computing.svg';
import videoIcon from '../../../images/video-svg.png';
import SingleColForm from '../../SingleColForm';
import CustomisedFormPopup from '../../Blog/CustomisedFormPopup';

function EditVersion() {

    const [shade, setShade] = useState(false);

    const handleDarkShade = () => {
      setShade(current => !current);
    };
  
  return (
    <>
      <main className="page-wrap">
                  <div className={`mainbanner realGullyBoyBanner  ${shade ? 'darkshade' : ''}`} data-img="1920px * 990px">
                    <span className="caption">
                      <div contenteditable="true" className="caption-edit" value="The Real Gully Boy">
                        The Real Gully Boy
                      </div>
                      <span contenteditable="true" className="sub-caption">
                        Five Star Chef From Dharavi - An Alumni Story
                      </span>
                      <span className="date">Aug 3, 2022</span>
                      <label className="browse-image" for="browse-image-main">
                        <span className="btn-browse">
                          <i className="me-2">
                            <img src={CloudComputer}/>
                          </i>
                          Browse Files
                        </span>
                        or drag and drop files here
                        <input className="d-none" type="file" id="browse-image-main"/>
                      </label>
                      <div className="darkoption d-flex align-items-center justify-content-center mt-4">
                        <div className="form-check form-switch p-0 d-flex align-items-center justify-content-center">
                          <label className="form-check-label font-18" for="flexSwitchCheckDefault">
                            Dark Shade enable
                          </label>
                          <input className="form-check-input ms-0 ms-3 font-32" onClick={handleDarkShade} type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                        
                        </div>
                      </div>
                    </span>
                    <div className="scrolldown d-flex justify-content-center align-items-center">
                      <b className="float_animate">#</b>
                    </div>
                  </div>
                 

                  <div className="bg-white container-fluid py-100">
                    <div className="container max-1140 blog-detail-page">
                      <div className="row">
                        <div className="col-md-12 page-title mb-3 mb-sm-5">
                          <h1 className="title mb-2 mb-sm-4">
                            <div contenteditable="true">
                              Dinesh Narasimha Kansane
                            </div>
                          </h1>
                          <p className="sub-tite mb-3">
                            <span contenteditable="true">
                              Student of SSRVM, Dharavi
                            </span>
                          </p>
                        </div>
                        <div className="col-lg">
                          <div className="pe-md-5">
                            <p contenteditable="true">
                              For more than a decade in any student’s life, the
                              school serves as the second home and teachers and
                              other staff members play the role of a guardian.
                              The impact they have on students is something that
                              stays with them long after they have graduated
                              from school.
                            </p>
                            <p contenteditable="true">
                              For more than a decade in any student’s life, the
                              school serves as the second home and teachers and
                              other staff members play the role of a guardian.
                              The impact they have on students is something that
                              stays with them long after they have graduated
                              from school.
                            </p>
                            <div className="d-flex flex-wrap">
                              <a className="btn btn-green" href="javascript:;">
                                Stories From My School
                              </a>
                              <CustomisedFormPopup className="btn btn-customize" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-auto">
                          <div className="videoSection blackBanner mt-5 mt-lg-0 d-flex justify-content-center">
                        
                            <VideoImgModalPopup className="video-btn" videoId="w7f7A8IkGsE" banner={YouTubeThumb} imgWidth={523}/>
                            
                            <div className="input-group video-link">
                              <span className="input-group-text" id="basic-video">
                                <i><img src={videoIcon}/></i>
                                ADD VIDEO LINK
                              </span>
                              <input type="text" className="form-control" id="basic-url" aria-describedby="basic-addon3"/>
                            </div>
                            <label className="upload-img" for="upload-img">
                              <span className="btn-browse">
                                <i className="me-2">
                                  <img src={CloudComputer}/>
                                </i>
                                Add Thumbnail
                              </span>
                              <input className="d-none" type="file" id="upload-img"/>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="row h-100">
                          <div className="leftPaneImg" data-img="960px * 700px">
                            <label className="upload-img" for="upload-img">
                              <span className="btn-browse">
                                <i className="me-2">
                                  <img src={CloudComputer}/>
                                </i>
                                Upload Image
                              </span>
                              <input className="d-none" type="file" id="upload-img"/>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 d-flex flex-column justify-content-center">
                        <div className="row">
                          <div className="col-xl-9 col-lg-12 col-md-12 rightpane py-5">
                            <h2 contenteditable="true">
                              Lorem ipsum dolor sit amet
                            </h2>
                            <p contenteditable="true">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nulla quam velit, vulputate eu pharetra nec,
                              mattis ac neque. Duis vulputate commodo lectus,
                            </p>
                            <ul contenteditable="true">
                              <li>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit.
                              </li>
                              <li>Lorem ipsum dolor sit amet, consectetur.</li>
                              <li>
                                Lorem ipsum dolor sit consectetur adipiscing
                                elit.
                              </li>
                            </ul>
                            
                            <div className="d-flex flex-wrap align-items-center">
                              <a className="btn btn-download" href="javascript:;">
                                <i></i>
                                <span>Download Brochure</span>
                              </a>
                              
                              <CustomisedFormPopup className="btn btn-customize" />
                            </div>
                            
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 bg-white py-80">
                        <div className="row justify-content-end">
                          <div className="col-xl-auto col-lg-12 col-md-12 leftPane">
                            <h2>Recent Stories</h2>
                            <ul className="recent_stories">
                              <li>
                                <div className="recent_stories_thumb" data-img="191px * 134px">
                                  <img src={RecentImgThumba1}/>
                                </div>
                                <div className="recent_stories_detail">
                                  Educators, Master the Art of Online Teaching
                                  with “Teach Online”
                                  <span className="date">Aug 3, 2022</span>
                                </div>
                                <a href="javascript:;" className="recentLink"></a>
                              </li>
                              <li>
                                <div className="recent_stories_thumb" data-img="186px * 130px">
                                  <img src={RecentImgThumba2}/>
                                </div>
                                <div className="recent_stories_detail">
                                  Let The Prep Begin! 6 Tips to Ace Your College
                                  Admission Interview!
                                  <span className="date">Aug 3, 2022</span>
                                </div>
                                <a href="javascript:;" className="recentLink"></a>
                              </li>
                              <li>
                                <div className="recent_stories_thumb" data-img="196px * 137px">
                                  <img src={RecentImgThumba3}/>
                                </div>
                                <div className="recent_stories_detail">
                                  What It Will Take For Your Winning School
                                  Election Next Year!
                                  <span className="date">Aug 3, 2022</span>
                                </div>
                                <a href="javascript:;" className="recentLink"></a>
                              </li>
                            </ul>
                            <a href="javascript:;" className="btn-download">View More</a>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 bg-form py-80" id="alumnistories">
                        <div className="row">
                          <div className="col-xl-auto col-lg-12 col-md-12 rightpane">
                            <h3 contenteditable="true">
                              DISCOVER YOUR
                              <br className="d-none d-lg-block"/>
                              OWN ALUMNI STORIES
                            </h3>
                            <div className="rightSideForm">
                              <SingleColForm/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
    </>
  )
}

export default EditVersion;
