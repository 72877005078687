import React from 'react';
import ImageVideoViewer from 'react-image-video-viewer';
import image1 from '../../images/3I0A6550-2.jpg';
import image2 from '../../images/439A3982-1.jpg';
import ImgVideoView from './ImgVideoView';
import MyGallery from './Gallery';

function TalentDetail() {

  return (
    <>
      <div className='w-1200 mx-auto my-5'>
        <div className='row g-5'>
          <div className='col-md-6'>
            <div className='profile-img'>
              {/* <ImgVideoView img1={image1} img2={image2}/> */}
              <MyGallery img1={image1} img2={image2}/>
            </div>
          </div>
          <div className='col-md-6 talent-detail'>
            <div className='row g-4'>
              <div className="col-12">
                <h1>Shawntoya Byrd</h1>
                <p className='text-start m-0'>Influencer, content creator, modelLos Angeles, CA</p>
                <p className='text-start m-0'>Gender-NonconformingShe/Her</p>
              </div>
              {/* Other profile details */}
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default TalentDetail;
