import React from 'react';

import OwlCarousel from 'react-owl-carousel';
import '../../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';

import rightGuidanceStudentSuccess from '../../images/right-guidance-student-success.svg';
import personalisedCounselling from '../../images/personalised-counselling.svg';
import psychometricTests from '../../images/psychometric-tests.svg';
import studentRetentionGrowth from '../../images/admission-marketing-support.svg';
import alumniMentorship from '../../images/alumni-mentorship.svg';
import admissionMarketingSupport from '../../images/admission-marketing-support.svg';



function CareerGuidanceProgramSlider() {
    const options = {
        margin: 20,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: false,
        smartSpeed: 1000,
        responsive: {
            0: { items: 1, dots: true },
            400: { items: 1, dots: true },
            600: { items: 2, dots: true },
            700: { items: 2, dots: true },
            1000: { items: 3, dots: false },
        },
    };
  return (
    <>
     

        <OwlCarousel className="alumniImpactProgramSlider owl-carousel owl-theme" id="alumniImpactProgram_carousel" {...options}>
                    
                        <div className="item">
                            <div className="alumniImpactProgramBlock">
                                <div className="icon"><img src={rightGuidanceStudentSuccess} width="48" className="img-fluid" alt=""/></div>
                                <h4>Right Guidance for Student Success</h4>
                                <p>Schools are responsible for educating students and preparing them for the outside world. Providing students with the right career guidance at the right time enables students to make informed career choices leading to successful outcomes.</p>
                                <a href="https://www.univariety.com/resources/featured-insights/detail.php?name=career-guidance-for-students" className="btn-knowmore" target="_blank">KNOW MORE</a>
                            </div>
                        </div>
    
                        <div className="item">
                            <div className="alumniImpactProgramBlock">
                                <div className="icon"><img src={personalisedCounselling} width="48" className="img-fluid" alt=""/></div>
                                <h4>Personalised Counselling</h4>
                                <p>From choosing the right career, course and college to building the right profile, our career counsellors are experts in guiding students towards careers that fit their interests and choices.</p>
                                <a href="https://www.univariety.com/resources/featured-insights/detail.php?name=personalised-counselling" className="btn-knowmore" target="_blank">KNOW MORE</a>
                            </div>
                        </div>

                        <div className="item">
                            <div className="alumniImpactProgramBlock">
                                <div className="icon"><img src={psychometricTests} width="48" className="img-fluid" alt=""/></div>
                                <h4>Psychometric Tests</h4>
                                <p>Scientific assessments of the student’s interests, aptitude and personality to determine their optimum career choices.</p>
                                <a href="https://www.univariety.com/resources/featured-insights/detail.php?name=psychometric-tests" className="btn-knowmore" target="_blank">KNOW MORE</a>
                            </div>
                        </div>

                        <div className="item">
                            <div className="alumniImpactProgramBlock">
                                <div className="icon"><img src={alumniMentorship} width="48" className="img-fluid" alt=""/></div>
                                <h4>Alumni Mentorship</h4>
                                <p>With Career Guidance Program, your students will not only get guidance from certified career counsellors but also personalised mentorship from your school’s alumni. After all, who can provide better insights on the course, college and career decisions than those who have been there, and done that.</p>
                                <a href="https://www.univariety.com/resources/featured-insights/detail.php?name=alumni-mentorship" className="btn-knowmore" target="_blank">KNOW MORE</a>
                            </div>
                        </div>

                        <div className="item">
                            <div className="alumniImpactProgramBlock">
                                <div className="icon"><img src={studentRetentionGrowth} width="48" className="img-fluid" alt=""/></div>
                                <h4>Student Retention Growth</h4>
                                <p>Switching schools after 10th grade is a trend amongst students. This decision is usually motivated by career interests. Through the program, students can get the right guidance to continue their higher studies in the same school.</p>
                                <a href="https://www.univariety.com/resources/featured-insights/detail.php?name=student-retention-growth" className="btn-knowmore" target="_blank">KNOW MORE</a>
                            </div>
                        </div>
    
                        <div className="item">
                            <div className="alumniImpactProgramBlock">
                                <div className="icon"><img src={admissionMarketingSupport} width="48" className="img-fluid" alt=""/></div>
                                <h4>School Success & Brand-building</h4>
                                <p>Schools can no longer rely on 9 and 10-pointers to market themselves like infrastructure. Career Guidance is a key marker for school success. A Career Guidance Program enables more confident, informed, and successful students who become the brand ambassadors of the school.</p>
                                <a href="https://www.univariety.com/resources/featured-insights/detail.php?name=institute-success-and-brand-building" className="btn-knowmore" target="_blank">KNOW MORE</a>
                            </div>
                        </div>
    
                    </OwlCarousel>
    </>
  )
}

export default CareerGuidanceProgramSlider;
