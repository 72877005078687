import React from 'react';
import banner from '../../images/cgp-banner.png';

import CareerGuidanceProgramSlider from './CareerGuidanceProgramSlider';

import LeftSideSlider from '../AlumniImpactProgram/LeftSideSlider';
import RightSideSlider from '../AlumniImpactProgram/RightSideSlider';

import CountUp from 'react-countup';

import VideoModalPopup from '../VideoModalPopup';

import VideoImgModalPopup from '../VideoImgModalPopup';

function CareerGuidanceProgram() {

  
  return (
    <>
      <section className="container-fluid headerBanner career_guidance_program_banner" id="headerBanner">
            <div className="w-1210 mx-auto pt-3 mb-5 mb-lg-0">
                <div className="row g-4">
                  <div className="col-md-12">
                    <div className="row g-5 align-items-center">
                        <div className="col-md" data-aos="fade-left" data-aos-delay="200">
                            <h1 className="mb-3">Career Guidance Program</h1>
                            <p className="text-start">Revolutionizing the Way Students Make Career Choices </p>
                            

                            <VideoModalPopup className="btn-bookdemo grdOrangeGold video-btn border-0" videoId="-0pIMFrW29U?si=3VHiWs3ji_C3S7jf" title="Book A Demo"/>
                            
                        </div>
                        <div className="col-md-auto text-center">
                            
                            <VideoImgModalPopup className="video-btn" videoId="-0pIMFrW29U?si=3VHiWs3ji_C3S7jf" banner={banner} imgWidth={807}/>
                        </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-4 pb-1">

                    <div className="horizontal-scroll">
                        <ul className="banner-white-block-wrap">
                            <li>
                                <div className="banner-white-block" >
                                    <div className="icon me-3"><img src="images/consultation.svg" alt=""/></div>
                                    <div className="blockBrife ">
                                        <div className="number count counter-value count-title" data-count="250000">
                                            <span>+</span>
                                            <CountUp end={250000} duration={2}>
      {({ countUpRef }) => (
        <span ref={countUpRef} />
      )}
    </CountUp>
                                            </div>
                                        <div className="title">Students Guided</div>
                                    </div>
                                    
                                </div>
                            </li>
                            <li>
                                <div className="banner-white-block">
                                    <div className="icon me-3"><img src="images/id.svg" alt=""/></div>
                                    <div className="blockBrife ">
                                        <div className="number count counter-value count-title" data-count="120">
                                            <span>+</span>
                                            <CountUp end={120} duration={2}>
                                                {({ countUpRef }) => (
                                                    <span ref={countUpRef} />
                                                )}
                                                </CountUp>
                                            </div>
                                        <div className="title">Admission in Top Colleges</div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="banner-white-block">
                                    <div className="icon me-3"><img src="images/scholarships.svg" alt=""/></div>
                                    <div className="blockBrife ">
                                        <div className="number count counter-value count-title" data-count="40000">
                                            <span>+</span>
                                            <CountUp end={40000} duration={2}>
                                                {({ countUpRef }) => (
                                                    <span ref={countUpRef} />
                                                )}
                                                </CountUp>
                                            </div>
                                        <div className="title">Scholarships</div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="banner-white-block">
                                    <div className="icon me-3"><img src="images/exclude.svg" alt=""/></div>
                                    <div className="blockBrife ">
                                        <div className="number count counter-value count-title" data-count="250">
                                        <CountUp end={250} duration={2}>
                                                {({ countUpRef }) => (
                                                    <span ref={countUpRef} />
                                                )}
                                                </CountUp>
                                        </div>
                                        <div className="title">Diverse Careers</div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="banner-white-block">
                                    <div className="icon me-3"><img src="images/exclude.svg" alt=""/></div>
                                    <div className="blockBrife ">
                                        <div className="number count counter-value count-title" data-count="250">
                                        <CountUp end={250} duration={2}>
                                                {({ countUpRef }) => (
                                                    <span ref={countUpRef} />
                                                )}
                                                </CountUp>
                                        </div>
                                        <div className="title">Schools</div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    
                    
                  </div>
                </div>
            </div>
        </section>

        <section className="container-fluid alumniImpactProgram pt-5" id="alumniImpactProgram" data-aos="fade-up" data-aos-delay="200">
            <div className="w-1200 mx-auto pt-5" >
                <h2>Why Choose Career Guidance Program</h2>
                
                <p>Univariety’s Career Guidance Program offers a comprehensive solution to schools to prepare students for their future careers. Career Guidance goes beyond just career counselling sessions and psychometric tests. It offers </p>
                <div data-aos="fade-left" data-aos-delay="600">
                    
                    <CareerGuidanceProgramSlider/>
                </div>
            </div>
        </section>

        <section className="trustedCompanies" id="trustedCompanies">

            <h2 data-aos="fade-up" data-aos-delay="200">Our Partner Schools</h2>
            <p>We work with 350+ Top Schools to enable Comprehensive Career Guidance and Counselling</p>

            <div data-aos="fade-left" data-aos-delay="200">
                <LeftSideSlider/>
            </div>
            

            <div data-aos="fade-right" data-aos-delay="200">
                <RightSideSlider/>
            </div>
            
            
        </section>

        <section className="container-fluid faq" id="faq">
            <div className="w-1200 mx-auto">
                
                
                <div className="row g-4">
                    <div className="col-md-4">
                        <h2 data-aos="fade-left" data-aos-delay="600">Frequently Asked Questions</h2>
                        <p className="text-darkblue">Let’s answer some of your burning questions about Career Guidance Program</p>
                    </div>
                    <div className="col-md-8">
                        <div className="accordion" id="accordionFaq" data-aos="fade-right" data-aos-delay="600">
                            <div className="accordion-item">
                              <h2 className="accordion-header">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqOne" aria-expanded="true" aria-controls="faqOne">
                                    What is a Career Guidance Program?
                                </button>
                              </h2>
                              <div id="faqOne" className="accordion-collapse collapse show" data-bs-parent="#accordionFaq">
                                <div className="accordion-body">
                                  <p>Univariety’s Career Guidance System is a complete career guidance solution for schools. It is backed by scientific research and professional career counselling that brings parents and students into one fold in the process of career planning. </p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqTwo" aria-expanded="false" aria-controls="faqTwo">
                                    What are the 5 points for Career Guidance?
                                </button>
                              </h2>
                              <div id="faqTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                <div className="accordion-body">
                                    <p>Univariety's Career Guidance System for School Students is not just about Counselling. It's a comprehensive program built on five core pillars - Psychometric Assessments, Research Tools, Personalized Counselling, Alumni Guidance, and University Connect. All these pillars empower the student to discover career clarity through experiences</p>
                                  </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqThree" aria-expanded="false" aria-controls="faqThree">
                                    How does Career Guidance and Counselling benefit the school?
                                </button>
                              </h2>
                              <div id="faqThree" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                <div className="accordion-body">
                                    <p>The number of students scoring more than 90% in Grade-10 & Grade-12 has gone up almost 5 times in the last decade. So, a school is no longer differentiated by the number of 10-pointers or 9-pointers they produce. Alumni Success, Focus on Students' Future, Student Profile Building, and Global Exposure are the credible differentiators for a 21st Century School. A holistic Career Guidance System enables all of these for a school.</p>
                                  </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqFour" aria-expanded="false" aria-controls="faqFour">
                                    What is the benefit of career guidance and counselling for students?
                                  </button>
                                </h2>
                                <div id="faqFour" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                  <div className="accordion-body">
                                      <p>Career counselling for school students is extremely important. It helps students reduce stress induced by indecisiveness and the academic pressure that they face in high school. Career Counselling helps them choose the right career path.</p>
                                    </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqFive" aria-expanded="false" aria-controls="faqFive">
                                    What is the main objective of Career Guidance Program?
                                  </button>
                                </h2>
                                <div id="faqFive" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                  <div className="accordion-body">
                                      <p>Career Guidance Program aims to empower schools to provide students with the right career guidance at the right time. It enables more confident, informed, and successful students and thereby building up the school’s brand and success. </p>
                                    </div>
                                </div>
                              </div>
                          </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default CareerGuidanceProgram;
