import React from 'react';

function SingleColForm() {
  return (
    <>
      <form className="" name="user_form" id="user_form" action="" method="post">
                                <div className="row g-3 align-items-center">
                                  <div className="col-md-12">
                                    <p className="text-end font-14 font-500 mb-0">
                                      * Marked fields are mandatory
                                    </p>
                                  </div>
                                  <div className="col-md-12">
                                    <input type="text" className="form-control flex-1" placeholder="Name*" name="FirstNamestd" id="FirstNamestd"/>
                                    <div className="error-feedback" id="FirstNamestdsp"></div>
                                  </div>

                                  <div className="col-md-12">
                                    <input type="email" className="form-control flex-1" placeholder="Email ID*" name="EmailAddressstd" id="EmailAddressstd"/>
                                    <div id="EmailAddressstdsp" className="error-feedback"></div>
                                  </div>

                                  <div className="col-md-12">
                                    <input type="text" className="form-control flex-1" placeholder="Contact Number*" name="ContactNumberstd" id="ContactNumberstd"/>
                                    <div id="ContactNumberstdsp" className="error-feedback"></div>
                                  </div>
                                  <div className="col-md-12">
                                    <input type="text" className="form-control flex-1" placeholder="School Name*" name="ParentNamestd" id="ParentNamestd"/>
                                    <div id="ParentNamestdsp" className="error-feedback"></div>
                                  </div>
                                  <div className="col-md-12">
                                    <input type="text" className="form-control flex-1" placeholder="City of School*" name="SchoolNamestd" id="SchoolNamestd"/>
                                    <div id="SchoolNamestdsp" className="error-feedback"></div>
                                  </div>
                                  <div className="col-md-12">
                                    <select className="form-select" aria-label="Default select example" onchange="getUserChange()" id="user_type" name="user_type">
                                      <option selected="" value="0">
                                        Function Area*
                                      </option>
                                      <option value="1">Student</option>
                                      <option value="2">Parent</option>
                                      <option value="3">School</option>
                                      <option value="4">University</option>
                                      <option value="5">Counsellor</option>
                                      <option value="6">Others</option>
                                    </select>
                                    <div id="Selectstdsp" className="error-feedback"></div>
                                  </div>
                                  <div className="col-md-12">
                                    <textarea className="form-control min-h-95" placeholder="Have Any Thoughts, Share Them with Us" id="floatingTextarea"></textarea>
                                    <div id="ContactNumberstdsp" className="error-feedback"></div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="row justify-content-end align-items-center">
                                     
                                      <div className="col-auto">
                                        <button type="button" className="btn btn-submit" data-bs-target="#success_popup" onclick="return onForm_Submit()">
                                          Submit
                                        </button>
                                      </div>
                                      
                                    </div>
                                  </div>
                                </div>
                              </form>
    </>
  )
}

export default SingleColForm;
