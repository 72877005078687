import React from 'react'
import PropertyList from './PropertyList.tsx';
import AgentList from './AgentList.tsx';

function PropertyShowCase() {
  return (
    <>
      <section className='PropertyShowCase container-fluid py-5'>
        <div className='w-1200 mx-auto'>
            <div className='row g-4'>
                <div className='col-lg-9'>
                    <PropertyList/>                    
                 </div>
                <div className='col-lg-3'>
                    <AgentList/>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default PropertyShowCase;
