import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'; // Import the CSS file for styling



const MyGallery = (props) => {

    const images = [
        {
          original: props.img1,
          thumbnail: props.img1,
          description: 'Description 1'
        },
        {
          original: props.img2,
          thumbnail: props.img2,
          description: 'Description 2'
        },
        // Add more images as needed
      ];

  return (
    <div>
      <ImageGallery items={images} />
    </div>
  );
}

export default MyGallery;
