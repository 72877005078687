import React from 'react';

import OwlCarousel from 'react-owl-carousel';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import FeatureList from './Home/FeatureList';



function GlobalSlider(props) {
    const options = {
        margin: props.margin,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: props.autoplay,
        smartSpeed: 1000,
        responsive: {
            0: { items: props.mobilecount, dots: props.mobiledots },
            400: { items: props.mobilecount, dots: props.mobiledots },
            600: { items: props.tabminicount, dots: props.tabminidots },
            700: { items: props.tabcount, dots: props.tabdots },
            1000: { items: props.desktopcount },
        },
    };
  return (
    
    <>
    <OwlCarousel id={`${props.title}_carousel`} className={`${props.title}Slider owl-carousel owl-theme`} {...options}>
                    <FeatureList/>
    </OwlCarousel>

    
    </>
  )
}

export default GlobalSlider;
