import React, { useState } from 'react';
import ImageGallery from 'react-image-gallery';
import ReactImageVideoviewer from 'react-image-video-viewer';

import image1 from '../../images/3I0A6550-2.jpg';
import image2 from '../../images/439A3982-1.jpg';

function TalentDetail() {
  const [showAlbum, setShowAlbum] = useState(false);
  const [images, setImages] = useState([]);

  const handleShowAlbum = () => {
    const selectedAlbumImages = [
      {
        url: image1,
        type: 'photo',
        altTag: 'Image 1 description'
      },
      {
        url: image2,
        type: 'photo',
        altTag: 'Image 2 description'
      }
      // Add more images as needed
    ];

    setImages(selectedAlbumImages);
    setShowAlbum(true);
  };

  return (
    <>
      <div className='w-1200 mx-auto my-5'>
        <div className='row g-5'>
          <div className='col-md-6'>
            <div className='profile-img'>
              <ImageGallery items={images} />
              <div className="album_imgblk position-relative" role="button" onClick={handleShowAlbum}>
                <img src={image1} alt="" />
                <div className="album_imgvdo position-absolute"></div>
              </div>
            </div>
          </div>
          <div className='col-md-6 talent-detail'>
            <div className='row g-4'>
              <div className="col-12">
                <h1>Shawntoya Byrd</h1>
                <p className='text-start m-0'>Influencer, content creator, modelLos Angeles, CA</p>
                <p className='text-start m-0'>Gender-NonconformingShe/Her</p>
              </div>
              {/* Other profile details */}
            </div>
          </div>
        </div>
      </div>
      {showAlbum && (
        <ReactImageVideoviewer 
          open={showAlbum}
          close={() => setShowAlbum(false)} 
          data={images}
          startIndex={0}
          showResourceCount={true}
          onCloseCallback={() => setShowAlbum(false)}
        />
      )}
    </>
  )
}

export default TalentDetail;
