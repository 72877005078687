import React from 'react';

import TeamMate from './TeamMate';
import VideoModalPopup from '../VideoModalPopup';


function AboutUs() {
  return (
    <>
      <div>AboutUs</div>
    </>
  )
}

export default AboutUs
