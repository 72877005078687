import React from 'react';



import Homeslider from './Homeslider';
import Partnerspopup from './MyVerticallyCenteredModal';
import GlobalSlider from '../GlobalSlider';
import Bannerslider from './Bannerslider';
import Whychooseblock from './Whychooseblock';
import Countblock from './Countblock';
import Listedprojects from './Listedprojects';
import SingleAdSlider from '../SingleAdSlider';
import SearchProperty from './SearchProperty.tsx';
import WelcomeSlider from './WelcomeSlider.js';

function Home(props) {

  return (
    <>

        <section className="headerBanner custom_dark_banner" id="headerBanner">
            <div className="">
                <Bannerslider/>
            </div>
        </section>

        <section className="container-fluid insights" id="insights" data-aos="fade-up" data-aos-delay="200">
            <div className="w-1200 mx-auto">
                

                <div className='row g-5 align-items-center'>
                    <div className='col-lg-8'>
                        <h2 className='mb-0 font-30 font-600'>Welcome back! </h2>
                        <p>Let’s continue your search</p>
                        <div className='xscroll mb-2'>
                            <ul className="nav nav-pills align-items-center search-tab flex-nowrap" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-buy-tab" data-bs-toggle="pill" data-bs-target="#pills-buy" type="button" role="tab" aria-controls="pills-buy" aria-selected="true">Buy</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-rent-tab" data-bs-toggle="pill" data-bs-target="#pills-rent" type="button" role="tab" aria-controls="pills-rent" aria-selected="false">Rent</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-new-projects-tab" data-bs-toggle="pill" data-bs-target="#pills-new-projects" type="button" role="tab" aria-controls="pills-new-projects" aria-selected="false">New Projects</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-pg-tab" data-bs-toggle="pill" data-bs-target="#pills-pg" type="button" role="tab" aria-controls="pills-pg" aria-selected="false">PG</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-plot-tab" data-bs-toggle="pill" data-bs-target="#pills-plot" type="button" role="tab" aria-controls="pills-plot" aria-selected="false">Plot</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-commercial-tab" data-bs-toggle="pill" data-bs-target="#pills-commercial" type="button" role="tab" aria-controls="pills-commercial" aria-selected="false">Commercial</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                <a className="nav-link" href='javascript:;'>Post Free Property AD</a>
                                </li>
                            </ul>
                        </div>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-buy" role="tabpanel" aria-labelledby="pills-buy-tab">
                                <SearchProperty/>
                            </div>
                            <div className="tab-pane fade" id="pills-rent" role="tabpanel" aria-labelledby="pills-rent-tab"> <SearchProperty/></div>
                            <div className="tab-pane fade" id="pills-new-projects" role="tabpanel" aria-labelledby="pills-new-projects-tab"> <SearchProperty/></div>
                            <div className="tab-pane fade" id="pills-pg" role="tabpanel" aria-labelledby="pills-pg-tab"> <SearchProperty/></div>
                            <div className="tab-pane fade" id="pills-plot" role="tabpanel" aria-labelledby="pills-plot-tab"> <SearchProperty/></div>
                            <div className="tab-pane fade" id="pills-commercial" role="tabpanel" aria-labelledby="pills-commercial-tab"> <SearchProperty/></div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <WelcomeSlider desktoplg={1} desktopmd={1} tab={1} mobile={1} dots={true} nav={false}/>                      
                    </div>
                </div>


                

            
            </div>
        </section>

        <Whychooseblock/>

        <Countblock/>

        <Listedprojects/>

        <section className="container-fluid py-5 career_guidance_program_banner" id="Featured" data-aos="fade-up" data-aos-delay="200">
            <div className="w-1200 mx-auto py-3" >
                <h2 className=''>Featured List</h2>
                <p className=' mb-5'>Need to identify</p>
                {/* <GlobalSlider title="FeaturedList" margin={20} autoplay="autoplay" mobilecount={1} tabminicount={2} tabcount={2} desktopcount={4} mobiledots="true" tabminidots="true" tabdots="true"/> */}
                
              
            </div>
        </section>

        <section className="container-fluid coreServices" id="coreServices" data-aos="fade-up" data-aos-delay="200">
            <div className="w-1200 mx-auto" >
                <h2>Our Categories</h2>
                <p>We offer new-age solutions -<br className="d-none d-lg-block" /> Market</p>
                {/* <div className="row g-4">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="coreServicesBlock litePink">
                            <div className="icon"><img src="images/alumni-networking-management.svg" width="50" className="img-fluid" alt=""/></div>
                            <h3>Alumni Networking & Management</h3>
                            <p>Build a thriving alumni community with updated information of alumni career journeys and achievements </p>
                            <a className="btn-readmore" target="_blank" href="https://www.univariety.com/resources/featured-insights/detail.php?name=alumni-networking-management "></a>   
                          
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="coreServicesBlock litePink">
                            <div className="icon"><img src="images/alumni-engagement.svg" width="50" className="img-fluid" alt=""/></div>
                            <h3>Alumni Engagement</h3>
                            <p>Maintain the connection with your alumni through meaningful engagement</p>
                            <a className="btn-readmore" target="_blank" href="https://www.univariety.com/resources/featured-insights/detail.php?name=meaningful-alumni-engagement "></a>
                          
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="coreServicesBlock litePink">
                            <div className="icon"><img src="images/alumni-mentorship.svg" width="50" className="img-fluid" alt=""/></div>
                            <h3>Alumni Mentorship</h3>
                            <p>Enable transfer of knowledge and expertise from alumni to current students. </p>
                            <a className="btn-readmore" target="_blank" href="https://www.univariety.com/resources/featured-insights/detail.php?name=alumni-mentors-for-student-success"></a>
                          
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="coreServicesBlock liteBlue">
                            <div className="icon"><img src="images/admission-marketing-support.svg" width="50" className="img-fluid" alt=""/></div>
                            <h3>Admission & Marketing Support</h3>
                            <p>Utilise alumni achievements and success stories for marketing admission growth</p>
                            <a className="btn-readmore" target="_blank" href="https://www.univariety.com/resources/featured-insights/detail.php?name=institute-marketing-and-brand-building"></a>
                           
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="coreServicesBlock liteOrgane">
                            <div className="icon"><img src="images/career-counselling.svg" width="50" className="img-fluid" alt=""/></div>
                            <h3>Career Counselling </h3>
                            <p>Expert career counsellors to answer all student and parent queries regarding career choices and academic routes.</p>
                            <a className="btn-readmore" href="https://www.univariety.com/resources/featured-insights/detail.php?name=personalised-counselling"></a>
                           
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="coreServicesBlock liteGold">
                            <div className="icon"><img src="images/psychometric-tests.svg" width="50" className="img-fluid" alt=""/></div>
                            <h3>Psychometric Tests</h3>
                            <p>Scientific assessments of the student’s interests, aptitude and personality to determine their optimum career choices. </p>
                            <a className="btn-readmore" target="_blank" href="https://www.univariety.com/resources/featured-insights/detail.php?name=psychometric-tests"></a>
                            
                        </div>
                    </div>

                  <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="coreServicesBlock liteGreen">
                            <div className="icon"><img src="images/research-tools.svg" width="50" className="img-fluid" alt=""/></div>
                            <h3>Research Tools</h3>
                            <p>Curated information database regarding careers, colleges, courses, countries, scholarships, and more.</p>
                            <a className="btn-readmore" href="/"></a>
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="coreServicesBlock liteLavender">
                            <div className="icon"><img src="images/university-connect.svg" width="50" className="img-fluid" alt=""/></div>
                            <h3>University Connect</h3>
                            <p>Connect students with top colleges in India and abroad</p>
                            <a className="btn-readmore" href="/"></a>
                           
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="coreServicesBlock liteLavender">
                            <div className="icon"><img src="images/study-abroad.svg" width="50" className="img-fluid" alt=""/></div>
                            <h3>Study Abroad</h3>
                            <p>Support students and alumni for higher education abroad.</p>
                            <a className="btn-readmore" href="/"></a>
                           
                        </div>
                    </div> 
                </div> */}
            </div>
        </section>

        <section className="container-fluid partners" id="partners" data-aos="fade-up" data-aos-delay="200">
            <div className="w-1200 mx-auto">
                <h2>What Our Partners Say About Us</h2>
                <p>350+ top team</p>
                
                {/* <Partnerspopup/> */}
            </div>
        </section>

        <section className="container-fluid insights" id="insights" data-aos="fade-up" data-aos-delay="200">
            <div className="w-1200 mx-auto">
                <h2>Tailored Solutions for<br className="d-none d-md-block" /> 21st-Century E</h2>
                <p>Our insights for marketing</p>

                

                {/* <Homeslider/> */}

                <div className="d-flex justify-content-center mt-4 pt-3">
                    <a href="/" className="btn-insights">VIEW ALL INSIGHTS</a>
                </div>
            </div>
        </section>

    </>
  )
}

export default Home;
