import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';



function CustomisedFormPopup(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  }

  const handleShow = (event) => {
    setShow(true);
  }

  return (
    <>
        <a href="javascript:;"  className={props.className} onClick={handleShow} >
          <i></i>
          <span>Customised Form</span>
        </a>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size=" max-1016"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="customize-form"
      >
       
        <Modal.Body className='p-0'>
     
          <div className="modal-body d-flex justify-content-center p-0">
          
          <div className="row">
              <div className="col-md-6">
                <div className="leftPane">
                  <div className="paneHeader">
                    <h5 className="modal-title" id="exampleModalFullscreenLabel">
                      Edit CTA Form
                    </h5>
                  </div>
                  <div className="paneBody">
                    <form className="row g-4 needs-validation" novalidate="">
                      <div className="col-md-12">
                        <label for="editTitle" className="form-label">Title</label>
                        <textarea className="form-control is-invalid" id="editTitle" placeholder="Add Form Title" required=""></textarea>
                        <div className="valid-feedback">
                          Looks good!
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="d-flex justify-content-between">
                          <label className="form-label">
                            Attach File (Optional)
                          </label>
                          <span className="font-12 mt-1">
                            Maximum file size : 5 mb (PDF, TIFF)
                          </span>
                        </div>
                        <div className="input-group">
                          <label className="input-group-text" for="inputGroupFile01">
                            <i className="icon-upload">
                              <img src={props.CloudComputer}/>
                            </i>
                            Choose File
                          </label>
                          <span>Drag and drop files here</span>
                          <input type="file" className="form-control d-none" id="inputGroupFile01"/>
                        </div>
                        <div className="valid-feedback">
                          Looks good!
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label for="CTATitle" className="form-label">
                          CTA Title
                        </label>
                        <input type="text" className="form-control" id="CTATitle" placeholder="Enter CTA Name" required=""/>
                        <div className="valid-feedback">
                          Looks good!
                        </div>
                      </div>

                      <div className="col-12">
                        <button className="btn btn-addTemplate" type="submit">
                          Add to Template
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-6 bg-darkblue">
                <button type="button" className="btn-close btn-close-white btn-close-small"  onClick={handleClose} ></button>
                <div className="rightPane">
                  <div className="rightPaneHeader">Title</div>
                  <div className="rightSideForm">
                    <form className="" name="user_form" id="user_form" action="" method="post">
                      <div className="row g-3 align-items-center">
                        <div className="col-md-12">
                          <p className="text-end font-14 font-500 mb-0">
                            * Marked fields are mandatory
                          </p>
                        </div>
                        <div className="col-md-12">
                          <input type="text" className="form-control flex-1" placeholder="Name*" name="FirstNamestd" id="FirstNamestd"/>
                          <div className="error-feedback" id="FirstNamestdsp"></div>
                        </div>

                        <div className="col-md-12">
                          <input type="email" className="form-control flex-1" placeholder="Email ID*" name="EmailAddressstd" id="EmailAddressstd"/>
                          <div id="EmailAddressstdsp" className="error-feedback"></div>
                        </div>

                        <div className="col-md-12">
                          <input type="text" className="form-control flex-1" placeholder="Mobile Number*" name="ContactNumberstd" id="ContactNumberstd"/>
                          <div id="ContactNumberstdsp" className="error-feedback"></div>
                        </div>
                        <div className="col-md-12">
                          <input type="text" className="form-control flex-1" placeholder="School Name*" name="ParentNamestd" id="ParentNamestd"/>
                          <div id="ParentNamestdsp" className="error-feedback"></div>
                        </div>

                        <div className="col-md-12">
                          <select className="form-select" aria-label="Default select example" onchange="getUserChange()" id="user_type" name="user_type">
                            <option selected="" value="0">
                              Select If You Would Like To Know More About Our
                              Offering?
                            </option>
                            <option value="1">Student</option>
                            <option value="2">Parent</option>
                            <option value="3">School</option>
                            <option value="4">University</option>
                            <option value="5">Counsellor</option>
                            <option value="6">Others</option>
                          </select>
                          <div id="Selectstdsp" className="error-feedback"></div>
                        </div>

                        <div className="col-md-12 pt-2">
                          <div className="row justify-content-end align-items-center">
                            <div className="col-auto">
                              <button type="button" className="btn btn-submit font-16 font-500">
                                CTA Name
                              </button>
                            </div>
                            </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
    
          </div>
                
                
        </Modal.Body>
        
      </Modal>
    </>
  );
}

export default CustomisedFormPopup;