import React from 'react';

import OwlCarousel from 'react-owl-carousel';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import imgPayRent from '../../images/pay-rent.svg';
import imgPersonalLoan from '../../images/personal-loan.svg';
import imgQuickCash from '../../images/quick-cash.svg';
import imgRentNowPayLater from '../../images/rent-now-pay-later.svg';
import imgRentAgreement from '../../images/rent-agreement.svg';
import imgHomeLoans from '../../images/home-loans.svg';
import imgHomeInteriors from '../../images/home-interiors.svg';
import imgPropertyManagement from '../../images/property-management.svg';
import imgFixedDeposit from '../../images/fixed-deposit.svg';

function Whychooseblock() {

    const options = {
        merge: true,
        loop: false,
        video: true,
        lazyLoad: true,
        autoWidth:true,
        nav: true,
        dots: false,
        margin: 20,
        responsive: {
            0: {              
                nav: false,
                dots: true                      
            },
            600: {
                nav: false,
                dots: true                            
            },
            1000: {
                
                dots: false
            }
        }
    };
  return (
    <>
      <div className="why_choose_block text-white pt-70 pb-60" >
            <div className="container">
                <h2 className="font-30 font-400 mb-4 pb-2 text-start">Why Choose <span className="font-500">Kasdeals?</span></h2>
                <div className=" d-flex justify-content-between flex-wrap">
                    <OwlCarousel className="owl-carousel owl-theme regularCarousel w-100" id="Kasdealscarousel" {...options}>
                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgPayRent} alt="Pay Rent" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Pay on Credit</span>
                            </a>
                        </div>

                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgPersonalLoan} alt="Personal Loans" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Personal Loans</span>
                            </a>
                        </div>

                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgQuickCash} alt="Quick Cash" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Quick Cash</span>
                            </a>
                        </div>

                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgRentNowPayLater} alt="Rent Now, Pay Later" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Rent Now, Pay Later</span>
                            </a>
                        </div>

                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgRentAgreement} alt="Rent Agreement" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Rent Agreement</span>
                            </a>
                        </div>

                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgHomeLoans} alt="Home Loans" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Home Loans</span>
                            </a>
                        </div>

                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgHomeInteriors} alt="Home Interiors" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Home Interiors</span>
                            </a>
                        </div>

                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgHomeInteriors} alt="Housing Premium" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Housing Premium</span>
                            </a>
                        </div>

                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgPropertyManagement} alt="Property Management" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Property Management</span>
                            </a>
                        </div>

                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgFixedDeposit} alt="Fixed Deposit" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Fixed Deposit</span>
                            </a>
                        </div>
                    </OwlCarousel>
                </div>
            </div>
        </div>
    </>
  )
}

export default Whychooseblock;
