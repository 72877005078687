import React from 'react';
import FormRegisterPopUp from '../FormRegisterPopUp';
 
import ImgBook1 from '../../images/book_1.jpg'; 
import ImgBook2 from '../../images/book_2.jpg'; 

function ExtraCurriculars() {
  return (
    <>
      <div className="extraCurriculars-list">
                            <div className="tab-white-block mb-2  py-4">
                                {/* <a className="pencil-edit" href="javascript:;" data-bs-toggle="modal" data-bs-target="#basic_info_popup"></a> */}
                                <FormRegisterPopUp className='pencil-edit border-0'/>
                                <div className="row g-4">
                                    <div className="col-md-12">
                                        <div className="customRow-header align-items-start">
                                            <div className="icon icon-exam-note"></div>
                                            <div className="customRow-header-title d-flex flex-column justify-content-center lineHeight-22">
                                                <span className="font-20 font-600">About Me</span>
                                                <span className="font-16 text-black lineHeight-26">Lorem ssss Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>
                                            </div>
                                       </div>
                                    </div>
                                   
                                </div>                                                    
                            </div>
                            <div className="curriculars" id="accordionExample">
                                

                                <div className="curriculars-item">
                                    <div className="curriculars-header" id="headingTwo">
                                      <div className="customRow-header collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                          <div className="icon icon-achievements"></div>
                                          <div className="customRow-header-title d-flex flex-column justify-content-center lineHeight-22">
                                              <span className="font-20 font-500">Achievements</span>
                                             
                                          </div>
                                          <i></i>
                                     </div>
                                     {/* <a className="pencil-edit" href="javascript:;" data-bs-toggle="modal" data-bs-target="#basic_info_popup"></a> */}
                                     <FormRegisterPopUp className='pencil-edit border-0'/>
                                    </div>
                                    
                                    <div id="collapseTwo" className="curriculars-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" >
                                      <div className="curriculars-body">
                                          <div className="customRow-detail">
                                              <div className="row g-3">
                                                  <div className="col-md-12">
                                                     <div className="xscroll">
                                                      <table className="curriculars_table"  style={{minWidth: "1020px"}}>
                                                          <thead>
                                                            <tr>
                                                              <th scope="col"><div>Activity Title</div></th>
                                                              <th scope="col" width="350"><div>Category</div></th>
                                                              <th scope="col" width="200"><div>Level</div></th>
                                                              <th scope="col" width="150"><div>Completed In</div></th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            <tr>
                                                              <th scope="row">Kathak</th>
                                                              <td>Arts &amp; Hobbies - eg. Music, Dance, Crafts, Games etc.</td>
                                                              <td>Intermediate</td>
                                                              <td>2022 - Sep</td>
                                                            </tr>
                                                            <tr>
                                                              <th scope="row">Basketball Championship</th>
                                                              <td>Sports</td>
                                                              <td>State Level and Above</td>
                                                              <td>2022 - Sep</td>
                                                            </tr>
                                                            <tr>
                                                              <th scope="row">WhiteHat Junior Programming</th>
                                                              <td>Online Courses(eg. Python, Computer Science, Finance, Biology)</td>
                                                              <td>Intermediate</td>
                                                              <td>2022 - Sep</td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                     </div>
                                                  </div>                                                
                                              </div>
                                          </div>
                                      </div>
                                    </div>
                                    
                                    
                                  </div>

                                  <div className="curriculars-item">
                                    <div className="curriculars-header" id="headingThree">
                                      <div className="customRow-header collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                          <div className="icon icon-books"></div>
                                          <div className="customRow-header-title d-flex flex-column justify-content-center lineHeight-22">
                                              <span className="font-20 font-500">Books</span>
                                            
                                          </div>
                                          <i></i>
                                     </div>
                                     {/* <a className="pencil-edit" href="javascript:;" data-bs-toggle="modal" data-bs-target="#basic_info_popup"></a> */}
                                     <FormRegisterPopUp className='pencil-edit border-0'/>
                                    </div>
                                    
                                    <div id="collapseThree" className="curriculars-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                      <div className="curriculars-body">
                                          <div className="customRow-detail">
                                              <div className="row g-3">
                                                  <div className="col-md-12">
                                                     <div className="xscroll">
                                                      <table className="curriculars_table" style={{minWidth: "1020px"}}>
                                                          <thead>
                                                            <tr>
                                                              <th scope="col" width="650"><div>Book Title</div></th>
                                                              <th scope="col"><div>Author</div></th>
                                                              <th scope="col" width="180"><div>Completed In</div></th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            <tr>
                                                          
                                                              <td>
                                                                <div className="book-wrap">
                                                                    <div className="book-img">
                                                                        <img src={ImgBook1} alt="" width="64"/>
                                                                    </div>
                                                                    <div className="book-detial">
                                                                        <h4 className="font-18 font-500">Sapiens</h4>
                                                                        <p>Sapiens goes on to retell the history of our species from a completely fresh perspective. It explains that even though we are 2022 - Dec far more powerful than our ancient ancestors, we aren’t much happier.</p>
                                                                    </div>
                                                                </div>
                                                              </td>
                                                              <td><span className="font-18 font-500">Yuval Noah Haraari</span></td>
                                                              <td>2022 - Dec</td>
                                                            </tr>
                                                            <tr>
                                                   
                                                              <td>
                                                                <div className="book-wrap">
                                                                    <div className="book-img">
                                                                        <img src={ImgBook2} alt="" width="64"/>
                                                                    </div>
                                                                    <div className="book-detial">
                                                                        <h4 className="font-18 font-500">Pride and Prejudice</h4>
                                                                        <p>The novel follows the character development of Elizabeth Bannet, the dynamic protagonist of the book who learns about the repercussions of hasty judgments and comes to appreciate the difference between superficial goodness and actual goodness.</p>
                                                                    </div>
                                                                </div>
                                                              </td>
                                                              <td><span className="font-18 font-500">Jane Austen</span></td>
                                                              <td>2019 - Jul</td>
                                                            </tr>
                                                        
                                                          </tbody>
                                                        </table>
                                                     </div>
                                                  </div>                                                
                                              </div>
                                          </div>
                                      </div>
                                    </div>
                                    
                                    
                                  </div>

                                  <div className="curriculars-item">
                                    <div className="curriculars-header" id="headingFour">
                                      <div className="customRow-header collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                          <div className="icon icon-activities"></div>
                                          <div className="customRow-header-title d-flex flex-column justify-content-center lineHeight-22">
                                              <span className="font-20 font-500">Activities</span>
                                              
                                          </div>
                                          <i></i>
                                     </div>
                                     {/* <a className="pencil-edit" href="javascript:;" data-bs-toggle="modal" data-bs-target="#basic_info_popup"></a> */}
                                     <FormRegisterPopUp className='pencil-edit border-0'/>
                                    </div>
                                    
                                    <div id="collapseFour" className="curriculars-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                      <div className="curriculars-body">
                                          <div className="customRow-detail">
                                              <div className="row g-3">
                                                  <div className="col-md-12">
                                                     <div className="xscroll">
                                                      <table className="curriculars_table" style={{minWidth: "1020px"}}>
                                                          <thead>
                                                            <tr>
                                                              <th scope="col" width="700"><div>Activity Title</div></th>                                                    
                                                              <th scope="col" width="150"><div>Completed In</div></th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            <tr>
                                                            
                                                              <td>
                                                                <div className="font-18 font-500">CATC</div>
                                                                <div className="font-16 ">Attended NCC Combined Annual Training Camp</div>
                                                              </td>
                                                              <td>2022 - Dec</td>
                                                            </tr>
                                                      
                                                          </tbody>
                                                        </table>
                                                     </div>
                                                  </div>                                                
                                              </div>
                                          </div>
                                      </div>
                                    </div>
                                    
                                    
                                  </div>
                               
                              </div>
                            
                        </div>
    </>
  )
}

export default ExtraCurriculars
