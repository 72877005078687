import React from 'react';
import ImgMale from '../../images/yellow-student-boy.svg';
import ImgFemale from '../../images/yellow-student.svg'; 
import ImgBook1 from '../../images/book_1.jpg'; 
import ImgBook2 from '../../images/book_2.jpg'; 
import ExtraCurriculars from './ExtraCurriculars';
import EntranceExams from './EntranceExams';
import EducationDetails from './EducationDetails';
import ParentInformation from './ParentInformation';
import Preferences from './Preferences';

function ProfilePage() {
  return (
    <>
      <section className="bg-white container-fluid alumni_profile_brife_wrap" id="alumni_profile_brife_wrap">
            <div className="max-1140 mx-auto alumni_profile_brife">
                <div className="row">
                   <div className="col-12 mb-4">
                        <a className="btn-back" href="javascript:;"><i className="icon-left-arrow font-10"></i> <span>Back to Directory</span></a>
                   </div>
                   <div className="col-12">
              
                    <div className="alumni_profile_name_college_other_detail_wrap student_profile">
                        <div className="alumni_profile_image_name_other_detail">
                            <div className="alumni_profile_image">
                                <img src={ImgMale} alt=""/>
                            </div>
                            <div className="alumni_profile_name_other_detail">
                                <h2 className="mb-0">Sourav Boski</h2>
                                <p className="font-14 mb-3">Class 12 A, Mayo College Boys School, Ajmer</p>
                                <div className="student_profile_details">
                                    <div className="alumni_profile_class_college">                            
                                        <div className="alumni_profile_college_phone mb-1">
                                            <span className="font-16 font-500 text-black line-height-18">+91 9873095880</span>
                                            <a className="verifyLink" href="javascript:;"><span>Verify Now</span></a>
                                        </div>
                                        <div className="alumni_profile_college_phone">
                                            <span className="font-16 font-500 text-black line-height-18">sourav.boski@yopmail.com</span>
                                            <a className="verifiedLink" href="javascript:;"><i className="icon-varified font-16 me-2"></i><span>Verified</span></a>
                                        </div>
                                    </div> 
                                    <ul className="school_other_details">
                                        <li>
                                            <span>Board</span>
                                            <b>CBSE</b>
                                        </li>
                                        <li>
                                            <span>Citizenship</span>
                                            <b>India</b>
                                        </li>
                                        <li>
                                            <span>Current Location</span>
                                            <b>Hyderabad, India</b>
                                        </li>
                                    </ul>
                                </div>                                                               
                            </div>                           
                        </div>
                        <a className="pencil-edit" href="javascript:;" data-bs-toggle="modal" data-bs-target="#basic_info_popup"></a>
                     
                    </div>
                        
                   </div>
                   
                </div>
             
            </div>
        </section>
        <section className="alumni_profile_detail_wrap container-fluid bg-gray-f3" id="alumni_profile_detail_wrap">
            <div className="max-1140 mx-auto alumni_profile_detail">
                <div className="alumni_profile_brife_tab mb-4">
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button className="nav-link active" id="pills-preferences-tab" data-bs-toggle="pill" data-bs-target="#pills-preferences" type="button" role="tab" aria-controls="pills-preferences" aria-selected="true">Preferences</button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="pills-parentInformation-tab" data-bs-toggle="pill" data-bs-target="#pills-parentInformation" type="button" role="tab" aria-controls="pills-parentInformation" aria-selected="false">Parent Information</button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="pills-educationDetails-tab" data-bs-toggle="pill" data-bs-target="#pills-educationDetails" type="button" role="tab" aria-controls="pills-educationDetails" aria-selected="false">Education Details</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-entranceExams-tab" data-bs-toggle="pill" data-bs-target="#pills-entranceExams" type="button" role="tab" aria-controls="pills-entranceExams" aria-selected="false">Entrance Exams</button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-extraCurriculars-tab" data-bs-toggle="pill" data-bs-target="#pills-extraCurriculars" type="button" role="tab" aria-controls="pills-extraCurriculars" aria-selected="false">Extra-curriculars</button>
                          </li>
                          
                      </ul>
                </div>
                
                  <div className="tab-content" id="pills-tabContent">
                    
      
                    <div className="tab-pane fade active show" id="pills-preferences" role="tabpanel" aria-labelledby="pills-preferences-tab">
                        <Preferences/>
                    </div>

                    <div className="tab-pane fade" id="pills-parentInformation" role="tabpanel" aria-labelledby="pills-parentInformation-tab">
                        <ParentInformation/>
                    </div>
                  
                    <div className="tab-pane fade" id="pills-educationDetails" role="tabpanel" aria-labelledby="pills-educationDetails-tab">
                        <EducationDetails/>
                    </div>


                    <div className="tab-pane fade" id="pills-entranceExams" role="tabpanel" aria-labelledby="pills-entranceExams-tab">
                        <EntranceExams/>
                    </div>

                    <div className="tab-pane fade" id="pills-extraCurriculars" role="tabpanel" aria-labelledby="pills-extraCurriculars-tab">
                        <ExtraCurriculars/>
                    </div>

                   
                  </div>
                  
            </div>
        </section>
    </>
  )
}

export default ProfilePage
