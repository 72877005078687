import React from 'react';
import ListedProjectSlider from './ListedProjectSlider';

function Listedprojects() {
  return (
    <>
    <div className='bg-projects py-5'>
      <div className='container'>
      <ListedProjectSlider title="Projects" subtitle="Exclusive showcase of top Villa" desktop={3}/>
      </div>
        
    </div>

    </>
  )
}

export default Listedprojects;
