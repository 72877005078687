import React from 'react';

import ImgAgent from '../../images/3I0A6550-2.jpg';
import ImgOrganisationLogo from '../../images/birla.png';

function AgentList() {
  return (
    <>
      <div className='row g-4'>
        <div className='col-lg-12 col-md-4 col-sm-6'>
            <div className='agent-card'>
              <div className='ribbon'>
                  <i className='left-edge'></i>
                  <i className='right-edge'></i>
                  <span>Trusted Agent</span>
              </div>
              <div className='agent-name-organisation'>
                  <div className='agent-detail'>
                      <div className='agent-pic'><img src={ImgAgent} alt="" className='img-fluid' /></div>
                      <div className='agent-name'>
                        <span>Agent Name</span>
                      </div>
                  </div>
                  <div className='agent-detail'>
                      <div className='agent-organisation-logo'><img src={ImgOrganisationLogo} alt="" className='img-fluid' /></div>
                      <div className='agent-organisation-name'>
                        <span>Agent organisation Name</span>
                        <span className='agentSince'>Agent organisation Name</span>
                      </div>
                  </div>
              </div>

              <div className='about-agent'>
                <h3>About Agent</h3>
                  <ul>
                    <li><i className="fa-regular fa-circle-check"></i><span>Has maximum property options</span></li>
                    <li><i className="fa-regular fa-circle-check"></i><span>Is the top agent of the locality</span></li>
                    <li><i className="fa-regular fa-circle-check"></i><span>Is trusted by all users</span></li>
                  </ul>
              </div>

              <div className='view-profile'>
                  <button className='btn btn-view-profile'><span>View Profile</span></button>
              </div>
            </div>
        </div>

        <div className='col-lg-12 col-md-4 col-sm-6'>
            <div className='agent-card'>
              <div className='ribbon'>
                  <i className='left-edge'></i>
                  <i className='right-edge'></i>
                  <span>Trusted Agent</span>
              </div>
              <div className='agent-name-organisation'>
                  <div className='agent-detail'>
                      <div className='agent-pic'><img src={ImgAgent} alt="" className='img-fluid' /></div>
                      <div className='agent-name'>
                        <span>Agent Name</span>
                      </div>
                  </div>
                  <div className='agent-detail'>
                      <div className='agent-organisation-logo'><img src={ImgOrganisationLogo} alt="" className='img-fluid' /></div>
                      <div className='agent-organisation-name'>
                        <span>Agent organisation Name</span>
                        <span className='agentSince'>Agent organisation Name</span>
                      </div>
                  </div>
              </div>

              <div className='about-agent'>
                <h3>About Agent</h3>
                  <ul>
                    <li><i className="fa-regular fa-circle-check"></i><span>Has maximum property options</span></li>
                    <li><i className="fa-regular fa-circle-check"></i><span>Is the top agent of the locality</span></li>
                    <li><i className="fa-regular fa-circle-check"></i><span>Is trusted by all users</span></li>
                  </ul>
              </div>

              <div className='view-profile'>
                  <button className='btn btn-view-profile'><span>View Profile</span></button>
              </div>
            </div>
        </div>

        <div className='col-lg-12 col-md-4 col-sm-6'>
            <div className='agent-card'>
              <div className='ribbon'>
                  <i className='left-edge'></i>
                  <i className='right-edge'></i>
                  <span>Trusted Agent</span>
              </div>
              <div className='agent-name-organisation'>
                  <div className='agent-detail'>
                      <div className='agent-pic'><img src={ImgAgent} alt="" className='img-fluid' /></div>
                      <div className='agent-name'>
                        <span>Agent Name</span>
                      </div>
                  </div>
                  <div className='agent-detail'>
                      <div className='agent-organisation-logo'><img src={ImgOrganisationLogo} alt="" className='img-fluid' /></div>
                      <div className='agent-organisation-name'>
                        <span>Agent organisation Name</span>
                        <span className='agentSince'>Agent organisation Name</span>
                      </div>
                  </div>
              </div>

              <div className='about-agent'>
                <h3>About Agent</h3>
                  <ul>
                    <li><i className="fa-regular fa-circle-check"></i><span>Has maximum property options</span></li>
                    <li><i className="fa-regular fa-circle-check"></i><span>Is the top agent of the locality</span></li>
                    <li><i className="fa-regular fa-circle-check"></i><span>Is trusted by all users</span></li>
                  </ul>
              </div>

              <div className='view-profile'>
                  <button className='btn btn-view-profile'><span>View Profile</span></button>
              </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default AgentList
