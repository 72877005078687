import React from 'react';

import template1Img from '../../images/template1_1.png';
import template2Img from '../../images/template1_2.png';
import template3Img from '../../images/template1_3.png';
import template4Img from '../../images/template1_4.png';
import TemplateModalPopup from './TemplateModalPopup';

function TemplateSelection() {
  return (
    <>
            <div className="pageHeader mb-3">
              <div className="row justify-content-between">
                <div className="col-auto">
                  <h1 className="text-dark font-24">Template Selection</h1>
                </div>                
              </div>
            </div>

            <div className="template-selection">
              <div className="row g-4">
                <div className="col-md-4">
                  <div className="success-template">
                    <div className="template_img" data-img="418px * 471px">
                      <img src={template1Img} width="100%"/>
                    </div>
                    <div className="template_selection_link">
                      <a className="btn-choose" href="/BlogEdit/Template1Edit">CHOOSE</a>
                      
                      <TemplateModalPopup className="btn-preview" template="/BlogTemplate1" />
                    </div>
                  </div>
                </div>
  
                <div className="col-md-4">
                  <div className="success-template">
                    <div className="template_img" data-img="418px * 471px">
                      <img src={template2Img} width="100%"/>
                    </div>
                    <div className="template_selection_link">
                      <a className="btn-choose" href="/BlogEdit/Template2Edit">CHOOSE</a>
                      <TemplateModalPopup className="btn-preview" template="/BlogTemplate2" />
                    </div>
                  </div>
                </div>
  
                <div className="col-md-4">
                  <div className="success-template">
                    <div className="template_img" data-img="418px * 471px">
                      <img src={template3Img} width="100%"/>
                    </div>
                    <div className="template_selection_link">
                      <a className="btn-choose" href="/BlogEdit/Template3Edit">CHOOSE</a>
                      <TemplateModalPopup className="btn-preview" template="/BlogTemplate3" />
                    </div>
                  </div>
                </div>
  
                <div className="col-md-4">
                  <div className="success-template">
                    <div className="template_img" data-img="418px * 471px">
                      <img src={template4Img} width="100%"/>
                    </div>
                    <div className="template_selection_link">
                      <a className="btn-choose" href="/BlogEdit/Template4Edit">CHOOSE</a>
                      <TemplateModalPopup className="btn-preview" template="/BlogTemplate4" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </>
  )
}

export default TemplateSelection;
