import React from 'react'
import FormRegisterPopUp from '../FormRegisterPopUp'

function EducationDetails() {
  return (
    <>
      <div className="educationDetails-detail tab-white-block">
                            {/* <a className="pencil-edit" href="javascript:;" data-bs-toggle="modal" data-bs-target="#basic_info_popup"></a> */}
                            <FormRegisterPopUp className="pencil-edit"/>
                            <div className="customRow">
                               <div className="customRow-header">
                                    <div className="icon icon-college"></div>
                                    <div className="customRow-header-title">
                                        <span className="font-20 font-500">12th Class</span>
                                    </div>
                               </div>
                               <div className="customRow-detail">
                                <div className="row g-3">
                                    <div className="col-md-4">
                                        <div className="d-flex flex-column">
                                            <div className="font-16 font-400 lineHeight-22 mb-1">Completion Year</div>
                                            <div className="font-20 font-500 lineHeight-22">2023</div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="d-flex flex-column">
                                            <div className="font-16 font-400 lineHeight-22 mb-1">School &amp; Board</div>
                                            <div className="font-20 font-500 lineHeight-22">Mayo College Girls School, Ajmer, CISCE</div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="d-flex flex-column">
                                            <div className="font-16 font-400 lineHeight-22 mb-1">Expected Marks/Grades</div>
                                            <div className="font-20 font-500 lineHeight-22">91%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>

                            <div className="customRow">
                                <div className="customRow-header">
                                     <div className="icon icon-school"></div>
                                     <div className="customRow-header-title">
                                         <span className="font-20 font-500">10th Class</span>
                                     </div>
                                </div>
                                <div className="customRow-detail">
                                    <div className="row g-3">
                                        <div className="col-md-4">
                                            <div className="d-flex flex-column">
                                                <div className="font-16 font-400 lineHeight-22 mb-1">Completion Year</div>
                                                <div className="font-20 font-500 lineHeight-22">2023</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="d-flex flex-column">
                                                <div className="font-16 font-400 lineHeight-22 mb-1">School &amp; Board</div>
                                                <div className="font-20 font-500 lineHeight-22">Mayo College Girls School, Ajmer, CISCE</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="d-flex flex-column">
                                                <div className="font-16 font-400 lineHeight-22 mb-1">Marks/Grades</div>
                                                <div className="font-20 font-500 lineHeight-22">91%</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                             </div>

                                                    
                        </div>
    </>
  )
}

export default EducationDetails
