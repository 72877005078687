import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';



function TemplateModalPopup(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  }

  const handleShow = (event) => {
    setShow(true);
  }

    const [view, setView] = useState('desktop');

  const handleView = () => {
    if (view === 'mobile'){
      setView('desktop');
    }
    else{
      setView ('mobile');
    }
  }


  return (
    <>
        <a href="javascript:;"  className={props.className} onClick={handleShow} >Preview</a>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="fullscreen"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="template-preview"
      >
       
        <Modal.Body className='p-0 d-flex flex-column'>
        <div class="modal-header bg-white">
            <h5 class="modal-title h4" id="exampleModalFullscreenLabel">Template 1 Preview</h5>
            <div class="togglelinks">
              <a class={`btn-desktop ${view === 'desktop' ? 'active':'' }`} onClick={handleView}  href="javascript:;"><i></i>Desktop</a>
              <a class={`btn-mobile ${view === 'mobile' ? 'active':'' }`} onClick={handleView} href="javascript:;"><i></i>Mobile</a>
            </div>
            {/* <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button> */}
            <Button variant="close ms-0" onClick={handleClose}></Button>
          </div>
          <div class="modal-body d-flex justify-content-center p-0">

          <iframe class={`iframe-preview iframe-${view === 'desktop' ? 'desktop':'mobile' }`}  width="100%" frameborder="0" seamless="seamless" src={props.template}></iframe>
    
          </div>
                
                
        </Modal.Body>
        
      </Modal>
    </>
  );
}

export default TemplateModalPopup;