import React from 'react'

function RefundPolicy() {
  return (
    <>
       <section className="container-fluid headerBanner privacyPolicyHeader" id="headerBanner">
            <div className="w-1200 mx-auto py-5">
                <div className="row g-4">
                  <div className="col-md-12">
                    <div className="row g-5 align-items-center">
                        <div className="col-md-13" data-aos="fade-left" data-aos-delay="200">
                            <h1 className="mb-4">Refund Policy </h1>  
                            <p className="font-25 font-500 text-start mb-4">All Univariety products, programs, courses and services are subject to terms and conditions. When you enrol for any of Univariety products, programs, courses and services, you agree to our Privacy Policy, Terms of Use as well as Refund Policy.</p>
                                                                     
                        </div>               
                    </div>
                  </div>               
                </div>
            </div>
        </section>

        <section className="container-fluid bg_grdBlueWhite refundPolicy" id="refundPolicy">
            <div className="w-1200 mx-auto">
                <div className="legal-cont container position-relative">


               
                    <ul className="tick">
                        <li>Univariety reserves the right to change any of these conditions at any time without any prior notice. By your continued access and use of our website, you agree to be bound by the latest version of the site's terms and conditions. Please check the website regularly to remain updated about any changes that may have been made.. </li>

                        <li>We have a strict NO REFUND policy for all our products, programs, courses, or services.  </li>
                       
                        <li>In case Univariety wishes to withdraw any product, program, course, or service, a prior 30-day notice will be sent to the candidate for completing the program. </li>
                        
                    </ul>
    
                  
           
    
    
    
    
                </div>
            </div>
        </section>
    </>
  )
}

export default RefundPolicy
