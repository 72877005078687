import React from 'react';

import WelImg1 from '../../images/generic-city-home-web.png';
import WelImg2 from '../../images/tvc-campaign-web-home-buy.png';
import WelImg3 from '../../images/tvc-campaign-web-home-sell.png';
import WelImg4 from '../../images/mb-destination-projects-web.jpg';
import VideoModalPopup from '../VideoModalPopup';


    const Welcomeitems = () => {
  return (
    <>
      <div className="item">
            <a href="javascript:;"><img src={WelImg1} alt="" /></a>
        </div>
        <div className="item">
         <a href="javascript:;"><img src={WelImg2} alt="" /></a>
         <VideoModalPopup className="btn btn-play video-btn welcome-owl-video" videoId ='8CPn51JioUk'/>
        </div>
        <div className="item">
         <a href="javascript:;"><img src={WelImg3} alt="" /></a>
         <VideoModalPopup className="btn btn-play video-btn welcome-owl-video" videoId ='8CPn51JioUk'/>
        </div>
        <div className="item">
         <a href="javascript:;"><img src={WelImg4} alt="" /></a>
        </div>
             
           
  
           
    </>
  )
};

export default Welcomeitems;

