import React from 'react';

function QuestionsPage() {
  return (
    <>
      <section className="container-fluid headerBanner" id="headerBanner">
            <div className="w-1200 mx-auto py-5">
                <div className="row g-4">
                  <div className="col-md-12">
                    <div className="row g-5 align-items-center">
                        <div className="col-md-13" data-aos="fade-left" data-aos-delay="200">
                            <h1 className="mb-0">Frequently Asked Questions</h1>                                               
                        </div>               
                    </div>
                  </div>               
                </div>
            </div>
        </section>

        <section className="faqSectionWrap" id="faqSectionWrap">
            <div className="faqSection bg_grdBlueWhite" id="faqSection">
                <div className="w-1200 mx-auto">
                    <h2 className="">Tell us more about Univariety</h2>
                    <div className="position-relative">
                        <div className="accordion" id="accordionAboutUnivariety">
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="AboutUnivariety_heading_1">
                                    <button className="accordion-button font-30 font-700 p-0 py-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#AboutUnivariety_collapse_1" aria-expanded="false" aria-controls="AboutUnivariety_collapse_1">
                                        Where is your Head Office?
                                </button>
                                </h3>
                                <div id="AboutUnivariety_collapse_1" className="accordion-collapse pb-4 collapse" aria-labelledby="AboutUnivariety_heading_1" data-bs-parent="#accordionAboutUnivariety">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Our Head Office is in Hyderabad, and our team is spread across major cities of India and North America.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="AboutUnivariety_heading_2">
                                    <button className="accordion-button font-30 font-700 p-0 py-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#AboutUnivariety_collapse_2" aria-expanded="false" aria-controls="AboutUnivariety_collapse_2">
                                        Is Univariety strategically associated with any companies/organizations?
                                </button>
                                </h3>
                                <div id="AboutUnivariety_collapse_2" className="accordion-collapse pb-4 collapse" aria-labelledby="AboutUnivariety_heading_2" data-bs-parent="#accordionAboutUnivariety">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Yes, we have Strategic Partnerships with UCLA Extension, Fortune India Magazine, The South Asia IB Schools Association (SAIBSA) and Members of Intl Schools Association (MISA).
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="AboutUnivariety_heading_3">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#AboutUnivariety_collapse_3" aria-expanded="true" aria-controls="AboutUnivariety_collapse_3">
                                        What is the size of Team Univariety?
                                </button>
                                </h3>
                                <div id="AboutUnivariety_collapse_3" className="accordion-collapse pb-4 collapse show" aria-labelledby="AboutUnivariety_heading_3" data-bs-parent="#accordionAboutUnivariety">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Univariety has 100+ Full time employees across various functions including Counselling, School Service &amp; Development, Digital Content, Research &amp; IT, and Admin &amp; Support Staff.
                                    </div>
                                </div>
                            </div>
        
                        </div>
                    </div>
                </div>
            </div>

            <div className="faqSection bg_darkblue" id="faqSection">
                <div className="w-1200 mx-auto">
                    <h2 className="text-white">Why Univariety?</h2>
                    <div className="position-relative">
                        <div className="accordion" id="accordionWhyUnivariety">
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="WhyUnivariety_heading_1">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#WhyUnivariety_collapse_1" aria-expanded="false" aria-controls="WhyUnivariety_collapse_1">
                                        How will our school benefit from a Career Guidance System?
                                </button>
                                </h3>
                                <div id="WhyUnivariety_collapse_1" className="accordion-collapse collapse pb-4" aria-labelledby="WhyUnivariety_heading_1" data-bs-parent="#accordionWhyUnivariety">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Univariety's system is designed to be one of the most unique Career Guidance platforms in the country, offering value to students and schools. CLICK HERE to discover the benefits.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="WhyUnivariety_heading_2">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#WhyUnivariety_collapse_2" aria-expanded="false" aria-controls="WhyUnivariety_collapse_2">
                                        We already have a Career Counsellor(s) in our School. Why do we need Univariety?
                                </button>
                                </h3>
                                <div id="WhyUnivariety_collapse_2" className="accordion-collapse collapse pb-4" aria-labelledby="WhyUnivariety_heading_2" data-bs-parent="#accordionWhyUnivariety">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Having a counsellor is indeed an advantage, but having a system integrated into the school’s guidance structure is even better. It develops on the counsellor’s efforts and creates a seamless method to track the progress of students and carry out systematic
                                        engagements with them. In addition to this, your alumni data is simultaneously digitized into the system, acting as a guiding force for junior students and creating a strong pillar for the school.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="WhyUnivariety_heading_3">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#WhyUnivariety_collapse_3" aria-expanded="false" aria-controls="WhyUnivariety_collapse_3">
                                        We have external counsellors available to students who are interested. Why do we need Univariety?
                                </button>
                                </h3>
                                <div id="WhyUnivariety_collapse_3" className="accordion-collapse collapse pb-4" aria-labelledby="WhyUnivariety_heading_3" data-bs-parent="#accordionWhyUnivariety">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Schools interact with the students on a daily basis and nurture them with the knowledge and skills they need for the future. Thus, offering an in-built guidance system impacts the holistic development of the student, and the school will have an irreplaceable
                                        role in the student’s future.
                                    </div>
                                </div>
                            </div>
        
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="faqSection" id="faqSection">
                <div className="w-1200 mx-auto">
                    <h2 className="">Why Choose Univariety over Competition?</h2>
                    <div className="position-relative">
                        <div className="accordion" id="accordionUnivarietyCompetition">
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="UnivarietyCompetition_heading_1">
                                    <button className="accordion-button font-30 font-700 p-0 py-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#UnivarietyCompetition_collapse_1" aria-expanded="false" aria-controls="UnivarietyCompetition_collapse_1">
                                        We have already partnered with a Career Guidance Company. Why should we switch to Univariety?
                                </button>
                                </h3>
                                <div id="UnivarietyCompetition_collapse_1" className="accordion-collapse pb-4 collapse" aria-labelledby="UnivarietyCompetition_heading_1" data-bs-parent="#accordionUnivarietyCompetition">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Our milestone based system is designed to ensure that students are moving in the right direction, offering them valuable guidance based on their position in the student lifecycle, which is carefully chalked out by our team of experts. We streamline our
                                        efforts towards ensuring that students make informed decisions about their future, and we also extend our expertise to parents and counsellors who require additional support during the college application process.
                                    </div>
                                </div>
                            </div>        
                        </div>
                    </div>
                </div>
            </div>

            <div className="faqSection bg_darkblue" id="faqSection">
                <div className="w-1200 mx-auto">
                    <h2 className="text-white">Our Services</h2>
                    <div className="position-relative">
                        <div className="accordion" id="accordionOurServices">
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="OurServices_heading_1">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#OurServices_collapse_1" aria-expanded="false" aria-controls="OurServices_collapse_1">
                                        How will you guide every student in our school?
                                </button>
                                </h3>
                                <div id="OurServices_collapse_1" className="accordion-collapse collapse pb-4" aria-labelledby="OurServices_heading_1" data-bs-parent="#accordionOurServices">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Students can access our platform on both desktop and mobile devices, allowing each student to gain instant access to professional counsellors as and when they require them.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="OurServices_heading_2">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#OurServices_collapse_2" aria-expanded="false" aria-controls="OurServices_collapse_2">
                                        We prefer in-person counselling sessions for our students.
                                </button>
                                </h3>
                                <div id="OurServices_collapse_2" className="accordion-collapse collapse pb-4" aria-labelledby="OurServices_heading_2" data-bs-parent="#accordionOurServices">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        The moment a query arises in a student’s mind, he/she can have the same cleared then and there through our chat-based counselling system. However, face-to-face interactions will be an extra-addition to our services, and can be arranged for using separate
                                        plans.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="OurServices_heading_3">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#OurServices_collapse_3" aria-expanded="false" aria-controls="OurServices_collapse_3">
                                        Are all your services offered online?
                                </button>
                                </h3>
                                <div id="OurServices_collapse_3" className="accordion-collapse collapse pb-4" aria-labelledby="OurServices_heading_3" data-bs-parent="#accordionOurServices">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        By building on the power and ease of technology, our entire platform is hosted online and offers students and schools easy access to our digital tools. We also have separate physical services and interventions in place.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="OurServices_heading_4">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#OurServices_collapse_4" aria-expanded="false" aria-controls="OurServices_collapse_4">
                                        Are you a study-abroad agent?
                                </button>
                                </h3>
                                <div id="OurServices_collapse_4" className="accordion-collapse collapse pb-4" aria-labelledby="OurServices_heading_4" data-bs-parent="#accordionOurServices">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Univariety does not act as an agent, and also does not function through commission-based relationships with universities. We maintain unbiased operations, keeping a student’s best interests and future in mind.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="OurServices_heading_5">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#OurServices_collapse_5" aria-expanded="false" aria-controls="OurServices_collapse_5">
                                        Do you have residential schools from remote areas subscribing to your platform?
                                </button>
                                </h3>
                                <div id="OurServices_collapse_5" className="accordion-collapse collapse pb-4" aria-labelledby="OurServices_heading_5" data-bs-parent="#accordionOurServices">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        A number of residential schools across India have joined hands with us, and our reach goes to different corners of the country, from Assam to Punjab to Kashmir.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="OurServices_heading_6">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#OurServices_collapse_6" aria-expanded="false" aria-controls="OurServices_collapse_6">
                                        Can we upload documents through your system for submitting college applications?
                                </button>
                                </h3>
                                <div id="OurServices_collapse_6" className="accordion-collapse collapse pb-4" aria-labelledby="OurServices_heading_6" data-bs-parent="#accordionOurServices">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Yes, we have partnered with Parchment and the feature will be live from July this year.
                                    </div>
                                </div>
                            </div>
        
                        </div>
                    </div>
                </div>
            </div>

            <div className="faqSection" id="faqSection">
                <div className="w-1200 mx-auto">
                    <h2 className="">Service Support</h2>
                    <div className="position-relative">
                        <div className="accordion" id="accordionServiceSupport">
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="ServiceSupport_heading_1">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#ServiceSupport_collapse_1" aria-expanded="false" aria-controls="ServiceSupport_collapse_1">
                                        How will you Launch your Services in our School?
                                </button>
                                </h3>
                                <div id="ServiceSupport_collapse_1" className="accordion-collapse collapse pb-4" aria-labelledby="ServiceSupport_heading_1" data-bs-parent="#accordionServiceSupport">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        A physical launch will be conducted with students, along with parents and the school’s senior staff. Followed by this will be the training given to the coordinator assigned by the school, and placement of the yearly calendar.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="ServiceSupport_heading_2">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#ServiceSupport_collapse_2" aria-expanded="false" aria-controls="ServiceSupport_collapse_2">
                                        How often will you visit our school for in-person Counselling?
                                </button>
                                </h3>
                                <div id="ServiceSupport_collapse_2" className="accordion-collapse collapse pb-4" aria-labelledby="ServiceSupport_heading_2" data-bs-parent="#accordionServiceSupport">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        In-person counselling is usually conducted once a year, followed by quarterly reviews. However, the frequency can be customized based on your requirement and size of Student Body.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="ServiceSupport_heading_3">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#ServiceSupport_collapse_3" aria-expanded="false" aria-controls="ServiceSupport_collapse_3">
                                        What kind of Service Support will you provide our School?
                                </button>
                                </h3>
                                <div id="ServiceSupport_collapse_3" className="accordion-collapse collapse pb-4" aria-labelledby="ServiceSupport_heading_3" data-bs-parent="#accordionServiceSupport">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        <ul className="tick">
                                            <li>Designated Service Account Manager who is the single point of contact for your School</li>
                                            <li>Quarterly &amp; Annual Service Reports</li>
                                            <li>Univariety is an Indian company with its Head Office in Hyderabad. We are reachable over Phone, email and in person, 9:00 AM TO 7:00 PM from Monday to Saturday</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="ServiceSupport_heading_4">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#ServiceSupport_collapse_4" aria-expanded="false" aria-controls="ServiceSupport_collapse_4">
                                        How do you ensure that all our students use your platform?
                                </button>
                                </h3>
                                <div id="ServiceSupport_collapse_4" className="accordion-collapse collapse pb-4" aria-labelledby="ServiceSupport_heading_4" data-bs-parent="#accordionServiceSupport">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        A digital wall, unique for each school, will be provided along with access to our mobile app. And on these platforms, oucounsellors will constantly interact with students, sharing solutions to concerns and queries as well as highly engaging and informative
                                        news, articles and interesting content. Additionally, we will also highlight the top 10 users of the system who will be rewarded with certificates. The school will also be able to add career guidance assessments in student
                                        report cards, allowing for active involvement of students in the career guidance process.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="ServiceSupport_heading_5">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#ServiceSupport_collapse_5" aria-expanded="false" aria-controls="ServiceSupport_collapse_5">
                                        How do you guarantee Data Confidentiality?
                                </button>
                                </h3>
                                <div id="ServiceSupport_collapse_5" className="accordion-collapse collapse pb-4" aria-labelledby="ServiceSupport_heading_5" data-bs-parent="#accordionServiceSupport">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Data Confidentiality is our foremost priority. We are proud to have long-standing associations with some of the finest Schools in India. That, along with a data confidentiality clause in all our partnership agreements make our commitment water tight.
                                        <br/><br/> Additionally, all school student and alumni is stored in secure locations, protected by the latest security measures. All employees are bound a strict confidentiality and non disclosure agreements.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="ServiceSupport_heading_6">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#ServiceSupport_collapse_6" aria-expanded="false" aria-controls="ServiceSupport_collapse_6">
                                        Do you provide Training Programs for Counsellors, Teachers and School Management?
                                </button>
                                </h3>
                                <div id="ServiceSupport_collapse_6" className="accordion-collapse collapse pb-4" aria-labelledby="ServiceSupport_heading_6" data-bs-parent="#accordionServiceSupport">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        We have a well-designed program, certified by UCLA Extension. <a href="http://www.globalcareercatalyst.com/" target="_blank">CLICK HERE</a> for more information
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="ServiceSupport_heading_7">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#ServiceSupport_collapse_7" aria-expanded="false" aria-controls="ServiceSupport_collapse_7">
                                        How do you involve our Parent body in the Guidance Process?
                                </button>
                                </h3>
                                <div id="ServiceSupport_collapse_7" className="accordion-collapse collapse pb-4" aria-labelledby="ServiceSupport_heading_7" data-bs-parent="#accordionServiceSupport">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Our parent module assigns a separate log-in ID to parents where they can review their child’s progress. They will also be updated with red flag alerts if the student is identified to be making wrong decisions. Parents can even chat with counsellors and
                                        the school alumni for extra guidance. <a href="/app/benefits-of-univariety" target="_blank">CLICK HERE</a> to view the full list of Benefits for Parents.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="ServiceSupport_heading_8">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#ServiceSupport_collapse_8" aria-expanded="false" aria-controls="ServiceSupport_collapse_8">
                                        What is Red-Flag Reporting?
                                </button>
                                </h3>
                                <div id="ServiceSupport_collapse_8" className="accordion-collapse collapse pb-4" aria-labelledby="ServiceSupport_heading_8" data-bs-parent="#accordionServiceSupport">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Early identification &amp; reporting of Career-related decisions that may be misaligned and not work in the best interests of the student .
        
        
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="ServiceSupport_heading_9">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#ServiceSupport_collapse_9" aria-expanded="false" aria-controls="ServiceSupport_collapse_9">
                                        What is 'Attrition Risk Reporting and Control'?
                                </button>
                                </h3>
                                <div id="ServiceSupport_collapse_9" className="accordion-collapse collapse pb-4" aria-labelledby="ServiceSupport_heading_9" data-bs-parent="#accordionServiceSupport">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        This report highlights students planning to drop out from your school
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="ServiceSupport_heading_10">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#ServiceSupport_collapse_10" aria-expanded="false" aria-controls="ServiceSupport_collapse_10">
                                        How do your services benefit our School's brand?
                                </button>
                                </h3>
                                <div id="ServiceSupport_collapse_10" className="accordion-collapse collapse pb-4" aria-labelledby="ServiceSupport_heading_10" data-bs-parent="#accordionServiceSupport">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        <ul className="tick">
                                            <li>A designated career guidance tab will be integrated into your school's website, where all career guidance activities and school placements will be highlighted, to showcase your initiatives &amp; Student achievements
                                                in full strength</li>
                                            <li>We create Institutional Brand Ambassadors out of your Alumni. We will capture your Alumni's journeys, highlighting success stories through videos and written case studies.</li>
                                            <li>We will facilitate introductions with top Indian and Foreign Universities &amp; companies to help you explore Strategic associations.</li>
                                            <li>To register your School for India's most prestigious School Listing <a href="https://www.future50schools.com/" target="_blank">CLICK HERE</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="ServiceSupport_heading_11">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#ServiceSupport_collapse_11" aria-expanded="false" aria-controls="ServiceSupport_collapse_11">
                                        Do you have a solution in case the School does not have a Counsellor at all?
                                </button>
                                </h3>
                                <div id="ServiceSupport_collapse_11" className="accordion-collapse collapse pb-4" aria-labelledby="ServiceSupport_heading_11" data-bs-parent="#accordionServiceSupport">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Absolutely! In fact, most Schools in India at this point, do not have designated Counsellors. So, while your Students have direct access to Expert Counsellors at Univariety, we also train your teachers on the basics of Career Guidance. Both our Platform
                                        as well as Univariety Counsellors are available to you for anytime support.
                                    </div>
                                </div>
                            </div>
        
                        </div>
                    </div>
                </div>
            </div>

            <div className="faqSection bg_darkblue" id="faqSection">
                <div className="w-1200 mx-auto">
                    <h2 className="text-white">Sign-up Process</h2>
                    <div className="position-relative">
                        <div className="accordion" id="accordionSignupProcess">
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="SignupProcess_heading_1">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#SignupProcess_collapse_1" aria-expanded="false" aria-controls="SignupProcess_collapse_1">
                                        Can our School get a Free Trial of Univariety's services?
                                </button>
                                </h3>
                                <div id="SignupProcess_collapse_1" className="accordion-collapse collapse pb-4" aria-labelledby="SignupProcess_heading_1" data-bs-parent="#accordionSignupProcess">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        A one-month trial run can be executed at your school, after which reports can be reviewed and consequent services can be decided upon. <a href="JavaScript:Void(0);" data-toggle="modal" data-target="#letsTalk">CLICK HERE</a>                                if you want a Free Trial.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="SignupProcess_heading_2">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#SignupProcess_collapse_2" aria-expanded="false" aria-controls="SignupProcess_collapse_2">
                                        We have specific needs, can you customize your packages for our school?
                                </button>
                                </h3>
                                <div id="SignupProcess_collapse_2" className="accordion-collapse collapse pb-4" aria-labelledby="SignupProcess_heading_2" data-bs-parent="#accordionSignupProcess">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        We would be glad to customize our services to your requirements, once they have been clearly discussed.
        
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="SignupProcess_heading_3">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#SignupProcess_collapse_3" aria-expanded="false" aria-controls="SignupProcess_collapse_3">
                                        We would like to keep your services optional for the Students. Is that possible?
                                </button>
                                </h3>
                                <div id="SignupProcess_collapse_3" className="accordion-collapse collapse pb-4" aria-labelledby="SignupProcess_heading_3" data-bs-parent="#accordionSignupProcess">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Sure! You can make it optional by meeting a minimum Student number requirement for the program. But by making the program optional, every student will not benefit and your school outplacement report and Alumni network will not be optimized.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="SignupProcess_heading_4">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#SignupProcess_collapse_4" aria-expanded="false" aria-controls="SignupProcess_collapse_4">
                                        What are the commercials of partnering with Univariety?
                                </button>
                                </h3>
                                <div id="SignupProcess_collapse_4" className="accordion-collapse collapse pb-4" aria-labelledby="SignupProcess_heading_4" data-bs-parent="#accordionSignupProcess">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        <a href="/app/pricing-plans-univariety-solutions" target="_blank">CLICK HERE</a> to view our various commercial packages and choose the one that suits you best.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="SignupProcess_heading_5">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#SignupProcess_collapse_5" aria-expanded="false" aria-controls="SignupProcess_collapse_5">
                                        What are the hidden costs?
                                </button>
                                </h3>
                                <div id="SignupProcess_collapse_5" className="accordion-collapse collapse pb-4" aria-labelledby="SignupProcess_heading_5" data-bs-parent="#accordionSignupProcess">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Transparency is what we believe in and we assure you of no hidden costs.
        
        
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="SignupProcess_heading_6">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#SignupProcess_collapse_6" aria-expanded="false" aria-controls="SignupProcess_collapse_6">
                                        Do you accept payment in instalments?
                                </button>
                                </h3>
                                <div id="SignupProcess_collapse_6" className="accordion-collapse collapse pb-4" aria-labelledby="SignupProcess_heading_6" data-bs-parent="#accordionSignupProcess">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Monthly or quarterly payment options can be availed. Note that the payment will have to be done at the beginning of every quarter.
        
        
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="SignupProcess_heading_7">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#SignupProcess_collapse_7" aria-expanded="false" aria-controls="SignupProcess_collapse_7">
                                        Ok, we are interested! What is the next step to partner with Univariety?
                                </button>
                                </h3>
                                <div id="SignupProcess_collapse_7" className="accordion-collapse collapse pb-4" aria-labelledby="SignupProcess_heading_7" data-bs-parent="#accordionSignupProcess">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Great! Please <a href="JavaScript:Void(0);" data-toggle="modal" data-target="#letsTalk">CLICK HERE</a> so we can get in touch with you!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
        </section>

        <section className="container-fluid headerBanner" id="headerBanner">
            <div className="w-1200 mx-auto py-5">
                <div className="row g-4">
                  <div className="col-md-12">
                    <div className="row g-5 align-items-center">
                        <div className="col-md-13" data-aos="fade-left" data-aos-delay="200">
                            <h1 className="mb-0">Student Guidance Solutions</h1>                                               
                        </div>               
                    </div>
                  </div>               
                </div>
            </div>
        </section>

        <section className="faqSectionWrap" id="faqSectionWrap">
            <div className="faqSection bg_grdBlueWhite" id="faqSection">
                <div className="w-1200 mx-auto">
                    <h2 className="">Psychometric Assessments</h2>
                    <div className="position-relative">
                        <div className="accordion" id="accordionPsychometricAssessments">
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="PsychometricAssessments_heading_1">
                                    <button className="accordion-button font-30 font-700 p-0 py-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#PsychometricAssessments_collapse_1" aria-expanded="false" aria-controls="PsychometricAssessments_collapse_1">
                                        Are your tests online or paper-based?
                                </button>
                                </h3>
                                <div id="PsychometricAssessments_collapse_1" className="accordion-collapse pb-4 collapse" aria-labelledby="PsychometricAssessments_heading_1" data-bs-parent="#accordionPsychometricAssessments">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Our Psychometric Tests are conducted online.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="PsychometricAssessments_heading_2">
                                    <button className="accordion-button font-30 font-700 p-0 py-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#PsychometricAssessments_collapse_2" aria-expanded="false" aria-controls="PsychometricAssessments_collapse_2">
                                        How does a Student know which Test to take?
                                </button>
                                </h3>
                                <div id="PsychometricAssessments_collapse_2" className="accordion-collapse pb-4 collapse" aria-labelledby="PsychometricAssessments_heading_2" data-bs-parent="#accordionPsychometricAssessments">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Schools and students will have access to a grade-wise bifurcation, helping them better understand the right assessment to take.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="PsychometricAssessments_heading_3">
                                    <button className="accordion-button font-30 font-700 p-0 py-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#PsychometricAssessments_collapse_3" aria-expanded="false" aria-controls="PsychometricAssessments_collapse_3">
                                        Will Univariety share Individual Student Test Reports with the School?
                                </button>
                                </h3>
                                <div id="PsychometricAssessments_collapse_3" className="accordion-collapse pb-4 collapse" aria-labelledby="PsychometricAssessments_heading_3" data-bs-parent="#accordionPsychometricAssessments">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        The Univariety dashboard offers schools access to individual student reports.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="faqSection bg_darkblue" id="faqSection">
                <div className="w-1200 mx-auto">
                    <h2 className="text-white">Personalised Counselling</h2>
                    <div className="position-relative">
                        <div className="accordion" id="accordionPersonalisedCounselling">
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="PersonalisedCounselling_heading_1">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#PersonalisedCounselling_collapse_1" aria-expanded="false" aria-controls="PersonalisedCounselling_collapse_1">
                                        How often will Univariety Counsellors visit our school for Counselling Sessions?
                                </button>
                                </h3>
                                <div id="PersonalisedCounselling_collapse_1" className="accordion-collapse collapse pb-4" aria-labelledby="PersonalisedCounselling_heading_1" data-bs-parent="#accordionPersonalisedCounselling">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Univariety offers a unique service of Virtual Counselling, which is a more flexible method offering quick support from our counsellors, based on a student's schedule and preferences. Therefore, most schools prefer enrolling for the Virtual Counselling
                                        services.
                                        <br/><br/> Univariety Counsellors visit schools that have opted for the relevant service. The frequency and duration of in-person Counselling depends on the Student Strength of the School.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="PersonalisedCounselling_heading_2">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#PersonalisedCounselling_collapse_2" aria-expanded="false" aria-controls="PersonalisedCounselling_collapse_2">
                                        What is Virtual Counselling? Isn't Face-to-Face Counselling better?
                                </button>
                                </h3>
                                <div id="PersonalisedCounselling_collapse_2" className="accordion-collapse collapse pb-4" aria-labelledby="PersonalisedCounselling_heading_2" data-bs-parent="#accordionPersonalisedCounselling">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Our digital platform makes Virtual Counselling an accessible, flexible and uninhibited guidance system, where our counsellors provide expert solutions to queries via chat or emails.
                                        <br/><br/> In-person counselling indeed has its own benefits, however, it does not facilitate individual attention to each student.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="PersonalisedCounselling_heading_3">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#PersonalisedCounselling_collapse_3" aria-expanded="false" aria-controls="PersonalisedCounselling_collapse_3">
                                        How will you ensure that all students are counselled?
                                </button>
                                </h3>
                                <div id="PersonalisedCounselling_collapse_3" className="accordion-collapse collapse pb-4" aria-labelledby="PersonalisedCounselling_heading_3" data-bs-parent="#accordionPersonalisedCounselling">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Digital counselling including Chats, Emails, telephones &amp; other resources like webinars,enable our Counsellors to ensure full coverage of your Student Body.
                                        <br/><br/> Counsellors also work on customised roadmaps for students on a case-to-case basis, and these are reported to the school and parents in the form of periodic reports or on-demand basis. We also track Student interactions
                                        with our counsellors to identify those who have not been covered. We then reach out to these students, their parents to schedule discussions.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="PersonalisedCounselling_heading_4">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#PersonalisedCounselling_collapse_4" aria-expanded="false" aria-controls="PersonalisedCounselling_collapse_4">
                                        How will you keep our School and Parents notified of a student's counselling session and progress?
                                </button>
                                </h3>
                                <div id="PersonalisedCounselling_collapse_4" className="accordion-collapse collapse pb-4" aria-labelledby="PersonalisedCounselling_heading_4" data-bs-parent="#accordionPersonalisedCounselling">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Summaries of all the counselling sessions done with the students are recorded and sent out to the students. We include and send links of emerging courses, test preparation, etc., in the discussion summaries that the parents can also refer to for more
                                        information. Additionally, parents can independently connect with the counsellor on our chat platform or opt for face-to-face counselling, and they can also utilize customized tools available on the portal.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="PersonalisedCounselling_heading_5">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#PersonalisedCounselling_collapse_5" aria-expanded="false" aria-controls="PersonalisedCounselling_collapse_5">
                                        What are the qualifications of Univariety Counsellors?
                                </button>
                                </h3>
                                <div id="PersonalisedCounselling_collapse_5" className="accordion-collapse collapse pb-4" aria-labelledby="PersonalisedCounselling_heading_5" data-bs-parent="#accordionPersonalisedCounselling">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Our counsellors have an educational background in counselling psychology and have received certifications from the GCC - UCLA Extension Certification Program for Counsellors. They also have expertise in handling and guiding students from grades 8 to 12,
                                        and handle more than 2000 enquiries each per year.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="PersonalisedCounselling_heading_6">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#PersonalisedCounselling_collapse_6" aria-expanded="false" aria-controls="PersonalisedCounselling_collapse_6">
                                        We have a great in-house Counsellor, why should we seek Univariety's Counselling Services?
                                </button>
                                </h3>
                                <div id="PersonalisedCounselling_collapse_6" className="accordion-collapse collapse pb-4" aria-labelledby="PersonalisedCounselling_heading_6" data-bs-parent="#accordionPersonalisedCounselling">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Its great that you have an in-house Counsellor! Our platform helps increase your counsellor's efficiency by providing latest information, updates, managing Student Data, Tracking Progress and Reports. With Univariety, your Counsellor also has access to
                                        a counselling helpdesk for any in-depth queries that she has for specific students. Additionally, Univariety counselling ensures that high quality and standardized information is provided to each student, irrespective of
                                        age, school or personal preferences.
                                        <br/><br/>
                                        <a href="/app/benefits-of-univariety" target="_blank">CLICK HERE</a> to view the benefits of our System for your School
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="faqSection" id="faqSection">
                <div className="w-1200 mx-auto">
                    <h2 className="">Alumni Guidance System</h2>
                    <div className="position-relative">
                        <div className="accordion" id="accordionAlumniGuidanceSystem">
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="AlumniGuidanceSystem_heading_1">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#AlumniGuidanceSystem_collapse_1" aria-expanded="false" aria-controls="AlumniGuidanceSystem_collapse_1">
                                        How does Univariety collect Alumni Data? What will the School have to do for it?
                                </button>
                                </h3>
                                <div id="AlumniGuidanceSystem_collapse_1" className="accordion-collapse collapse pb-4" aria-labelledby="AlumniGuidanceSystem_heading_1" data-bs-parent="#accordionAlumniGuidanceSystem">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        The school provides Alumni's initial contact information and their 12th grade information. Univariety connects with Alumni via Email, SMS, WhatsApp or Phone to collect information on their current college placements, applications and scholarships. The
                                        involvement of the school is critical in the process as they provide Univariety with the initial information on the students, which allows Univariety to further refine this information and also keep it updated."
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="AlumniGuidanceSystem_heading_2">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#AlumniGuidanceSystem_collapse_2" aria-expanded="false" aria-controls="AlumniGuidanceSystem_collapse_2">
                                        How will Univariety ensure Alumni Data confidentiality?
                                </button>
                                </h3>
                                <div id="AlumniGuidanceSystem_collapse_2" className="accordion-collapse collapse pb-4" aria-labelledby="AlumniGuidanceSystem_heading_2" data-bs-parent="#accordionAlumniGuidanceSystem">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Univariety only shares college-related information of Alumni to the existing students. Only those Alumni who have consented to receive communication will be getting messages on their emails. No student can contact an Alumni directly without the Alumni
                                        sharing his/her personal contact through the Univariety platform.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="AlumniGuidanceSystem_heading_3">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#AlumniGuidanceSystem_collapse_3" aria-expanded="false" aria-controls="AlumniGuidanceSystem_collapse_3">
                                        How will Alumni guide our students?
                                </button>
                                </h3>
                                <div id="AlumniGuidanceSystem_collapse_3" className="accordion-collapse collapse pb-4" aria-labelledby="AlumniGuidanceSystem_heading_3" data-bs-parent="#accordionAlumniGuidanceSystem">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Alumni can guide students through 1-on-1 interactions where a student sends a message to the Alumni on the Univariety platform and the Alumni responds to them. Another method is where Alumni share their experiences through videos, which can be accessed
                                        on the Univariety platform.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="AlumniGuidanceSystem_heading_4">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#AlumniGuidanceSystem_collapse_4" aria-expanded="false" aria-controls="AlumniGuidanceSystem_collapse_4">
                                        Will Univariety organize Alumni Reunions and other events for our School?
                                </button>
                                </h3>
                                <div id="AlumniGuidanceSystem_collapse_4" className="accordion-collapse collapse pb-4" aria-labelledby="AlumniGuidanceSystem_heading_4" data-bs-parent="#accordionAlumniGuidanceSystem">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        No, Univariety does not organize events in schools. However, Univariety will provide you with the necessary tools to organize Alumni Reunions and other event organizing tools. These features are going to be launched soon, so keep watching this space for
                                        more updates on the same.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="AlumniGuidanceSystem_heading_5">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#AlumniGuidanceSystem_collapse_5" aria-expanded="false" aria-controls="AlumniGuidanceSystem_collapse_5">
                                        Will Univariety help us with fund raising from Alumni?
                                </button>
                                </h3>
                                <div id="AlumniGuidanceSystem_collapse_5" className="accordion-collapse collapse pb-4" aria-labelledby="AlumniGuidanceSystem_heading_5" data-bs-parent="#accordionAlumniGuidanceSystem">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Univariety will only provide you tools to organize Alumni Reunions and other event organizing tools. It does not organize any such events for the schools.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="AlumniGuidanceSystem_heading_6">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#AlumniGuidanceSystem_collapse_6" aria-expanded="false" aria-controls="AlumniGuidanceSystem_collapse_6">
                                        Can the School collect its own Alumni Data?
                                </button>
                                </h3>
                                <div id="AlumniGuidanceSystem_collapse_6" className="accordion-collapse collapse pb-4" aria-labelledby="AlumniGuidanceSystem_heading_6" data-bs-parent="#accordionAlumniGuidanceSystem">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        A school is definitely open to collecting its own Alumni data. However, this is a long and cumbersome exercise which involves multiple interactions with Alumni students. Univariety has automated many of these steps and therefore is able to collect accurate
                                        and authentic information in a speedy manner.
                                        <br/><br/> Moreover, our research has shown that only 60% of information on Alumni present with schools is accurate, on even basic parameters like contact information, email addresses or current colleges.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="faqSection bg_darkblue" id="faqSection">
                <div className="w-1200 mx-auto">
                    <h2 className="text-white">University Guidance</h2>
                    <div className="position-relative">
                        <div className="accordion" id="accordionUniversityGuidance">
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="UniversityGuidance_heading_1">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#UniversityGuidance_collapse_1" aria-expanded="false" aria-controls="UniversityGuidance_collapse_1">
                                        Are you Agents for Foreign Universities?
                                </button>
                                </h3>
                                <div id="UniversityGuidance_collapse_1" className="accordion-collapse collapse pb-4" aria-labelledby="UniversityGuidance_heading_1" data-bs-parent="#accordionUniversityGuidance">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        No, we are not agents. We are an ed-tech company which provides a career &amp; college guidance platform for schools, and we help every student get into his/her dream university.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="UniversityGuidance_heading_2">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#UniversityGuidance_collapse_2" aria-expanded="false" aria-controls="UniversityGuidance_collapse_2">
                                        Does Univariety provide information for Indian as well as Foreign Universities?
                                </button>
                                </h3>
                                <div id="UniversityGuidance_collapse_2" className="accordion-collapse collapse pb-4" aria-labelledby="UniversityGuidance_heading_2" data-bs-parent="#accordionUniversityGuidance">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Yes, we provide information for both Indian and Foreign Universities
        
        
        
        
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="UniversityGuidance_heading_3">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#UniversityGuidance_collapse_3" aria-expanded="false" aria-controls="UniversityGuidance_collapse_3">
                                        Does your platform only give information about Universities which Univariety is partnered with?
                                </button>
                                </h3>
                                <div id="UniversityGuidance_collapse_3" className="accordion-collapse collapse pb-4" aria-labelledby="UniversityGuidance_heading_3" data-bs-parent="#accordionUniversityGuidance">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        No, our platform provides information about all the Indian and Foreign Universities. In case our website does not have information on a particular university, we would be glad to help you receive the information from reliable sources.
        
        
        
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="UniversityGuidance_heading_4">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#UniversityGuidance_collapse_4" aria-expanded="false" aria-controls="UniversityGuidance_collapse_4">
                                        Can you organize Career Fairs for our School?
                                </button>
                                </h3>
                                <div id="UniversityGuidance_collapse_4" className="accordion-collapse collapse pb-4" aria-labelledby="UniversityGuidance_heading_4" data-bs-parent="#accordionUniversityGuidance">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Yes, we organize fairs for your school on an On-Demand basis. However, all expenses for the fair have to be borne by the school.
        
        
        
        
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="UniversityGuidance_heading_5">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#UniversityGuidance_collapse_5" aria-expanded="false" aria-controls="UniversityGuidance_collapse_5">
                                        Can you assist our students in securing Scholarships?
                                </button>
                                </h3>
                                <div id="UniversityGuidance_collapse_5" className="accordion-collapse collapse pb-4" aria-labelledby="UniversityGuidance_heading_5" data-bs-parent="#accordionUniversityGuidance">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Yes, we help students in securing scholarships for any university of their choice. Information regarding scholarships can also be obtained on the Univariety website.
        
        
        
        
        
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="UniversityGuidance_heading_6">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#UniversityGuidance_collapse_6" aria-expanded="false" aria-controls="UniversityGuidance_collapse_6">
                                        Can you arrange University workshops in our School?
                                </button>
                                </h3>
                                <div id="UniversityGuidance_collapse_6" className="accordion-collapse collapse pb-4" aria-labelledby="UniversityGuidance_heading_6" data-bs-parent="#accordionUniversityGuidance">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Yes, we arrange university workshops for your school.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="UniversityGuidance_heading_7">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#UniversityGuidance_collapse_7" aria-expanded="false" aria-controls="UniversityGuidance_collapse_7">
                                        Can you connect us directly with the Admission officers of International Universities ?
                                </button>
                                </h3>
                                <div id="UniversityGuidance_collapse_7" className="accordion-collapse collapse pb-4" aria-labelledby="UniversityGuidance_heading_7" data-bs-parent="#accordionUniversityGuidance">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Yes, we can connect you with the admissions team of all our partnered universities, irrespective of them being in India or abroad. In case a school is not partnered with us, we try our best to provide you with relevant information on the admission officers.
        
        
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="UniversityGuidance_heading_8">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#UniversityGuidance_collapse_8" aria-expanded="false" aria-controls="UniversityGuidance_collapse_8">
                                        Does Univariety offer any scholarships to our Meritorious students ?
                                </button>
                                </h3>
                                <div id="UniversityGuidance_collapse_8" className="accordion-collapse collapse pb-4" aria-labelledby="UniversityGuidance_heading_8" data-bs-parent="#accordionUniversityGuidance">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        No, Univariety do not offer any kind of scholarships. But we help you gain scholarships from some of the top Universities in the world.
        
                                    </div>
                                </div>
                            </div>
        
        
                        </div>
                    </div>
                </div>
            </div>

            <div className="faqSection" id="faqSection">
                <div className="w-1200 mx-auto">
                    <h2 className="">Research & Knowledge Tools</h2>
                    <div className="position-relative">
                        <div className="accordion" id="accordionResearchKnowledgeTools">
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="ResearchKnowledgeTools_heading_1">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#ResearchKnowledgeTools_collapse_1" aria-expanded="false" aria-controls="ResearchKnowledgeTools_collapse_1">
                                        Can our School Counsellor and teachers access your research tools?
                                </button>
                                </h3>
                                <div id="ResearchKnowledgeTools_collapse_1" className="accordion-collapse collapse pb-4" aria-labelledby="ResearchKnowledgeTools_heading_1" data-bs-parent="#accordionResearchKnowledgeTools">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        Yes, for every school that signs up with Univariety, we create a school dashboard where school stakeholders can log in and access research tools
        
        
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="ResearchKnowledgeTools_heading_2">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#ResearchKnowledgeTools_collapse_2" aria-expanded="false" aria-controls="ResearchKnowledgeTools_collapse_2">
                                        Do we need to install a software to access the Univariety Platform?
                                </button>
                                </h3>
                                <div id="ResearchKnowledgeTools_collapse_2" className="accordion-collapse collapse pb-4" aria-labelledby="ResearchKnowledgeTools_heading_2" data-bs-parent="#accordionResearchKnowledgeTools">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        No, Univariety is a web based platform for which no software installation is necessary. The only requirement for accessing the platform is a working internet connection and a computer/ laptop
        
        
        
        
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="ResearchKnowledgeTools_heading_3">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#ResearchKnowledgeTools_collapse_3" aria-expanded="false" aria-controls="ResearchKnowledgeTools_collapse_3">
                                        Will you train our Counsellor and students on how to use these tools?
                                </button>
                                </h3>
                                <div id="ResearchKnowledgeTools_collapse_3" className="accordion-collapse collapse pb-4" aria-labelledby="ResearchKnowledgeTools_heading_3" data-bs-parent="#accordionResearchKnowledgeTools">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        As part of the implementation of the Univariety services, we hold dedicated training sessions for school staff and a launch program for the parents and students.
        
        
        
        
        
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h3 className="accordion-header" id="ResearchKnowledgeTools_heading_4">
                                    <button className="accordion-button font-30 font-700 p-0 py-4" type="button" data-bs-toggle="collapse" data-bs-target="#ResearchKnowledgeTools_collapse_4" aria-expanded="false" aria-controls="ResearchKnowledgeTools_collapse_4">
                                        How do we know that the information you are providing on your Platform is accurate?
                                </button>
                                </h3>
                                <div id="ResearchKnowledgeTools_collapse_4" className="accordion-collapse collapse pb-4" aria-labelledby="ResearchKnowledgeTools_heading_4" data-bs-parent="#accordionResearchKnowledgeTools">
                                    <div className="accordion-body font-18 p-0 pb-1">
                                        We have a dedicated team who is tasked with consistently maintaining updated information on Univariety. Most of the data is collected in collaboration with universities or from their official website. We also get updates from universities regarding their
                                        recent and most updated information.
        
        
        
        
        
        
                                    </div>
                                </div>
                            </div>
        
        
        
                        </div>
                    </div>
                </div>
            </div>

         
          
        </section>
    </>
  )
}

export default QuestionsPage;
