import React from 'react';

import BlogThumbMain from '../../images/main-blog.png';
import BlogThumbImg1 from '../../images/blog-thumb-1.png';
import BlogThumbImg2 from '../../images/blog-thumb-2.png';
import BlogThumbImg3 from '../../images/blog-thumb-3.png';
import BlogThumbImg4 from '../../images/blog-thumb-4.png';
import BlogThumbImg5 from '../../images/blog-thumb-5.png';
import BlogThumbImg6 from '../../images/blog-thumb-6.png';

function BlogListing() {
  return (
    <>
      <div className="row g-4">
                    <div className="col-md-12">
                        <div className="main-blog">
                            <div className="blog-img"><img src={BlogThumbMain} width="100%"/></div>
                            
                            <div className="blog-detail">
                                <div className="category">ALUMNI ENGAGEMENT</div>
                                <div className="blog-title">What It Will Take For Your Winning School Election Next Year!<span className="date">Aug 3, 2022</span></div>
                            </div>      
                            <a href="javascript:;" className="blogLink"></a>                       
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="blog-block">
                            <div className="blog-img">
                                <img src={BlogThumbImg1} width="100%"/>
                            </div>
                            <div className="blog-detail">
                                <div className="category red">ALUMNI ENGAGEMENT</div>
                                <div className="blog-title">The Real Gully Boy<br/> Five Star Chef From Dharavi - An Alumni Story<span className="date">Aug 3, 2022</span></div>
                            </div>
                            <a href="javascript:;" className="blogLink"></a>                       
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="blog-block">
                            <div className="blog-img">
                                <img src={BlogThumbImg2} width="100%"/>
                            </div>
                            <div className="blog-detail">
                                <div className="category yellow">STUDENTS GUIDANCE</div>
                                <div className="blog-title">Let The Prep Begin! 6 Tips to Ace Your<br/> College Admission Interview!<span className="date">Aug 3, 2022</span></div>
                            </div>
                            <a href="javascript:;" className="blogLink"></a>                       
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="blog-block">
                            <div className="blog-img">
                                <img src={BlogThumbImg3} width="100%"/>
                            </div>
                            <div className="blog-detail">
                                <div className="category blue">SCHOOL BRANDING &amp; ADMISSIONS</div>
                                <div className="blog-title">Educators, Master the Art of Online<br/> Teaching with “Teach Online”<span className="date">Aug 3, 2022</span></div>
                            </div>
                            <a href="javascript:;" className="blogLink"></a>                       
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="blog-block">
                            <div className="blog-img">
                                <img src={BlogThumbImg4} width="100%"/>
                            </div>
                            <div className="blog-detail">
                                <div className="category yellow">STUDENTS GUIDANCE</div>
                                <div className="blog-title">Let The Prep Begin! 6 Tips to Ace Your<br/> College Admission Interview!<span className="date">Aug 3, 2022</span></div>
                            </div>
                            <a href="javascript:;" className="blogLink"></a>                       
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="blog-block">
                            <div className="blog-img">
                                <img src={BlogThumbImg5} width="100%"/>
                            </div>
                            <div className="blog-detail">
                                <div className="category red">ALUMNI ENGAGEMENT</div>
                                <div className="blog-title">Educators, Master the Art of Online<br/> Teaching with “Teach Online”<span className="date">Aug 3, 2022</span></div>
                            </div>
                            <a href="javascript:;" className="blogLink"></a>                       
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="blog-block">
                            <div className="blog-img">
                                <img src={BlogThumbImg6} width="100%"/>
                            </div>
                            <div className="blog-detail">
                                <div className="category yellow">STUDENTS GUIDANCE</div>
                                <div className="blog-title">Let The Prep Begin! 6 Tips to Ace Your<br/> College Admission Interview!<span className="date">Aug 3, 2022</span></div>
                            </div>
                            <a href="javascript:;" className="blogLink"></a>                       
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="loader">

                        </div>
                    </div>
                </div>
    </>
  )
}

export default BlogListing;
