import React from 'react';
import facebookIcon from '../images/facebook_icon.svg';
import linkedinIcon from '../images/linkedin_icon.svg';
import instagramIcon from '../images/instagram_icon.svg';
import twitterIcon from '../images/twitter_icon.svg';
import youtubeIcon from '../images/youtube_icon.svg';

function Footer() {
  return (
    <footer>
      <div className="container-fluid bg_darkblue py-5 text-white">
        <div className="w-1200 mx-auto">
     
            <div className="row footer-row footer-row2">
                <div className="col-6 col-md-4 col-lg-2 mb-4 mb-md-0">
                    <ul>
                        <li><a target="_blank" href="https://www.univariety.com/blog/">Blog</a></li>
                        <li><a href="/Our-Clientele">Our Partners</a></li>
                    </ul>
                </div>
                <div className="col-6 col-md-4 col-lg-2 mb-4 mb-md-0">
                    <ul>
                        <li><a href="https://products.univariety.com/product/career-counselling-session/s">Get Counselling</a></li>
                        <li><a target="_blank" href="https://products.univariety.com/">Our Products</a></li>
                    </ul>
                </div>
                <div className="col-6 col-md-4 col-lg-2 mb-4 mb-md-0">
                    <ul>
                        <li><a href="/About-Us">About Us</a></li>
                        <li><a href="/Contact-Us">Contact Us</a></li>
                        <li><a href="/Faq">FAQ's</a></li>
                    
                    </ul>
                </div>
                <div className="col-6 col-md-4 col-lg-2 mb-4 mb-md-0">
                    <ul>
                        <li><a href="/Privacy-Policy">Privacy Policy</a></li>
                        <li><a href="/Terms-and-Conditions">Terms &amp; Conditions</a></li>
                        <li><a href="/Refund-Policy">Refund Policy</a></li>
                        <li><a target="_blank" href="https://univariety.com/sitemap.xml">Sitemap</a></li>
                    </ul>
                </div>
             
            </div>
        </div>
      </div>
      <div className="container-fluid bg-white copyRight py-4">
        <div className="w-1200 mx-auto">
            <div className="row g-4 align-items-center text-center text-sm-start">
                <div className="col-sm">
                    <p className="m-0">AST Software Solutions Private Limited. © 2024. All Rights Reserved. </p>
                </div>
                <div className="col-sm-auto">
                    <div className="social-links">
                        <a href="https://www.facebook.com/univariety/" target="_blank"><img src={facebookIcon} alt=""/></a>
                        <a href="https://www.linkedin.com/company/univariety/" target="_blank"><img src={linkedinIcon} alt=""/></a>
                        <a href="https://www.instagram.com/theofficialunivariety/" target="_blank"><img src={instagramIcon} alt=""/></a>
                        <a href="https://twitter.com/Univariety" target="_blank"><img src={twitterIcon} alt=""/></a>
                        <a href="https://www.youtube.com/channel/UCpwpzaFC6Dn3xDLrlEiFoUA?view_as=subscriber" target="_blank"><img className="m-0" src={youtubeIcon} alt=""/></a>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
