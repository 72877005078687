import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';



function VideoImgModalPopup(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  }

  const handleShow = (event) => {
    setShow(true);
  }


  return (
    <>
        <a href="javascript:;"  className={props.className} onClick={handleShow} ><img data-aos="fade-right" data-aos-delay="200" src={props.banner} width={props.imgWidth} className="img-fluid" alt=""/></a>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="video_pop"
      >
       
        <Modal.Body className='p-0'>
       
                <Button variant="close" onClick={handleClose}></Button>
                <div className="ratio ratio-16x9">
                  <iframe className="embed-responsive-item" src={`https://www.youtube.com/embed/${props.videoId}?rel=0&amp;showinfo=0&amp;modestbranding=1&amp;autoplay=1`} id="video" allowscriptaccess="always"></iframe>
                </div>
        </Modal.Body>
        
      </Modal>
    </>
  );
}

export default VideoImgModalPopup;