import React from 'react';

import OwlCarousel from 'react-owl-carousel-rtl';
import '../../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';

import daisImg from '../../images/dais.png';
import greenwoodImg from '../../images/greenwood.png';
import ryanImg from '../../images/ryan.png';
import testlerimg from '../../images/testler.png';
import podarImg from '../../images/podar.png';
import birlaImg from '../../images/birla.png';
import ssrvmImg from '../../images/ssrvm.png';
import isdImg from '../../images/isd.png';
import cambrianHallImg from '../../images/cambrian-hall.png';
import newEraHighSchoolImg from '../../images/new-era-high-school.png';

function RightSideSlider() {

    const options = {
            merge: true,
            loop: true,
            video: true,
            lazyLoad: true,
            autoWidth:true,
            nav: false,
            dots: false,
            margin: 20,
            slideTransition: 'linear',
            autoplay:true,
            autoplayTimeout: 5000,
            autoplaySpeed: 5000,            
            autoplayHoverPause:false,
            rtl:true
    };

  return (
    <>
      <OwlCarousel className="rightsideSlider owl-carousel owl-theme" id="rightside_carousel" {...options}>
                    
            <div className="item"><div className="logo-block"><img src={daisImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={greenwoodImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={ryanImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={testlerimg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={podarImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={birlaImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={ssrvmImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={isdImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={cambrianHallImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={newEraHighSchoolImg} alt=""/></div></div>

            <div className="item"><div className="logo-block"><img src={daisImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={greenwoodImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={ryanImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={testlerimg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={podarImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={birlaImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={ssrvmImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={isdImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={cambrianHallImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={newEraHighSchoolImg} alt=""/></div></div>
            
        </OwlCarousel>
    </>
  )
}

export default RightSideSlider
