import React from 'react';
import UploadImg from '../../images/tumblr_inline_mt71ansFkw1s4o9cd540.gif'

function PropertyList() {
  return (
    <>
    <h2 className='font-18 text-start font-500'>3820 results | Villas for Sale in Hyderabad</h2>
    <p className='text-start font-16'>You can get a villa for sale in Hyderabad at a price range of ₹1.50 Cr - ₹14 Cr. Some of the popular localities in Hyderabad are Medchal Highway, Jubilee Hills, Gachibowli, Miyapur, Kondapur offering excellent options for a Villa. Some of the prime projects in Hyderabad are BMR Residency, Bhanu Township, JS Orchid Apartment, Maruthi Pride, Sri Tirumala Harmony. One can get a villa in Hyderabad for sale at an average price of ₹3.97 Cr and median price of ₹2.73 Cr with a QoQ growth of 5.80%</p>
      <div className='row g-3'>
        
        <div className='col-12'>
            <div className='property-block'>
                <div className='property-details'>
                <div className='row g-4'>
                            <div className='col-sm-4'>
                                <div className='uploaded-imgs'>
                                    <img src={UploadImg} className='img-fluid rounded' alt="" />
                                </div>
                                <div className='owner-detail font-16 mt-2'>
                                    <span className='font-500'>Owner:</span> Rajendra Kumar
                                </div>
                                <div className='user-type'>
                                    <i className="fa-solid fa-shield-heart me-1"></i>
                                    <span className='icon-premium font-500'>Premium</span>
                                </div>
                            </div>
                            <div className='col-sm-8'>
                                <div className='row g-3'>
                                    <div className='col-12'>
                                        <div className='row g-2'>
                                            <div className='col-sm'><h4 className='font-18 mb-0'>4 BHK Villa for Sale in Kollur, Outer Ring Road, Hyderabad</h4></div>
                                            <div className='col-sm-auto'>
                                                <div className='wishList-share'>
                                                    <a className='btn-whislist me-1' href='javascript:;'><i className="fa-regular fa-heart"></i></a>
                                                    <a className='btn-share' href='javascript:;'><i className="fa-solid fa-share"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='gray-box'>
                                            <div className='gray-box-list'>
                                                <div className='gray-box-row'>
                                                    <div className='detail-block'>
                                                        <div className='icon carpet'>
                                                            <i className="fa-solid fa-rug"></i>
                                                        </div>
                                                        <div className='detail-block-title-brife'>
                                                            <div className='detail-block-title'>Carpet Area</div>
                                                            <div className='detail-block-brife'>1620 sqft</div>
                                                        </div>
                                                    </div>

                                                    <div className='detail-block'>
                                                        <div className='icon status'>
                                                            <i className="fa-solid fa-building"></i>
                                                        </div>
                                                        <div className='detail-block-title-brife'>
                                                            <div className='detail-block-title'>Status</div>
                                                            <div className='detail-block-brife'>Ready to Move</div>
                                                        </div>
                                                    </div>

                                                    
                                                </div>
                                                <div className='collapse' id='collapseExample'>
                                                <div className='gray-box-row collapse' >
                                                <div className='detail-block'>
                                                        <div className='icon floor'>
                                                            <i className="fa-solid fa-layer-group"></i>
                                                        </div>
                                                        <div className='detail-block-title-brife'>
                                                            <div className='detail-block-title'>Floor</div>
                                                            <div className='detail-block-brife'>Ground out of 3</div>
                                                        </div>
                                                    </div>

                                                    <div className='detail-block'>
                                                        <div className='icon transaction'>
                                                            <i className="fa-solid fa-money-bill-wave"></i>
                                                        </div>
                                                        <div className='detail-block-title-brife'>
                                                            <div className='detail-block-title'>Transaction</div>
                                                            <div className='detail-block-brife'>Resale</div>
                                                        </div>
                                                    </div>
                                                    <div className='detail-block'>
                                                        <div className='icon furnishing'>
                                                            <i className="fa-solid fa-couch"></i>
                                                        </div>
                                                        <div className='detail-block-title-brife'>
                                                            <div className='detail-block-title'>furnishing</div>
                                                            <div className='detail-block-brife'>Unfurnished</div>
                                                        </div>
                                                    </div>
                                                    <div className='detail-block'>
                                                        <div className='icon facing'>
                                                        <i className="fa-regular fa-compass"></i>
                                                        </div>
                                                        <div className='detail-block-title-brife'>
                                                            <div className='detail-block-title'>facing</div>
                                                            <div className='detail-block-brife'>East</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                
                                            </div>
                                            <div className='gray-box-toggle'>
                                            <button className="btn btn-toggle" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='row g-3'>
                                        <div className='col-auto'>
                                            <div className='checked-pointes'>
                                                <i className='checked'></i>
                                                <span>East Facing Property</span>
                                            </div>
                                        </div>
                                        <div className='col-auto'>
                                            <div className='checked-pointes'>
                                                <i className='checked'></i>
                                                <span>Newly Constructed Property</span>
                                            </div>
                                        </div>
                                            <div className='col-12'>
                                                <p className='font-16 text-start mb-0'>Have a look at this praiseworthy 4 BHK independent villa that is for sale in one of the desirable locations of Nagole in Hyderabad.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div className='property-price-contact'>
                    <div className='row g-3 flex-row flex-md-column justify-content-between'>
                        <div className='col-md-12 col-auto my-md-5'>
                            <div className="price text-md-center"><span className="rupees">₹</span>1.60 Cr </div>
                            <div className="price_per_sq text-md-center"><span className="rupees">₹</span>11,511 per sqft </div>
                        </div>
                        <div className='col-md-12 col-auto'>
                            <div className='row g-2'>
                                <div className='col-md-12 col-auto text-center'>
                                    <button className='btn btn-contact-owner'>Contact Owner</button>
                                </div>
                                <div className='col-md-12 col-auto text-center'>
                                    <button className='btn btn-get-phone'>Get Phone No.</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>

        <div className='col-12'>
            <div className='property-block'>
                <div className='property-details'>
                <div className='row g-4'>
                            <div className='col-sm-4'>
                                <div className='uploaded-imgs'>
                                    <img src={UploadImg} className='img-fluid rounded' alt="" />
                                </div>
                                <div className='owner-detail font-16 mt-2'>
                                    <span className='font-500'>Owner:</span> Rajendra Kumar
                                </div>
                                <div className='user-type'>
                                    <i className="fa-solid fa-shield-heart me-1"></i>
                                    <span className='icon-premium font-500'>Premium</span>
                                </div>
                            </div>
                            <div className='col-sm-8'>
                                <div className='row g-3'>
                                    <div className='col-12'>
                                        <div className='row g-2'>
                                            <div className='col-sm'><h4 className='font-18 mb-0'>4 BHK Villa for Sale in Kollur, Outer Ring Road, Hyderabad</h4></div>
                                            <div className='col-sm-auto'>
                                                <div className='wishList-share'>
                                                    <a className='btn-whislist me-1' href='javascript:;'><i className="fa-regular fa-heart"></i></a>
                                                    <a className='btn-share' href='javascript:;'><i className="fa-solid fa-share"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='gray-box'>
                                            <div className='gray-box-list'>
                                                <div className='gray-box-row'>
                                                    <div className='detail-block'>
                                                        <div className='icon carpet'>
                                                            <i className="fa-solid fa-rug"></i>
                                                        </div>
                                                        <div className='detail-block-title-brife'>
                                                            <div className='detail-block-title'>Carpet Area</div>
                                                            <div className='detail-block-brife'>1620 sqft</div>
                                                        </div>
                                                    </div>

                                                    <div className='detail-block'>
                                                        <div className='icon status'>
                                                            <i className="fa-solid fa-building"></i>
                                                        </div>
                                                        <div className='detail-block-title-brife'>
                                                            <div className='detail-block-title'>Status</div>
                                                            <div className='detail-block-brife'>Ready to Move</div>
                                                        </div>
                                                    </div>

                                                    
                                                </div>
                                                <div className='collapse' id='collapseExample1'>
                                                <div className='gray-box-row collapse' >
                                                <div className='detail-block'>
                                                        <div className='icon floor'>
                                                            <i className="fa-solid fa-layer-group"></i>
                                                        </div>
                                                        <div className='detail-block-title-brife'>
                                                            <div className='detail-block-title'>Floor</div>
                                                            <div className='detail-block-brife'>Ground out of 3</div>
                                                        </div>
                                                    </div>

                                                    <div className='detail-block'>
                                                        <div className='icon transaction'>
                                                            <i className="fa-solid fa-money-bill-wave"></i>
                                                        </div>
                                                        <div className='detail-block-title-brife'>
                                                            <div className='detail-block-title'>Transaction</div>
                                                            <div className='detail-block-brife'>Resale</div>
                                                        </div>
                                                    </div>
                                                    <div className='detail-block'>
                                                        <div className='icon furnishing'>
                                                            <i className="fa-solid fa-couch"></i>
                                                        </div>
                                                        <div className='detail-block-title-brife'>
                                                            <div className='detail-block-title'>furnishing</div>
                                                            <div className='detail-block-brife'>Unfurnished</div>
                                                        </div>
                                                    </div>
                                                    <div className='detail-block'>
                                                        <div className='icon facing'>
                                                        <i className="fa-regular fa-compass"></i>
                                                        </div>
                                                        <div className='detail-block-title-brife'>
                                                            <div className='detail-block-title'>facing</div>
                                                            <div className='detail-block-brife'>East</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                
                                            </div>
                                            <div className='gray-box-toggle'>
                                                <button className="btn btn-toggle" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample1" aria-expanded="false" aria-controls="collapseExample"></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='row g-3'>
                                        <div className='col-auto'>
                                            <div className='checked-pointes'>
                                                <i className='checked'></i>
                                                <span>East Facing Property</span>
                                            </div>
                                        </div>
                                        <div className='col-auto'>
                                            <div className='checked-pointes'>
                                                <i className='checked'></i>
                                                <span>Newly Constructed Property</span>
                                            </div>
                                        </div>
                                            <div className='col-12'>
                                                <p className='font-16 text-start mb-0'>Have a look at this praiseworthy 4 BHK independent villa that is for sale in one of the desirable locations of Nagole in Hyderabad.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div className='property-price-contact'>
                    <div className='row g-3 flex-row flex-md-column justify-content-between'>
                        <div className='col-md-12 col-auto my-md-5'>
                            <div className="price text-md-center"><span className="rupees">₹</span>1.60 Cr </div>
                            <div className="price_per_sq text-md-center"><span className="rupees">₹</span>11,511 per sqft </div>
                        </div>
                        <div className='col-md-12 col-auto'>
                            <div className='row g-2'>
                                <div className='col-md-12 col-auto text-center'>
                                    <button className='btn btn-contact-owner'>Contact Owner</button>
                                </div>
                                <div className='col-md-12 col-auto text-center'>
                                    <button className='btn btn-get-phone'>Get Phone No.</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
      </div>
    </>
  )
}

export default PropertyList;
