import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import imgView from '../images/view.svg';


function FormPopUp(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button  className={props.className} onClick={handleShow}>
        {props.name}
      </Button>

      <Modal className='loginModal' show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
        centered>
       
       <Modal.Body className='p-4 p-sm-4 p-md-5'>
          <h4>Login</h4>
          <Button variant="close position-absolute top-20 right-20" onClick={handleClose}>
            
          </Button>
        <div className="tab-pane fade show active" id="login-tab-content" role="tabpanel" aria-labelledby="login-tab">
                                                <form name="login_2" id="login_2"  method="post" className="needs-validation row g-3" novalidate="">
                                                    <div className='col-12'>
                                                      <div className="form-group form-control-email">
                                                          <input type="email" className="form-control" id="ShopLoginFormModel_email" placeholder="Email" required=""/>
                                                          <div className="valid-feedback">
                                                              Looks good!
                                                          </div>
                                                      </div>
                                                    </div>
                                                    <div className='col-12'>
                                                      <div className="form-group form-control-password">
                                                        <input type="password" className="form-control" id="ShopLoginFormModel_password" placeholder="Password"/>
                                                        <a className="viewpassword" href="#!" onclick="Toggle()"><img src={imgView}/></a>
                                                      </div>
                                                      <div className='px-3'>
                                                      <div className="row mt-2">
                                                        <div className="col-md-6 pr-2 form-control-check">
                                                            <div className="form-group form-check">
                                                                <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                                                <label className="form-check-label font-14 font-500" for="exampleCheck1">Check me out</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 pl-2">
                                                            <div className="form-group text-end">
                                                                <label for="button2" className="font-14 font-500 cursor-pointer" onclick="forgot_password()">Forgot Your Password?</label>
                                                            </div>
                                                        </div>
                                                       </div>
                                                      </div>
                                                    </div>
                                                    <div className='col-12 d-flex justify-content-center'>
                                                      <a href='/Profile' type="submit" className="btn-login text-decoration-none"><span>Login</span></a>
                                                    </div>
                                                    
                                                    
                                                    
                                                </form>
                                                
                                               
                                            </div>
        </Modal.Body>
        {/* <Modal.Footer>
          
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default FormPopUp;
