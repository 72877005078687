import React from 'react';

function SavedDrafts() {
  return (
    <>
      <main class="content">
            <div class="pageHeader edit-header">
              <div class="row justify-content-between">
                <div class="col-auto">
                  <h1 class="text-dark font-24">Saved Drafts</h1>
                </div>
           
                
              </div>
            </div>

            <div class="template-date-table">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col" width="80">Sr. No</th>
                        <th scope="col" width="500" class="text-start">Article Name</th>
                        <th scope="col" class="text-start">Saved Draft Date</th>
                        <th scope="col" width="200" class="text-end pe-4">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><div>1.</div></td>
                        <td class="text-start"><div><b>The Real Gully Boy</b></div></td>
                        <td class="text-start"><div><b>5-09-2022</b></div></td>
          
                        <td>
                          <div class="d-flex flex-wrap justify-content-end align-items-center pe-3">
                            <a class="btn-publish me-2" href="javascript:;">PUBLISH</a>
                            <a class="btn-delete" href="javascript:;"></a>
                            
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td><div>2.</div></td>
                        <td class="text-start"><div><b>Five Star Chef From Dharavi - An Alumni Story</b></div></td>
                        <td class="text-start"><div><b>5-09-2022</b></div></td>
          
                        <td>
                          <div class="d-flex flex-wrap justify-content-end align-items-center pe-3">
                            <a class="btn-publish me-2" href="javascript:;">PUBLISH</a>
                            <a class="btn-delete" href="javascript:;"></a>
                            
                          </div>
                        </td>
                      </tr>
        
                      <tr>
                        <td><div>3.</div></td>
                        <td class="text-start"><div><b>Think Differently</b></div></td>
                        <td class="text-start"><div><b>5-09-2022</b></div></td>
          
                        <td>
                          <div class="d-flex flex-wrap justify-content-end align-items-center pe-3">
                            <a class="btn-publish me-2" href="javascript:;">PUBLISH</a>
                            <a class="btn-delete" href="javascript:;"></a>
                            
                          </div>
                        </td>
                      </tr>
        
                     
                    </tbody>
                  </table>
                </div>
              </div>
            
          
          </main>
    </>
  )
}

export default SavedDrafts;
