import React, { useState } from 'react';

import Banner from '../../../images/right-img.jpg';

import LeftImg from '../../../images/ee8dabebf5fb0fd88120e1f66eb5d95a@2x.png';
import YouTubeThumb from '../../../images/black-banner.png';
import VideoImgModalPopup from '../../VideoImgModalPopup';

import RecentImgThumba1 from '../../../images/rectangle-12885.png';
import RecentImgThumba2 from '../../../images/rectangle-12887.png';
import RecentImgThumba3 from '../../../images/rectangle-12889.png';

import CloudComputer from '../../../images/cloud-computing.svg';
import videoIcon from '../../../images/video-svg.png';
import SingleColForm from '../../SingleColForm';
import CustomisedFormPopup from '../../Blog/CustomisedFormPopup';

function EditVersion() {

    const [shade, setShade] = useState(false);

    const handleDarkShade = () => {
      setShade(current => !current);
    };
  
  return (
    <>
      <main className="page-wrap">



        <div className="bg-yellow container-fluid white-element pt-5">
            <div className="row position-relative z-2 realGullyBoy">
            
                <div className="col-xxl-6 col-xl-5 col-lg-5 col-md-5">
                    <div className="row justify-content-end h-100 align-items-center">
                        <div className="col-xxl-10 col-xl-10 col-lg-11 col-md-12">
                            <div className="pe-md-4 ps-4 ps-lg-0">
                                <h1 contenteditable="true">The Real<br className="d-none d-xl-block"/> Gully Boy</h1>
                                <div className="sub-title" contenteditable="true">Five Star Chef From Dharavi - An Alumni Story</div>
                                <p contenteditable="true">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus, ac blandit elit tincidunt id.</p>
                                
                                <div className="d-flex flex-wrap align-items-center">
                                    <a data-bs-toggle="modal" data-bs-target="#downloadBrochure" className="btn btn-white" href="javascript:;"><span contenteditable="true">CTA Name</span> <span className="right-arrow"></span></a>
                                    <a className="btn btn-customize" href="javascript:;" data-bs-toggle="modal" data-bs-target="#customize-form"><i></i><span>Customised Form</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-6 col-xl-7 col-lg-7 col-md-7">
                    <div className="row">
                        <div className="col-xl-11">
                            <div className="left-dot" data-img="560px * 526px">
                                <img src={Banner} className="img-fluid" width="893"/>
                                <label className="upload-img position-absolute" for="upload-img">
                                  <span className="btn-browse"><i className="me-2"><img src="images/upload-svg.png"/></i>Upload Image</span>
                                  
                                  <input className="d-none" type="file" id="upload-img"/>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg-white container-fluid py-100">
            <div className="container max-1140 blog-detail-page">
                <div className="row">

                  <div className="col-lg-12 ">
                    <div className="reg_bg">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="red_bg_text" contenteditable="true">
                                    WANT TO DISCOVER YOUR OWN ALUMNI STORIES
                                </div>
                            </div>
                        </div>
                    </div>                                    
                </div>
                <div className="col-lg-auto position-relative z-1 order-lg-1">
                  <div className="rightSideForm rightGraph mt-min-lg-250">
               
                      <h3 contenteditable="true">Book A Demo</h3>
              
                      <SingleColForm/>
                  </div>
              </div>
                    <div className="col-lg mt-5 mt-lg-0">
                        <div className="page-title me-xxl-5 pe-xxl-5">
                            <h2 className="title mb-2 mb-sm-4 text-start" contenteditable="true">Dinesh Narasimha Kansane, Student of SSRVM, Dharavi</h2>
                            <p contenteditable="true">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus, ac blandit elit tincidunt id. Sed rhoncus, tortor sed eleifend tristique, tortor mauris molestie elit, et lacinia ipsum quam nec dui. Quisque nec mauris sit amet elit iaculis pretium sit amet quis magna. Aenean velit odio, elementum in tempus ut, vehicula eu diam. </p>
                            <p contenteditable="true">Pellentesque rhoncus aliquam mattis. Ut vulputate eros sed felis sodales nec vulputate justo hendrerit. Vivamus varius pretium ligula, a aliquam odio euismod sit amet. Quisque laoreet sem sit amet orci ullamcorper at ultricies metus viverra. Pellentesque arcu mauris, malesuada quis ornare accumsan, blandit sed diam.</p>
                            
                     
                        </div>
                    </div>
                    
                    
                    
                </div>
            </div>
        </div>

        <div className="container-fluid bg-litebeige position-relative">
            <div className="container max-1140 beigeSection">
                <div className="row">
                    <div className="col-12">
                        <h2 contenteditable="true">Lorem ipsum dolor sit amet</h2>
                        <p contenteditable="true">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit,<br className="d-none d-md-block"/> vulputate eu pharetra nec, mattis ac neque.</p>
                    </div>
                    <div className="col-lg-6">
                        <div className="beigeSectionImg text-center mt-4 mt-lg-0" data-img="550px * 450px">
                            <img src={LeftImg} className="img-fluid" width="550"/>
                            <label className="upload-img position-absolute" for="upload-img">
                              <span className="btn-browse"><i className="me-2"><img src={CloudComputer}/></i>Upload Image</span>
                              
                              <input className="d-none" type="file" id="upload-img"/>
                            </label>
                        </div>
                    </div>
                    <div className="col-lg-6 ">
                        <div className="pt-4 pt-lg-4 pt-xxl-5 ps-0 ps-lg-4 ps-xxl-5 beigeSectiondetail">                            
                            <ul className="arrow-list mb-4 mb-sm-5" contenteditable="true">
                                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit</li>
                                <li>vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus,</li>
                                <li>vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus</li>
                            </ul>
                            <div className="d-flex flex-wrap align-items-center">
                                <a data-bs-toggle="modal" data-bs-target="#downloadBrochure" className="btn-download-green" href="javascript:;"><i></i> <span> Download Brochure</span></a>
                                
                                <CustomisedFormPopup className="btn btn-customize" />
                                
                              </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg-white container-fluid">
            <div className="container max-1140 recentSuccessStories">
                <h2>Recent Success Stories</h2>
                <div className="x-scroll">
                    <div className="recentSuccessStoriesList">
                        <div className="recentSuccessList">
                            <div className="listImg" data-img="350px * 249px">
                                <img src={RecentImgThumba1} width="100%"/>
                            </div>
                            <div className="listDetail">
                                <h3>Taking A Leaf Out Of the Indigo Airlines Approach</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit nulla quam velit.</p>
                                <div className="date">Aug 3, 2022</div>
                                <a className="btn-read" href="javascript:;">Read More <i></i></a>
                            </div>
                        </div>
    
                        <div className="recentSuccessList">
                            <div className="listImg" data-img="350px * 249px">
                                <img src={RecentImgThumba2} width="100%"/>
                            </div>
                            <div className="listDetail">
                                <h3>Taking A Leaf Out Of the Indigo Airlines Approach</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit nulla quam velit.</p>
                                <div className="date">Aug 3, 2022</div>
                                <a className="btn-read" href="javascript:;">Read More <i></i></a>
                            </div>
                        </div>
    
                        <div className="recentSuccessList">
                            <div className="listImg" data-img="350px * 249px">
                                <img src={RecentImgThumba3} width="100%"/>
                            </div>
                            <div className="listDetail">
                                <h3>Taking A Leaf Out Of the Indigo Airlines Approach</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit nulla quam velit.</p>
                                <div className="date">Aug 3, 2022</div>
                                <a className="btn-read" href="javascript:;">Read More <i></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <a className="btn-viewmore" href="javascript:;">View More <i></i></a>
                </div>
            </div>
        </div>
       

        
    </main>
    </>
  )
}

export default EditVersion;
