import React from 'react';

export default function SearchProperty() {
  return (
    <>
      <div className='searchWrap'>
                                    <div className='inputWrap'>
                                    <div className='searchInput'><input type='text' placeholder='Enter Location' className='form-control'/></div>
                                    <div className='searchSelect'>
                                        <select className="form-select property-type" aria-label="Default select example">
                                            <option selected>House/Villa</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                    <div className='searchSelect'>
                                        <select className="form-select property-budget" aria-label="Default select example">
                                            <option selected>Budget</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                    </div>
                                    <button className='btn btn-search'><i className='icon-search'></i><span>Search</span></button>
                                </div>
    </>
  )
}
