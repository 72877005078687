import React from 'react';

function PrivacyPolicy() {
  return (
    <>
      <section className="container-fluid headerBanner privacyPolicyHeader" id="headerBanner">
            <div className="w-1200 mx-auto py-5">
                <div className="row g-4">
                  <div className="col-md-12">
                    <div className="row g-5 align-items-center">
                        <div className="col-md-13" data-aos="fade-left" data-aos-delay="200">
                            <h1 className="mb-4">Privacy Policy</h1>  
                            <p className="font-25 font-500 text-start mb-4">International Educational Gateway Pvt. Ltd. (hereinafter referred to as “Univariety'', “we”, “us”, or “Our”) may periodically update these terms and you would need to visit this webpage for the most up-to-date policy wordings frequently. You agree to review these Privacy Policies and always remain in compliance. In terms of the Information Technology Act, 2000, this document is an electronic record. Being generated by a computer system it does not require any physical or digital signatures.</p>
                            <p className="font-18 font-400 text-start">
                                <span className="font-700 text-start d-block">Please read the privacy policy carefully before accessing the website</span>
                                If you are under the age of 18, you must have your parent or guardian read the agreement below and agrees to it for you. If you don’t get your parent or guardian to read and agree to this, you don’t have permission to use this site. Please read this privacy policy (“agreement”) carefully before availing the services offered by Univariety. By accessing, viewing, visiting, using, or interacting on this platform or with any banner, pop-up, or advertising that appears on it or by availing any services offered by Univariety, you acknowledge that you have read this agreement and that you accept and will be bound by this agreement. If you do not unconditionally agree to all the terms of this agreement, you may not use the services. By impliedly or expressly accepting these terms of service, you also accept and agree to be legally bound by Univariety’s policies (including but not limited to terms of service and other policies available on the website) as amended from time to time.
                            </p>                                             
                        </div>               
                    </div>
                  </div>               
                </div>
            </div>
        </section>

        <section className="container-fluid privacyPolicy" id="privacyPolicy">
            <div className="w-1200 mx-auto">
                <div className="legal-cont container position-relative">


                    <h2 className="font-24 font-500">This Privacy Policy scope covers the following</h2>
                    <ul className="tick">
                        <li>What information we collect/track about you?</li>
                        <li>How we use the information we collect/track?</li>
                        <li>Our policy on correcting and updating the information you have provided to us.</li>
                        <li>Our policy on your right to opt out of some of our information-sharing practices.</li>
                        <li>This Privacy Policy applies to any information you provide to us via our website, email, telephone, or in any other communication or exchange you have with Univariety.</li>
                    </ul>
    
    
    
                    <h2 className="font-24 font-500">While we would strongly advise you to read the Policy in full, the following summary will give you a snapshot of the salient points covered herein</h2>
                    <ul className="tick">
                        <li>This Policy details the critical aspects governing your personal data relationship with Univariety which is owned and operated by International Educational Gateway Pvt. Ltd., a company incorporated under the Companies Act of 2013, and having its registered office at H. No. 8-2-624, Suite 301, Sri Durga Towers, Road No. 10, Banjara Hills, Hyderabad, Telangana - 500034, India.</li>
                        <li>Your personal data relationship with Univariety varies based on the capacity in which you interact with us/avail of our products and solutions (“Services”). You could be:</li>
                        <ol>
                            <li>A visitor to univariety.com (“Website”) or any pages thereof (“Visitor”);</li>
                            <li>A person/entity availing of one of our Services (“Customer”); or</li>
                            <li>An employee/agent/representative/appointee/member of a Customer organization who uses the said Service (“User”). Based on whether you are a Visitor, Customer, or User, the type of data we collect and the purpose for which it is being used will differ and this Policy details such variations;</li>
                        </ol>
                    </ul>
    
                    <p className="font-18">This Privacy Policy is a part of and should be read in conjunction with our Terms of Service; and when you submit information to us, you are doing so on a completely voluntary basis, at your sole discretion and will. Many times the information you submit is required to obtain the benefit, assistance, or service you are requesting or would like to obtain from us-for instance, to use our services as listed on Univariety.com, or to obtain information about our Client offerings.</p>
                    <p className="font-18">This Policy will clarify the rights available to you vis-à-vis the personal data you share with us. If you disagree with the Policy, we would advise you to not visit/use our website or mobile application/platform (collectively “App”).</p>
    
    
                    <h2 className="font-24 font-500">Information We may collect and its usage</h2>
                    <div className="table-responsive mb-4">
                        <table className="table table-striped" style={{minWidth: '1140px'} }>
                            <thead>
                              <tr>
                                <th scope="col" width="150">TYPE OF USER</th>
                                <th scope="col">VISITOR</th>
                                <th scope="col">CUSTOMER</th>
                                <th scope="col">USER</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">WHAT DATA WE MAY COLLECT</th>
                                <td>
                                    <ul className="tick">
                                        <li>Your IP Address;</li>
                                        <li>Your location;</li>
                                        <li>Your behavior on the Website, (what pages you visit, time spent on each of the pages, etc.);</li>
                                        <li>Device/s being used to access the Website and its details (model, operating system, etc.);</li>
                                        <li>Cookies, and Web Beacon data; and</li>
                                        <li>Data from third parties who have your explicit consent to lawfully share that data.</li>
                                    </ul>
                                    <p className="font-18">If you submit an inquiry through the Website, we will additionally seek the following information from you:</p>
                                    <ul className="tick">
                                        <li>Name</li>
                                        <li>Telephone number</li>
                                        <li>Organization details</li>
                                        <li>e-mail etc.</li>
                                    </ul>
    
    
    
    
    
                                </td>
                                <td>
                                    <ul className="tick">
                                        <li>Your IP Address;</li>
                                        <li>Your location;</li>
                                        <li>The name and e-mail of your representative who signs up for a Service on your behalf; and</li>
                                        <li>Payment mode information to check your financial qualifications, detect fraud and facilitate payments for our Services.</li>
                                    </ul>
    
                                </td>
                                <td>
                                    <ul className="tick">
                                        <li>Your name, e-mail, phone number, and organization name;</li>
                                        <li>Your IP Address;</li>
                                        <li>Your location unless the location is deactivated on your App;</li>
                                        <li>Your behavior on the website and in the relevant product environment and features used;</li>
                                        <li>Device/s being used to access the Website/App and its details (model, operating system, etc.);</li>
                                        <li>Cookies and Web Beacon data;</li>
                                        <li>Data from third parties who have your explicit consent to lawfully share that data;</li>
                                    </ul>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">HOW AND WHY WE USE IT</th>
                                <td>
                                    <p className="font-18">We use this information to analyse and identify your behavior and enhance the interactions you have with us via our Website.</p>
                                    <p className="font-18">If you submit your details and give us your consent, we may use your data to send you e-mails/newsletters, re-target Univariety advertisements or re-market our Services using services from third parties like Facebook and Google.</p>
                                </td>
                                <td>
                                    <p className="font-18">We collect this data in order to help you register for and facilitate provision of our Services.</p>
                                    <p className="font-18">We also use this data to enable you to make payments for our Services. We use a third-party service provider to manage payment processing. This service provider is not permitted to store, retain, or use the information you provide except for the sole purpose of payment processing on our behalf.</p>
                                    <p className="font-18">If you give us your consent, we may send you newsletters and e-mails to market other products and services we may provide. You can withdraw that consent anytime by logging in to your account or sending an email to care@univariety.com</p>
                                </td>
                                <td>
                                    <p className="font-18">We collect this data in order to facilitate provision of our Services. The preference related information in your profile is used to recommend you the content and other relevant users who can help you make an informed career related decision. You can always send an email to us at care@univariety.com, if you wish to get your entire profile information deleted from our database.</p>
                                    <p className="font-18">We will occasionally send you e-mails regarding changes or updates to the Service that you are using. In the event you report an issue with a Service, we may also screen/video record your Device only when you use the App for a limited period to help us better understand how to address the issue.</p>
                                    <p className="font-18">If you give us your consent, we may send you newsletters and e-mails to market other products and services we may provide. You can withdraw that consent anytime by logging in to your account or sending an email to care@univariety.com</p>
                                    <p className="font-18">We may also collect anonymous usage behavior for our aggregation and analysis to determine the usability of various Services and to enhance user experience.</p>
                                </td>
                              </tr>
                            
                            </tbody>
                          </table>
                    </div>
    
                    <h2 className="font-24 font-500">What are the tools referred to above? </h2>
                    <div className="table-responsive mb-4">
                        <table className="table table-striped" style={{minWidth: '1140px'} }>
                            <thead>
                              <tr>
                                <th scope="col" width="200">TYPE OF FILE</th>
                                <th scope="col">COOKIES</th>
                                <th scope="col">WEB BEACON</th>
                              </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">WHAT THEY DO</th>
                                    <td>
                                        <p className="font-18">A “cookie” is a small text file that a website can place on your computer’s hard drive to collect information about your activities on the website. Most browsers automatically accept cookies, but you have the option to change your browser settings to decline them.</p>
                                        <p className="font-18">We use cookies to deliver content specific to your interests, to save your password so that you do not have to re-enter it each time that you visit our Website (if you choose), and for other purposes.</p>
                                    </td>
                                    <td>
                                        <p className="font-18">A web beacon (also known as Clear GIF, Web Bugs, or Pixel Tag) is a technique used on web pages and emails to unobtrusively (usually invisibly) allow checking that a user has accessed some content and track your behavior. It is similar to a cookie in what it does, but it doesn’t get downloaded onto your Device.</p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                    </div>
    
                    <h2 className="font-24 font-500">WHAT THEY WON’T DO</h2>
                    <ul className="tick">
                        <li>Harvest any personally identifiable information; or</li>
                        <li>Contain any executable software, malicious code, or virus.</li>
                    </ul>
    
                    <h2 className="font-24 font-500">Retention of personal information</h2>
                    
                    <p className="font-18">We will store any personal data we collect from you as long as it is necessary in order to facilitate your use of the Services and for ancillary legitimate and essential business purposes – these include, without limitation, for improving our Services, attending to technical issues, and dealing with disputes.</p>
                    <p className="font-18">We may need to retain some/all of your personal data even if you seek deletion thereof, if it is needed to comply with various legal obligations, resolve disputes and enforce our agreements.</p>
                    <p className="font-18">If you are a Customer, please be advised that: (i) you will need to inform your Leads about how you/we (on your behalf) store and deal with any data collected from them using one of our Services, in compliance with applicable laws; and (ii) after you terminate your usage of a Service, we may, unless legally prohibited, delete all data provided or collected by you (including Lead information) from our servers.</p>
    
                   
                    <h2 className="font-24 font-500">Tracking by our advertisement partners</h2>
                    <p className="font-18">Upon receiving your specific consent, we may share the data we collect using various tools with our advertisement partners to track your visits, establish your non-personal identity and present you with targeted advertisements about our Services.</p>
                  
    
                    <h2 className="font-24 font-500">Tools used by our customers</h2>
                    <p className="font-18">If you are a Customer, you are empowered to use proprietary or other third-party technologies on your website and host them in our App. If you do, you agree and acknowledge that it is your sole obligation to inform your Leads about any data you collect by using such technologies, how it is being used, and the policies by which such collection is bound.</p>
    
    
                    <h2 className="font-24 font-500">Transfer of information to Third Parties</h2>
                    <p className="font-18">In the ordinary course of business, we may employ third parties to assist us in providing certain components of our Services in compliance with the provisions of this and the Terms of Service Policy. To do so, we may need to share your data with them.</p>
                    <p className="font-18">Wherever applicable, we have entered into adequate Confidentiality agreements with these third parties to ensure the confidentiality and safety of such data.</p>
    
                    <h2 className="font-24 font-500">Third-party links</h2>
                    <p className="font-18">We may display links to third-party websites or applications on our Website or App for advertising or providing you with relevant content. We will not be responsible for such third-party websites or applications if you choose to access them. If you provide any data to such a website/application, please ensure you read their policies given that you will no longer be bound by this Policy in doing so.</p>
    
                    <h2 className="font-24 font-500">Compelled disclosure</h2>
                    <p className="font-18">In addition to the purposes set out in the Policy, we may disclose any data we collected or processed from you if it is required:</p>
                    <ul className="tick">
                        <li>Under applicable law or to respond to a legal process, such as a court order, or subpoena;</li>
                        <li>To protect our safety, your safety, or the safety of others or in the legitimate interest of any party in the context of national security, law enforcement, litigation, criminal investigation or to prevent death or imminent bodily harm;</li>
                        <li>To investigate fraud or violation of our Terms of Service and Acceptable Use policy;</li>
                        <li>If required in connection with legal proceedings brought against Univariety, its officers, employees, affiliates, customers, or vendors; or</li>
                        <li>To establish, exercise, protect, defend, and enforce our legal rights.</li>
                    </ul>
    
                    <h2 className="font-24 font-500">Security of your personal information</h2>
                    <p className="font-18">At International Educational Gateway Pvt. Ltd., we value data privacy and understand the Client’s need to keep applicant information confidential. International Educational Gateway Pvt. Ltd. has leased highly secure cloud server architecture from providers like US-based Amazon Web Server, which is rated the world leader in secure cloud computing by Gartner Research. Our cloud platform uses SSL encryption for ensuring secure connections between servers and browsers to help protect your data from unauthorized access, use, loss, destruction, or disclosure.</p>
                    <p className="font-18">Your password is your first line of defense once you set up a Univariety account, so we recommend that you set a strong password that you never share with anyone and ensure you sign out of your Univariety account once you have used that same. Further, it is advised to change the password at regular intervals to ensure the safety of your account.</p>
    
                    <h2 className="font-24 font-500">Changes in Privacy Policy</h2>
                    <p className="font-18">If we decide to change our privacy policy, we will post those changes to this privacy statement, and other places we deem appropriate so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it. You are advised to visit this page regularly to familiarize yourself with the latest privacy policy.</p>
    
                    <h2 className="font-24 font-500">Your Privacy Choices</h2>
                    <p className="font-18">We provide you the opportunity to ‘opt out’ of having your personally identifiable information used for certain purposes when we ask for this information. If you prefer that we not share information you have provided to us with third parties, you may opt out of some of these types of disclosures.</p>
                    <p className="font-18">If you no longer wish to receive our newsletter communications, you may opt out of receiving email communication from us by following the instructions included in each newsletter or communication or by emailing us (see “unsubscribe” below).</p>
                    <p className="font-18">If you no longer want us to use your information, then you can request that we erase your Personal Information by writing to us at <a className="text-black font-500" href="mailto:care@univariety.com">care@univariety.com</a> along with your details such as name, email address, and mobile number. Once received, your request will be processed and resolved within 30 days.</p>
                    <p className="font-18">In addition, to tell us not to share information you can also write to us at the following address:</p>
                    <p className="font-18">H. No. 8-2-624, Suite 301, Sri Durga Towers, Road No. 10, Banjara Hills, Hyderabad, Telangana - 500034, India.</p>
    
                    
    
    
                </div>
            </div>
        </section>
    </>
  )
}

export default PrivacyPolicy;
