import React from 'react';

import ImgFamilyContact from '../../images/family-contact.svg';
import ImgGlyph from '../../images/glyph.svg';

import ImgBanner from '../../images/contact_header_img.png';

function ContactUs() {
  return (
    <>
      <section className="container-fluid headerBanner client_banner" id="headerBanner">
            <div className="w-1080 mx-auto pt-3">
                <div className="row g-4">
                  <div className="col-md-12">
                    <div className="row g-5 align-items-center">
                        <div className="col-md-6" data-aos="fade-left" data-aos-delay="200">
                            <h1 className="mb-3">We would love to hear from you!</h1>
                            <p className="text-start mb-4">Univariety is all about you!<br className="d-none d-sm-block" />
                                Go ahead and send in your queries about our guidance programs, products, events, partnerships and more.</p>
                            <address>
                                <ul>
                                    <li className="mobile"><a href="tel:93920 01234"> +91 93920 01234 </a></li>
                                    <li className="mail"><a href="mailto:contactus@univariety.com"> contactus@univariety.com </a></li>
                                    <li className="location">
                                        Suite 301B, Sri Durga Towers, Road No. 10,<br className="d-none d-sm-block" />Banjara Hills, Hyderabad 500034,<br className="d-none d-sm-block" />Telangana, India
                                    </li>
                                </ul>
                            </address>                    
                        </div>
                        <div className="col-md-6 text-center">
                            <img data-aos="fade-right" data-aos-delay="200" src={ImgBanner} width="383" className="img-fluid" alt=""/>
                        </div>
                    </div>
                  </div>
               
                </div>
            </div>
        </section>

        <section className="container-fluid contactMails" id="contactMails">
            <div className="w-1200 mx-auto">
                <h2 className="text-white mb-5">For any new enquiry please drop here</h2>
                <div className="row pt-3">
                    
                    <div className="col-md-6 col-sm-6 mb-5">
                        <div className="callout bg-white radius-4 position-relative p-4 text-center mb-4">
                            <div className="icon d-flex justify-content-center align-items-center position-absolute">
                                <img src={ImgFamilyContact}/>
                            </div>
                            <p className="font-14 font-500 mt-4 m-0 text-center">Students / Parents</p>
                            <a className="font-18 font-600" href="mailto:products@univariety.com">products@univariety.com</a>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 mb-5">
                        <div className="callout bg-white radius-4 position-relative p-4 text-center mb-4">
                            <div className="icon d-flex justify-content-center align-items-center position-absolute">
                                <img src={ImgGlyph}/>
                            </div>
                            <p className="font-14 font-500 mt-4 m-0 text-center">School</p>
                            <a className="font-18 font-600" href="mailto:schools@univariety.com">schools@univariety.com</a>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 mb-5 mb-lg-3 mb-md-3">
                        <div className="callout bg-white radius-4 position-relative p-4 text-center mb-4">
                            <div className="icon d-flex justify-content-center align-items-center position-absolute">
                            <img src={ImgFamilyContact}/>
                            </div>
                            <p className="font-14 font-500 mt-4 m-0 text-center">University</p>
                            <a className="font-18 font-600" href="mailto:university@univariety.com">university@univariety.com</a>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 mb-3 mb-lg-3 mb-md-3">
                        <div className="callout bg-white radius-4 position-relative p-4 text-center mb-4">
                            <div className="icon d-flex justify-content-center align-items-center position-absolute">
                            <img src={ImgGlyph}/>
                            </div>
                            <p className="font-14 font-500 mt-4 m-0 text-center">Counsellor</p>
                            <a className="font-18 font-600" href="mailto:trainingandcertification@univariety.com">trainingandcertification@univariety.com</a>
                        </div>
                    </div>


                    <div className="col-md-6 col-sm-6 mb-3">
                        <h4 className="font-24 text-white text-center mb-5">For any support related query<br/>please mail us here</h4>
                        <div className="callout bg-white radius-4 position-relative p-4 text-center mb-4">
                            <div className="icon d-flex justify-content-center align-items-center position-absolute">
                            <img src={ImgFamilyContact}/>
                            </div>
                            <p className="font-14 font-500 mt-4 m-0 text-center">Support</p>
                            <a className="font-18 font-600" href="mailto:care@univariety.com">care@univariety.com</a>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 mb-3">
                        <h4 className="font-24 text-white text-center mb-5">For marketing &amp; events partnership opportunity please write us at</h4>
                        <div className="callout bg-white radius-4 position-relative p-4 text-center mb-4">
                            <div className="icon d-flex justify-content-center align-items-center position-absolute">
                            <img src={ImgGlyph}/>
                            </div>
                            <p className="font-14 font-500 mt-4 m-0 text-center">Partnership</p>
                            <a className="font-18 font-600" href="mailto:contactus@univariety.com">contactus@univariety.com</a>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>

        <section className="container-fluid contactForm" id="contactForm">
            <div className="w-1200 mx-auto">
               
                <div className="row g-4 g-sm-5">
                    
                    <div className="col-lg-3 col-md-4">
                        <h3 className="font-25 font-600">Here’s our communication timeline!</h3>
                        <div className="greenListBlock">
                            <ul>
                                <li><i></i><span>You can write in your queries to us</span></li>
                                <li><i></i><span>If necessary, a personalized agent will assist you when required</span></li>
                                <li><i></i><span>All your queries will be answered and closed within 1 or 2 working days</span></li>
                                <li><i></i><span>If you’re a new customer, you can sign in and register to avail services</span></li>
                                <li><i></i><span>Your valuable feedback on social media would be much appreciated</span></li>
                            </ul>
                        </div>
                    </div>
                   
                    
                    <div className="col-lg-9 col-md-8">
                        
                        <div className="FormTab_scroll">
                            <ul className="nav nav-tabs" id="FormTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="Student-tab" data-bs-toggle="tab" data-bs-target="#Student" type="button" role="tab" aria-controls="Student" aria-selected="true">Student</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="Parent-tab" data-bs-toggle="tab" data-bs-target="#Parent" type="button" role="tab" aria-controls="Parent" aria-selected="false">Parent</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="School-tab" data-bs-toggle="tab" data-bs-target="#School" type="button" role="tab" aria-controls="School" aria-selected="false">School</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="University-tab" data-bs-toggle="tab" data-bs-target="#University" type="button" role="tab" aria-controls="University" aria-selected="false">University</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="Counsellor-tab" data-bs-toggle="tab" data-bs-target="#Counsellor" type="button" role="tab" aria-controls="Counsellor" aria-selected="false">Counsellor</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    
                                    <button className="nav-link" id="Others-tab" data-bs-toggle="tab" data-bs-target="#Others" type="button" role="tab" aria-controls="Others" aria-selected="false">Others</button>
                                </li>
                            </ul>
                        </div>
        
                        <div className="tab-content" id="FormTabContent">
                            <div className="tab-pane fade show active" id="Student" role="tabpanel" aria-labelledby="Student-tab">
                                <form className="needs-validation" name="student_form" id="student_form" action="Contactus/LSQ_save" method="post">
                                    <p className="text-end font-13 font-500 mb-4">* Marked fields are mandatory</p>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="FirstNamestd" className="form-label">Name*</label></div>
                                        <div className="col-md-8"><input type="text" className="form-control flex-1" name="FirstNamestd" id="FirstNamestd"/>
                                        <div className="error-feedback" id="FirstNamestdsp"></div>
                                    </div>
                                    </div>                          
                                
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="ParentNamestd" className="form-label">Parent Name*</label></div>
                                        <div className="col-md-8"><input type="text" className="form-control flex-1" name="ParentNamestd" id="ParentNamestd"/>
                                        <div id="ParentNamestdsp" className="error-feedback"></div>
                                    </div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="ContactNumberstd" className="form-label">Contact Number*</label></div>
                                        <div className="col-md-8"><input type="text" className="form-control flex-1" name="ContactNumberstd" id="ContactNumberstd"/>
                                        <div id="ContactNumberstdsp" className="error-feedback"></div>
                                    </div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="EmailAddressstd" className="form-label">Email Address*</label></div>
                                        <div className="col-md-8"><input type="email" className="form-control flex-1" id="EmailAddressstd" name="EmailAddressstd"/>
                                        <div id="EmailAddressstdsp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="SchoolNamestd" className="form-label">School Name*</label></div>
                                        <div className="col-md-8"><input type="text" className="form-control flex-1" name="SchoolNamestd" id="SchoolNamestd"/>
                                        <div id="SchoolNamestdsp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="HelpfulInformationstd" className="form-label">Helpful Information</label></div>
                                        <div className="col-md-8">
                                            <textarea className="form-control flex-1" id="HelpfulInformationstd" name="HelpfulInformationstd" rows="3"></textarea>
                                            <button type="submit" className="btn btn-submit grdGreenBlue mt-4" name="submit_std" id="submit_std" onclick="return onForm_Submit('1')"><span>Submit</span></button>
                                        </div>
                                    </div>
                                   
                                    
                                 
                                </form>
                            </div>
                            
        
                            <div className="tab-pane fade" id="Parent" role="tabpanel" aria-labelledby="Parent-tab">
                                <form className="needs-validation" id="parent_form" name="parent_form" action="Contactus/LSQ_save" method="post">
                                    <p className="text-end font-13 font-500 mb-4">* Marked fields are mandatory</p>
        
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="FirstNameP" className="form-label">First Name*</label></div>
                                        <div className="col-md-8"><input type="text" className="form-control flex-1" name="FirstNameparn" id="FirstNameparn"/>
                                        <div id="FirstNameparnsp" className="error-feedback"></div></div>
                                    </div>

                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="StudentNameP" className="form-label">Student Name*</label></div>
                                        <div className="col-md-8"><input type="text" className="form-control flex-1" name="StudentNameparn" id="StudentNameparn"/>
                                        <div id="StudentNameparnsp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="ContactNumberP" className="form-label">Contact Number*</label></div>
                                        <div className="col-md-8"><input type="text" className="form-control flex-1" name="ContactNumberparn" id="ContactNumberparn"/>
                                        <div id="ContactNumberparnsp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="EmailAddressP" className="form-label">Email Address*</label></div>
                                        <div className="col-md-8"><input type="email" className="form-control flex-1" name="EmailAddressparn" id="EmailAddressparn"/>
                                        <div id="EmailAddressparnsp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="SchoolNameP" className="form-label">School Name*</label></div>
                                        <div className="col-md-8"><input type="text" className="form-control flex-1" name="SchoolNameparn" id="SchoolNameparn"/>
                                        <div id="SchoolNameparnsp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="HelpfulInformationP" className="form-label">Helpful Information</label></div>
                                        <div className="col-md-8">
                                            <textarea className="form-control flex-1" id="HelpfulInformationP" name="HelpfulInformationP" rows="3"></textarea>
                                            <button type="submit" className="btn btn-submit grdGreenBlue mt-4" form="parent_form" name="submit_prt" id="submit_prt" onclick="return onForm_Submit('2')"><span>Submit</span></button>
                                        </div>
                                    </div>

                                </form>
                            </div>
        
                            <div className="tab-pane fade" id="School" role="tabpanel" aria-labelledby="School-tab">
                                <form className="needs-validation" id="school_form" name="school_form" action="Contactus/LSQ_save" method="post">
                                    <p className="text-end font-13 font-500 mb-4">* Marked fields are mandatory</p>
        
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="SchoolNameSch" className="form-label">School Name*</label></div>
                                        <div className="col-md-8"><input type="text" className="form-control flex-1" name="SchoolNameSch" id="SchoolNameSch"/>
                                        <div id="SchoolNameSchsp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="CountrySch" className="form-label">Country*</label></div>
                                        <div className="col-md-8"><input type="text" className="form-control flex-1" name="CountrySch" id="CountrySch"/>
                                        <div id="CountrySchsp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="CitySch" className="form-label">City*</label></div>
                                        <div className="col-md-8"><input type="text" className="form-control flex-1" name="CitySch" id="CitySch"/>
                                        <div id="CitySchsp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="YourNameSch" className="form-label">Your Name*</label></div>
                                        <div className="col-md-8"><input type="text" className="form-control flex-1" name="YourNameSch" id="YourNameSch"/>
                                        <div id="YourNameSchsp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="DesignationSch" className="form-label">Designation*</label></div>
                                        <div className="col-md-8"><input type="text" className="form-control flex-1" name="DesignationSch" id="DesignationSch"/>
                                        <div id="DesignationSchsp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="ContactNumberSch" className="form-label">Contact Number*</label></div>
                                        <div className="col-md-8"><input type="text" className="form-control flex-1" name="ContactNumberSch" id="ContactNumberSch"/>
                                        <div id="ContactNumberSchsp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="EmailAddressSch" className="form-label">Email Address*</label></div>
                                        <div className="col-md-8"><input type="email" className="form-control flex-1" name="EmailAddressSch" id="EmailAddressSch"/>
                                        <div id="EmailAddressSchsp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="PreferredDateTimeSch" className="form-label">Preferred Date/Time</label></div>
                                        <div className="flex-1 col-md-8">
                                            <div className="form-group mb-3">
                                                <div className="input-group date" id="datetimepicker">
                                                    <input type="text" className="form-control unstyled datepicker hasDatepicker" id="PreferredDateTimeSch" placeholder="Select Date" name="PreferredDateTimeSch"/><br/>
                                                    <div id="PreferredDateTimeSchsp" className="error-feedback"></div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                           <select className="form-select" aria-label="Default select example" id="time" name="time">
                                                <option selected="" value="">Select Time</option>
                                                <option value="Before 9:30 am">Before 9:30 am</option>
                                                <option value="9:30 am - 11:30 am">9:30 am - 11:30 am</option>
                                                <option value="11:30 am - 1:30 pm">11:30 am - 1:30 pm</option>
                                                <option value="1:30 pm - 3:30 pm">1:30 pm - 3:30 pm</option>
                                                <option value="3:30 pm - 5:30 pm">3:30 pm - 5:30 pm</option>
                                                <option value="5:30 pm - 7:30 pm">5:30 pm - 7:30 pm</option>
                                                <option value="After 7:30 pm">After 7:30 pm</option>
                                                
                                                </select>
                                                <div id="Timesp" className="error-feedback"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="HelpfulInformationSch" className="form-label">Helpful Information</label></div>
                                        <div className="col-md-8">
                                            <textarea className="form-control flex-1" id="HelpfulInformationSch" name="HelpfulInformationSch" rows="3"></textarea>
                                            <button type="submit" className="btn btn-submit grdGreenBlue mt-4" id="submit_sch" name="submit_sch" onclick="return onForm_Submit('3')"><span>Submit</span></button>
                                        </div>
                                    </div>

                                
                                </form>
                            </div>
        
                            <div className="tab-pane fade" id="University" role="tabpanel" aria-labelledby="University-tab">
                                <form className="needs-validation" id="university_form" name="university_form" action="Contactus/LSQ_save" method="post">
                                    <p className="text-end font-13 font-500 mb-4">* Marked fields are mandatory</p>
        
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="UniversityNameUni" className="form-label">University Name*</label></div>
                                        <div className="col-md-8"><input type="text" className="form-control flex-1" name="UniversityNameUni" id="UniversityNameUni"/>
                                        <div id="UniversityNameUnisp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="CountryUni" className="form-label">Country*</label></div>
                                        <div className="col-md-8"><input type="text" className="form-control flex-1" name="CountryUni" id="CountryUni"/>
                                        <div id="CountryUnisp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="CityUni" className="form-label">City*</label></div>
                                        <div className="col-md-8"><input type="text" className="form-control flex-1" name="CityUni" id="CityUni"/>
                                        <div id="CityUnisp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="YourNameUni" className="form-label">Your Name*</label></div>
                                        <div className="col-md-8"><input type="text" className="form-control flex-1" name="YourNameUni" id="YourNameUni"/>
                                        <div id="YourNameUnisp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="DesignationUni" className="form-label">Designation*</label></div>
                                        <div className="col-md-8"><input type="text" className="form-control flex-1" name="DesignationUni" id="DesignationUni"/>
                                        <div id="DesignationUnisp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="ContactNumberUni" className="form-label">Contact Number*</label></div>
                                        <div className="col-md-8"><input type="text" className="form-control flex-1" name="ContactNumberUni" id="ContactNumberUni"/>
                                        <div id="ContactNumberUnisp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="EmailAddressUni" className="form-label">Email Address*</label></div>
                                        <div className="col-md-8"><input type="email" className="form-control flex-1" name="EmailAddressUni" id="EmailAddressUni"/>
                                        <div id="EmailAddressUnisp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="PreferredDateTimeUni" className="form-label">Preferred Date/Time</label></div>
                                        <div className="flex-1 col-md-8">
                                            <div className="form-group mb-3">
                                                <div className="input-group date" id="datetimepicker">
                                                    <input type="text" className="form-control unstyled datepicker hasDatepicker" id="PreferredDateTimeUni" placeholder="Select Date" name="PreferredDateTimeUni"/>
                                                    
                                                    <div id="PreferredDateTimeUnisp" className="error-feedback"></div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                
                                               <select className="form-select" aria-label="Default select example" id="time" name="time">
                                                <option selected="" value="">Select Time</option>
                                                <option value="Before 9:30 am">Before 9:30 am</option>
                                                <option value="9:30 am - 11:30 am">9:30 am - 11:30 am</option>
                                                <option value="11:30 am - 1:30 pm">11:30 am - 1:30 pm</option>
                                                <option value="1:30 pm - 3:30 pm">1:30 pm - 3:30 pm</option>
                                                <option value="3:30 pm - 5:30 pm">3:30 pm - 5:30 pm</option>
                                                <option value="5:30 pm - 7:30 pm">5:30 pm - 7:30 pm</option>
                                                <option value="After 7:30 pm">After 7:30 pm</option>
                                                
                                                </select>
                                                <div id="timesp" className="error-feedback"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="HelpfulInformationUni" className="form-label">Helpful Information</label></div>
                                        <div className="col-md-8">
                                            <textarea className="form-control flex-1" id="HelpfulInformationUni" name="HelpfulInformationUni" rows="3"></textarea>
                                            <button type="submit" className="btn btn-submit grdGreenBlue mt-4" id="submit_uni" name="submit_uni" onclick="return onForm_Submit('4')"><span>Submit</span></button>
                                        </div>
                                    </div>
                         
                                   
                                </form>
                            </div>
                            
        
                            <div className="tab-pane fade" id="Counsellor" role="tabpanel" aria-labelledby="Counsellor-tab">
                                <form className="needs-validation" id="counsellor_form" name="counsellor_form" action="Contactus/LSQ_save" method="post">
                                    <p className="text-end font-13 font-500 mb-4">* Marked fields are mandatory</p>
        
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="YourNameCoun" className="form-label">Your Name*</label></div>
                                        <div className="col-md-8"><input type="text" className="form-control flex-1" name="YourNameCoun" id="YourNameCoun"/>
                                        <div id="YourNameCounsp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="EmailAddressCoun" className="form-label">Email Address*</label></div>
                                        <div className="col-md-8"><input type="email" className="form-control flex-1" name="EmailAddressCoun" id="EmailAddressCoun"/>
                                        <div id="EmailAddressCounsp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="ContactNumberCoun" className="form-label">Contact Number*</label></div>
                                        <div className="col-md-8"><input type="text" className="form-control flex-1" name="ContactNumberCoun" id="ContactNumberCoun"/>
                                        <div id="ContactNumberCounsp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="DesignationCoun" className="form-label">Designation*</label></div>
                                        <div className="col-md-8">
                                        <select className="form-select form-control flex-1" name="DesignationCoun" id="DesignationCoun" aria-label="Default select example">
                                            <option>Select</option>
                                            <option value="Educator">Educator</option>
                                            <option value="Counsellor">Counsellor</option>
                                            <option value="Management">Management</option>
                                            <option value="Others">Others</option>
                                        </select>
                                        <div id="DesignationCounsp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="HelpfulInformationCoun" className="form-label">Helpful Information</label></div>
                                        <div className="col-md-8">
                                            <textarea className="form-control flex-1" id="HelpfulInformationCoun" name="HelpfulInformationCoun" rows="3"></textarea>
                                            <button type="submit" className="btn btn-submit grdGreenBlue mt-4" id="submit_cun" name="submit_cun" onclick="return onForm_Submit('5')"><span>Submit</span></button>
                                        </div>
                                    </div>
                                 
                                </form>
                            </div>
        
                            <div className="tab-pane fade" id="Others" role="tabpanel" aria-labelledby="Others-tab">
                                <form className="needs-validation" id="others_form" name="others_form" action="Contactus/LSQ_save" method="post">
                                    <p className="text-end font-13 font-500 mb-4">* Marked fields are mandatory</p>
        
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="YourNameOth" className="form-label">Your Name*</label></div>
                                        <div className="col-md-8"><input type="text" className="form-control flex-1" name="YourNameOth" id="YourNameOth"/>
                                        <div id="YourNameOthsp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="EmailAddressOth" className="form-label">Email Address*</label></div>
                                        <div className="col-md-8"><input type="email" className="form-control flex-1" name="EmailAddressOth" id="EmailAddressOth"/>
                                        <div id="EmailAddressOthsp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="ContactNumberOth" className="form-label">Contact Number*</label></div>
                                        <div className="col-md-8"><input type="text" className="form-control flex-1" name="ContactNumberOth" id="ContactNumberOth"/>
                                        <div id="ContactNumberOthsp" className="error-feedback"></div></div>
                                    </div>
                                    <div className="row g-0 mb-3">
                                        <div className="col-md-4"><label for="HelpfulInformationOth" className="form-label">Helpful Information</label></div>
                                        <div className="col-md-8">
                                            <textarea className="form-control flex-1" id="HelpfulInformationOth" name="HelpfulInformationOth" rows="3"></textarea>
                                            <button type="submit" className="btn btn-submit grdGreenBlue mt-4" id="submit_oth" name="submit_oth" ><span>Submit</span></button>
                                        </div>
                                    </div>

                                </form>
                            </div>
        
                        </div>
                    </div>
            
                </div>
                
            </div>
        </section>
    </>
  )
}

export default ContactUs
