import React, { useState, useRef  } from 'react';
import Header from '../../Header';
import Footer from '../../Footer';

import TemplateModalPopup from '../TemplateModalPopup';

import EditVersion from './EditVersion';
import CloudComputer from '../../../images/cloud-computing.svg';

function Template4Edit() {
  const [file, setFile] = useState(false);

  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    setFile(event.target.files);
  };

  const handleReset = () => {
    setFile(null);
    fileInputRef.current.value = '';
  };

  return (
    <>
      <div className='py-5'>
        <div className='max-1140 mx-auto'>
            <div className='pageHeader edit-header'>
                <div className="row justify-content-between align-items-center">
                    <div className="col-sm-auto">
                    <h1 className="text-dark font-24">Template 4 - Video &amp; Para Text</h1>
                    </div>
                    <div className="col-sm-auto text-right add_user">
                    <a href="add-edit-pg-smart-apply-lead.html" className="btn btn-save me-2">Save as</a>
                    <TemplateModalPopup className="btn btn-preview me-2" template="/BlogTemplate4" />
                    <a href="javascript:;" className="btn btn-publish">Publish</a>
                    </div>
                </div>
            </div>
            <div className='template-edit-section'>
                <div className="row mb-4 pb-2">
                <div className="col-6">
                  <div className="row">
                    <div className="col-auto">
                      <label for="articleName" className="form-label font-16 font-400 my-2 pt-1">
                        Article Name
                      </label>
                    </div>
                    <div className="col">
                      <input type="email" className="form-control" id="articleName" value="The Real Gully Boy"/>
                      <div className="text-end font-14 text-gray-8e fst-italic">
                        50 Characters
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="thumb-wrap">
                    <label  onChange={handleFileChange}
      className={`thumb-image ${file  ? 'select' : 'unselect'}`} for="thumb-image">
                      <span className="btn-browse">
                        <i className="me-2">
                          <img src={CloudComputer}/>
                        </i>
                      </span>
                      <input className="fileInput" type="file" id="thumb-image" ref={fileInputRef}  required=""/>
                      <span className="default-text">
                        Add Thumbnail banner
                      </span>
                    </label>
                    <a className="remove-file" onClick={handleReset} href="javascript:;"></a>
                  </div>
                </div>
         
              </div>

              <div className="template-edit-section-body" id="template_one">
               <Header/>

               <EditVersion/>

               <Footer/>
              </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default Template4Edit;
