import React, { useRef } from 'react';
import banner from '../../images/clientele.png';

import Imgtips from '../../images/tips.png';
import Imgvdjs from '../../images/vdjs.png';
import ImgAvasara from '../../images/image-56.png';

import ImgMctm from '../../images/emblem.png';
import ImgDpsHyd from '../../images/image-57.png';
import ImgMITGurukul from '../../images/image-58.png';
import ImgDais from '../../images/dais-logo.png';
import ImgGreenwoodHighSchool from '../../images/greenwood-logo.png';
import ImgRamjasSchool from '../../images/years-excellence.png';
import ImgKalyani from '../../images/kalyani.png';
import ImgWorliDypatil from '../../images/image-60.png';
import ImgIndiraNationalSchool from '../../images/ins.png';
import ImgJayshree from '../../images/image-62.png';

import ImgGopiBirlaMemorialSchool from '../../images/gbms.png';
import ImgLighthouseGroup from '../../images/ins.png';
import ImgRyanInternationalSchools from '../../images/image-62.png';

import ImgSchoolOfScholarsMegheGroup from '../../images/meghe.png';
import ImgReliance from '../../images/reliance-foundation.svg';
import ImgRelianceFoundation from '../../images/reliance.png';
import ImgDalmiaVidyaMandir from '../../images/dvm.png';

import ImgSSRVMSchools from '../../images/ssrvm-logo.png';
import ImgAmritaVidyalayam from '../../images/amrita-vidyalayam-logo-vertical-blue.svg';
import VideoImgModalPopup from '../VideoImgModalPopup';


function OurClientele({ targetId, offset = 0, animationDuration = 100 }) {

    const targetRef = useRef(null);

  const handleScroll = () => {
    const targetElement = targetRef.current;
    if (!targetElement) return; // Handle missing target

    const targetTop = targetElement.offsetTop - offset;
    window.scrollTo({ top: targetTop, behavior: 'smooth' });
  };
  return (
    <>
      <section className="container-fluid headerBanner client_banner" id="headerBanner">
            <div className="w-1080 mx-auto pt-3 mb-5 mb-lg-0">
                <div className="row g-4">
                  <div className="col-md-12">
                    <div className="row g-5 align-items-center">
                        <div className="col-md-6" data-aos="fade-left" data-aos-delay="200">
                            <h1>Our Clientele</h1>
                            <p className="text-start">Trusted by institutions across geographies and scale. From single campus schools, large group schools, to corporate groups, and faith based trusts, we have aligned our mission to enable each client institution's success.</p>
                            <a id='scrollToButton' onClick={handleScroll} href="#connect" className="btn-bookdemo grdBlueLavender btn-move"><span>BOOK A DEMO</span></a>   
                            
                                                        
                        </div>
                        <div className="col-md-6 text-center">
                            <img data-aos="fade-right" data-aos-delay="200" src={banner} width="383" className="img-fluid" alt=""/>
                        </div>
                    </div>
                  </div>
               
                </div>
            </div>
        </section>

        <section className="container-fluid client_list" id="client_list" data-aos="fade-up" data-aos-delay="200">
            <div className="tab-section row mt-5">
                <div className="w-1000 mx-auto">
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button className="nav-link active" id="pills-singleCampus-tab" data-bs-toggle="pill" data-bs-target="#pills-singleCampus" type="button" role="tab" aria-controls="pills-singleCampus" aria-selected="true">Single Campus</button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="pills-groupInstitutions-tab" data-bs-toggle="pill" data-bs-target="#pills-groupInstitutions" type="button" role="tab" aria-controls="pills-groupInstitutions" aria-selected="false">Group of Institutions</button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="pills-corporateHouses-tab" data-bs-toggle="pill" data-bs-target="#pills-corporateHouses" type="button" role="tab" aria-controls="pills-corporateHouses" aria-selected="false">Corporate Houses</button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="pills-faithBasedTrusts-tab" data-bs-toggle="pill" data-bs-target="#pills-faithBasedTrusts" type="button" role="tab" aria-controls="pills-faithBasedTrusts" aria-selected="false">Faith Based Trusts</button>
                        </li>
                      </ul>
                </div>
                  
            </div>
            <div className="w-980 mx-auto py-5 mt-2 mb-4" >
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-singleCampus" role="tabpanel" aria-labelledby="pills-singleCampus-tab" tabindex="0">
                        <div className="row g-2 g-sm-3 justify-content-center">
                            <div className="col-auto">
                                <div className="client-logo">
                                    <img src={Imgtips} alt=""/>
                                </div>
                                <div className="client-name">The Indian Public School</div>
                            </div>
                            <div className="col-auto">
                                <div className="client-logo">
                                    <img src={Imgvdjs} alt=""/>
                                </div>
                                <div className="client-name">Vidya Devi Jindal School</div>
                            </div>
                            <div className="col-auto">
                                <div className="client-logo">
                                    <img src={ImgAvasara} alt=""/>
                                </div>
                                <div className="client-name">Avasara Academy, Pune</div>
                            </div>
                            <div className="col-auto">
                                <div className="client-logo">
                                    <img src={ImgMctm} alt=""/>
                                </div>
                                <div className="client-name">M.C.T. M Chidambaram Chettyar International School</div>
                            </div>
                            <div className="col-auto">
                                <div className="client-logo">
                                    <img src={ImgDpsHyd} alt=""/>
                                </div>
                                <div className="client-name">DPS Hyderabad</div>
                            </div>
                            <div className="col-auto">
                                <div className="client-logo">
                                    <img src={ImgMITGurukul} alt=""/>
                                </div>
                                <div className="client-name">MIT Gurukul</div>
                            </div>
                            <div className="col-auto">
                                <div className="client-logo">
                                    <img src={ImgDais} alt=""/>
                                </div>
                                <div className="client-name">Dhirubhai Ambani International School</div>
                            </div>
                            <div className="col-auto">
                                <div className="client-logo">
                                    <img src={ImgGreenwoodHighSchool} alt=""/>
                                </div>
                                <div className="client-name">Greenwood High School</div>
                            </div>
                            <div className="col-auto">
                                <div className="client-logo">
                                    <img src={ImgRamjasSchool} alt=""/>
                                </div>
                                <div className="client-name">Ramjas School</div>
                            </div>
                            <div className="col-auto">
                                <div className="client-logo">
                                    <img src={ImgKalyani} alt=""/>
                                </div>
                                <div className="client-name">Kalyani School Alumni Association</div>
                            </div>
                            <div className="col-auto">
                                <div className="client-logo">
                                    <img src={ImgWorliDypatil} alt=""/>
                                </div>
                                <div className="client-name">DY Patil International School, Worli</div>
                            </div>
                            <div className="col-auto">
                                <div className="client-logo">
                                    <img src={ImgIndiraNationalSchool} alt=""/>
                                </div>
                                <div className="client-name">Indira National School</div>
                            </div>
                            <div className="col-auto">
                                <div className="client-logo">
                                    <img src={ImgJayshree} alt=""/>
                                </div>
                                <div className="client-name">Jayshree Periwal International School</div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-groupInstitutions" role="tabpanel" aria-labelledby="pills-groupInstitutions-tab" tabindex="0">
                        <div className="row g-2 g-sm-3 justify-content-center">
                            <div className="col-auto">
                                <div className="client-logo">
                                    <img src={ImgGopiBirlaMemorialSchool} alt=""/>
                                </div>
                                <div className="client-name">Gopi Birla Memorial School</div>
                            </div>
                            <div className="col-auto">
                                <div className="client-logo">
                                    <img src={ImgDpsHyd} alt=""/>
                                </div>
                                <div className="client-name">DPS Bangalore South</div>
                            </div>
                            <div className="col-auto">
                                <div className="client-logo">
                                    <img src={ImgDpsHyd} alt=""/>
                                </div>
                                <div className="client-name">DPS Bangalore North</div>
                            </div>
                            <div className="col-auto">
                                <div className="client-logo">
                                    <img src={ImgDpsHyd} alt=""/>
                                </div>
                                <div className="client-name">DPS Bangalore East</div>
                            </div>
                            <div className="col-auto">
                                <div className="client-logo">
                                    <img src={ImgDpsHyd} alt=""/>
                                </div>
                                <div className="client-name">DPS Gurgaon</div>
                            </div>
                            <div className="col-auto">
                                <div className="client-logo">
                                    <img src={ImgLighthouseGroup} alt=""/>
                                </div>
                                <div className="client-name">Lighthouse Group</div>
                            </div>
                            <div className="col-auto">
                                <div className="client-logo">
                                    <img src={ImgRyanInternationalSchools} width="76" className="img-fluid" alt=""/>
                                </div>
                                <div className="client-name">Ryan International Schools</div>
                            </div>

                            
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-corporateHouses" role="tabpanel" aria-labelledby="pills-corporateHouses-tab" tabindex="0">
                        <div className="row g-2 g-sm-3 justify-content-center">                      
                            <div className="col-auto">
                                <div className="client-logo">
                                    <img src={ImgSchoolOfScholarsMegheGroup} alt=""/>
                                </div>
                                <div className="client-name">School Of Scholars, Meghe Group</div>
                            </div>
                            <div className="col-auto">
                                <div className="client-logo">
                                    <img src={ImgRelianceFoundation} alt=""/>
                                </div>
                                <div className="client-name">Reliance Foundation School, Surat</div>
                            </div>
                            <div className="col-auto">
                                <div className="client-logo">
                                    <img src={ImgRelianceFoundation} alt=""/>
                                </div>
                                <div className="client-name">Reliance Foundation School, Koparkhairane</div>
                            </div>
                            <div className="col-auto">
                                <div className="client-logo">
                                    <img src={ImgReliance} alt=""/>
                                </div>
                                <div className="client-name">Kokilaben Dhirubhai Ambani Reliance Foundation School Jamnagar</div>
                            </div>
                            <div className="col-auto">
                                <div className="client-logo">
                                    <img src={ImgDalmiaVidyaMandir} alt=""/>
                                </div>
                                <div className="client-name">Dalmia Vidya Mandir</div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-faithBasedTrusts" role="tabpanel" aria-labelledby="pills-faithBasedTrusts-tab" tabindex="0">
                        <div className="row g-2 g-sm-3 justify-content-center">
                        <div className="col-auto">
                            <div className="client-logo">
                                <img src={ImgSSRVMSchools} alt=""/>
                            </div>
                            <div className="client-name">SSRVM Schools</div>
                        </div>
                        <div className="col-auto">
                            <div className="client-logo">
                                <img src={ImgAmritaVidyalayam} alt=""/>
                            </div>
                            <div className="client-name">Amrita Vidyalayam</div>
                        </div>
                    </div>
                    </div>
                  </div>                  
            </div>
        </section>
        
        <section className="why_choose_block">
            <div className="w-1200 mx-auto">
                <h2>Why Choose Univariety?</h2>
                <div className="why_choose_wrapper">
                    <div className="text-center " data-aos="fade-left">
                        
                        <VideoImgModalPopup className="video-btn play-button" videoId="v3pCpQelvNk" banner="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/app/themes/uni_new/images/wcu_img01.jpg" imgWidth={210}/>                        
                        <h3 className="font-30 font-500 cursorpointer homevdoplaybtn1">Students</h3>
                        <p className="font-16 font-500 cursorpointer homevdoplaybtn1">believe in us while making their career decisions</p>
                    </div>
                    <div className="text-center " data-aos="fade-left" data-aos-delay="200">
                        
                        <VideoImgModalPopup className="video-btn play-button" videoId="S03YtXo-KjE" banner="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/app/themes/uni_new/images/wcu_img02.jpg" imgWidth={210}/>                        
                        <h3 className="font-30 font-500 cursorpointer homevdoplaybtn2">Parents</h3>
                        <p className="font-16 font-500 cursorpointer homevdoplaybtn2">trust in us with their child's future career choices</p>
                    </div>
                    <div className="text-center " data-aos="fade-left" data-aos-delay="400">
                        
                        <VideoImgModalPopup className="video-btn play-button" videoId="zD2IBwBpvwY" banner="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/app/themes/uni_new/images/wcu_img03.jpg" imgWidth={210}/>
                        <h3 className="font-30 font-500 cursorpointer homevdoplaybtn3">Schools</h3>
                        <p className="font-16 font-500 cursorpointer homevdoplaybtn3">vouch for us for our world-class counselling services</p>
                    </div>
                    <div className="text-center " data-aos="fade-left" data-aos-delay="800">
                        
                        <VideoImgModalPopup className="video-btn play-button" videoId="BqhaWmn8hI0" banner="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/app/themes/uni_new/images/wcu_img04.jpg" imgWidth={210}/>
                        
                        <h3 className="font-30 font-500 cursorpointer homevdoplaybtn4">Universities</h3>
                        <p className="font-16 font-500 cursorpointer homevdoplaybtn4">partner with us for our unique services</p>
                    </div>
                    <div className="text-center " data-aos="fade-left" data-aos-delay="1000">
                        
                        <VideoImgModalPopup className="video-btn play-button" videoId="LcKsnx2iZQI" banner="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/app/themes/uni_new/images/wcu_img05.jpg" imgWidth={210}/>
                        
                        <h3 className="font-30 font-500 cursorpointer homevdoplaybtn5">Counsellors</h3>
                        <p className="font-16 font-500 cursorpointer homevdoplaybtn5" id="webinar_event">collaborate with us for career transformations</p>
                    </div>
                </div>
                
            </div>
        </section>

        <section className="container-fluid connect" id={targetId} ref={targetRef} data-aos="fade-up" data-aos-delay="200">
            <div className="w-750 mx-auto" >
                <h2>Looking for something? Connect with Us</h2>
                <form className="needs-validation" novalidate="">
                    <div className="row g-3">
                        
                        <div className="col-md-6">
                            <input type="text" className="form-control flex-1" placeholder="Name *" id="FirstNamestd" required=""/>
                            <div className="invalid-feedback">Enter Valid  Name</div>
                        </div>

                        <div className="col-md-6">
                            <input type="email" className="form-control flex-1" placeholder="Email ID *" id="EmailAddressstd" required=""/>
                            <div className="invalid-feedback">Enter Valid Email Address</div>
                        </div>

                        <div className="col-md-12">
                            <input type="text" className="form-control flex-1" placeholder="Institution Name *" id="FirstNamestd" required=""/>
                            <div className="invalid-feedback">Enter Valid Institution Name</div>
                        </div>

                        <div className="col-md-6">
                            <input type="text" className="form-control flex-1" placeholder="Contact Number *" id="ContactNumberstd" required=""/>
                            <div className="invalid-feedback">Enter Valid Contact Number</div>
                        </div>

                        <div className="col-md-6">
                            <input type="text" className="form-control flex-1" placeholder="City of Institution *" id="ContactNumberstd" required=""/>
                            <div className="invalid-feedback">Enter Valid Contact Number</div>
                        </div>

                        <div className="col-md-6">
                            <select className="form-select" aria-label="Default select example">
                                <option selected>Select State</option>
                                <option value="1">...</option>
                              </select>
                            <div className="invalid-feedback">Enter Valid Contact Number</div>
                        </div>

                        <div className="col-md-6">
                            <input type="text" className="form-control flex-1" placeholder="Function Area*" id="ContactNumberstd" required=""/>
                            <div className="invalid-feedback">Enter Valid Contact Number</div>
                        </div>

                        <div className="col-md-12 d-flex justify-content-center mt-4 pt-2">
                            <button type="submit" className="btn btn-submit">GET STARTED!</button>
                        </div>

                    </div>
                 
                </form>
            </div>
        </section>
    </>
  )
}

export default OurClientele
