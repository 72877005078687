import React from 'react';
import TemplateSelection from './TemplateSelection';
import SavedDrafts from './SavedDrafts';
import PublishedArticles from './PublishedArticles';

function BlogsTab() {
  return (
    <>
      <div className='bg-gray-f3 py-5 h-100'>
        <div className='max-1140 mx-auto '>
        <ul class="nav nav-pills mb-5 blogtab" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active" id="pills-templates-tab" data-bs-toggle="pill" data-bs-target="#pills-templates" type="button" role="tab" aria-controls="pills-templates" aria-selected="true">Templates</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-saved-drafts-tab" data-bs-toggle="pill" data-bs-target="#pills-saved-drafts" type="button" role="tab" aria-controls="pills-saved-drafts" aria-selected="false">Saved Drafts</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-published-articles-tab" data-bs-toggle="pill" data-bs-target="#pills-published-articles" type="button" role="tab" aria-controls="pills-published-articles" aria-selected="false">Published Articles</button>
            </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-templates" role="tabpanel" aria-labelledby="pills-templates-tab">
                <TemplateSelection/>
            </div>
            <div class="tab-pane fade" id="pills-saved-drafts" role="tabpanel" aria-labelledby="pills-saved-drafts-tab">
                <SavedDrafts/>
            </div>
            <div class="tab-pane fade" id="pills-published-articles" role="tabpanel" aria-labelledby="pills-published-articles-tab">
                <PublishedArticles/>
            </div>
        </div>
        </div>
        

      </div>
    </>
  )
}

export default BlogsTab;
