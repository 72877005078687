import React from 'react';
import kasdeal from '../../images/kasdeal-logo.png';
import qrImg from '../../images/qr-code.png';

function PreMarketPulse() {
  return (
    <>
      <div className='container-fluid'>
            <div className='w-1200 mx-auto py-5'>
                <div className='row g-5 align-items-center'>
                    <div className="col-12">
                        <div className='p-5 bg_gold rounded'>
                          <div className='row g-3 align-items-center'>
                            <div className='col-auto'><img src={kasdeal} width={40} className='img-fluid' alt="" /></div>
                            <div className='col'><a href='https://www.kasdeals.com/' className='font-30 text-black text-decoration-none text-break'>https://www.kasdeals.com/</a></div>
                            <div className='col-12'><h1 className='m-0 font-36 font-600'>Wed, 24 Jul 2024</h1></div>
                          </div>
                            
                            
                        </div>
                    </div>

                    <div className="col-12">
                        <div className='row'>
                            <div className='col-md'>
                                <h3 className='font-22 font-500 mb-0'>Global Indices</h3>
                            </div>

                            <div className='col-md-auto text-gray06'>
                            As of Today at 7:24 am in the Morning
                            </div>

                            <div className='col-12'>
                                  <div className="table-responsive">
                                    <table className="table table-striped">
                                      <thead>
                                        <tr >
                                          <th scope="col" className='font-500'>Name</th>
                                          <th scope="col" className='font-500'> </th>
                                          <th scope="col" className='font-500'>LTP</th>
                                          <th scope="col" className='font-500'>Change</th>
                                          <th scope="col" className='font-500'>Change %</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr className='positive'>
                                          <th scope="row" className='font-500'><i className="fa-solid fa-circle-chevron-down me-2 negitive"></i> GIFT Nifty </th>
                                          <td><i className="fa-regular fa-clock me-2 positive"></i> <span className='text-gray3'>Asia</span></td>
                                          <td className='negitive'><span className='negitive'>24,402.5</span> </td>
                                          <td><span className='negitive'>-68.5M</span></td>
                                          <td><span className='negitive'>-0.28%</span></td>
                                        </tr>

                                        <tr className='negitive'>
                                          <th scope="row" className='font-500'><i className="fa-solid fa-circle-chevron-down me-2 negitive"></i> Dow Jones</th>
                                          <td><i className="fa-regular fa-clock me-2 negitive"></i> <span className='text-gray3'>US</span></td>
                                          <td><span className='negitive'>40358.09</span></td>
                                          <td><span className='negitive'>-57.35</span></td>
                                          <td><span className='negitive'>-0.14%</span></td>
                                        </tr>

                                        <tr className='negitive'>
                                          <th scope="row" className='font-500'><i className="fa-solid fa-circle-chevron-down me-2 negitive"></i> Nasdaq</th>
                                          <td><i className="fa-regular fa-clock me-2 negitive"></i> <span className='text-gray3'>US</span></td>
                                          <td><span className='negitive'>17997.35</span></td>
                                          <td><span className='negitive'>-10.22</span></td>
                                          <td><span className='negitive'>-0.06%</span></td>
                                        </tr>

                                        <tr className='negitive'>
                                          <th scope="row" className='font-500'><i className="fa-solid fa-circle-chevron-down me-2 negitive"></i> S&P 500</th>
                                          <td><i className="fa-regular fa-clock me-2 negitive"></i> <span className='text-gray3'>US</span></td>
                                          <td><span className='negitive'> 5555.74</span></td>
                                          <td><span className='negitive'>-8.67</span></td>
                                          <td><span className='negitive'>-0.16%</span></td>
                                        </tr>

                                        <tr className='negitive'>
                                          <th scope="row" className='font-500'><i className="fa-solid fa-circle-chevron-down me-2 negitive"></i> Hang Seng</th>
                                          <td><i className="fa-regular fa-clock me-2 positive"></i> <span className='text-gray3'>Asia</span></td>
                                          <td><span className='negitive'>17399.77</span></td>
                                          <td><span className='negitive'>-69.59</span></td>
                                          <td><span className='negitive'>-0.40%</span></td>
                                        </tr>

                                        <tr className='negitive'>
                                          <th scope="row" className='font-500'><i className="fa-solid fa-circle-chevron-down me-2 negitive"></i> Nikkei 225</th>
                                          <td><i className="fa-regular fa-clock me-2 positive"></i> <span className='text-gray3'>Asia</span></td>
                                          <td><span className='negitive'>39560.9</span></td>
                                          <td><span className='negitive'>-33.49</span></td>
                                          <td><span className='negitive'>-0.08%</span></td>
                                        </tr>

                                        <tr className='negitive'>
                                          <th scope="row" className='font-500'><i className="fa-solid fa-circle-chevron-up me-2 positive"></i> KOSPI</th>
                                          <td><i className="fa-regular fa-clock me-2 positive"></i> <span className='text-gray3'>Asia</span></td>
                                          <td><span className='positive'> 2774.97</span></td>
                                          <td><span className='positive'>+0.68</span></td>
                                          <td><span className='positive'>+0.02%</span></td>
                                        </tr>

                                        <tr className='negitive'>
                                          <th scope="row" className='font-500'><i className="fa-solid fa-circle-chevron-down me-2 negitive"></i> FTSE 100</th>
                                          <td><i className="fa-regular fa-clock me-2 negitive"></i> <span className='text-gray3'>Europe</span></td>
                                          <td><span className='negitive'>8167.37</span></td>
                                          <td><span className='negitive'>-31.41</span></td>
                                          <td><span className='negitive'>-0.38%</span></td>
                                        </tr>                                

                                      </tbody>
                                    </table>
                                  </div>                            
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className='row'>
                            <div className='col-md'>
                                <h3 className='font-22 font-500 mb-0'>Indian Indices</h3>
                            </div>

                            <div className='col-md-auto text-gray06'>As of Most Recent Market Close</div>

                            <div className='col-12'>
                                  <div className="table-responsive">
                                    <table className="table table-striped">
                                      <thead>
                                        <tr >
                                          <th scope="col" className='font-500'>Name</th>
                                          <th scope="col" className='font-500' width={200}> </th>
                                          <th scope="col" className='font-500'>LTP</th>
                                          <th scope="col" className='font-500'>Change</th>
                                          <th scope="col" className='font-500'>Change %</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr className='positive'>
                                          <th scope="row" className='font-500'><i className="fa-solid fa-circle-chevron-down me-2 negitive"></i> Sensex </th>
                                          <td><i className="fa-regular fa-clock me-2 negitive"></i> <span className='text-gray3'></span></td>
                                          <td className='negitive'><span className='negitive'> 80429.04</span> </td>
                                          <td><span className='negitive'>-73.04</span></td>
                                          <td><span className='negitive'>-0.09%</span></td>
                                        </tr>

                                        <tr className='negitive'>
                                          <th scope="row" className='font-500'><i className="fa-solid fa-circle-chevron-down me-2 negitive"></i>  Nifty 50</th>
                                          <td><i className="fa-regular fa-clock me-2 negitive"></i> </td>
                                          <td><span className='negitive'>24479.05</span></td>
                                          <td><span className='negitive'>-30.20</span></td>
                                          <td><span className='negitive'>-0.12%</span></td>
                                        </tr>

                                        <tr className='negitive'>
                                          <th scope="row" className='font-500'><i className="fa-solid fa-circle-chevron-down me-2 negitive"></i> Nifty Bank</th>
                                          <td><i className="fa-regular fa-clock me-2 negitive"></i> </td>
                                          <td><span className='negitive'>51778.3</span></td>
                                          <td><span className='negitive'>-502.10</span></td>
                                          <td><span className='negitive'>-0.96%</span></td>
                                        </tr>

                                        <tr className='negitive'>
                                          <th scope="row" className='font-500'><i className="fa-solid fa-circle-chevron-down me-2 negitive"></i> India VIX</th>
                                          <td><i className="fa-regular fa-clock me-2 negitive"></i> </td>
                                          <td><span className='negitive'>12.75</span></td>
                                          <td><span className='negitive'>-2.69</span></td>
                                          <td><span className='negitive'>-17.43%</span></td>
                                        </tr>

                                      </tbody>
                                    </table>
                                  </div>                            
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                      <h2 className='font-24 text-start mb-3'>Market Bulletin</h2>

                      <div className='body-green-tick'>
                        <ul className='text-black'>
                          <li>Indian stock market is expected to open lower today, influenced by weak global trends </li>
                          <li>Asian markets are down; Japan's Nikkei is flat, Topix fell 0.23%, South Korea's Kospi dropped 0.7%, Hong Kong's Hang Seng indicates a flat opening</li>
                          <li>US stocks closed lower as investors focused on Alphabet and Tesla Q2 results; Tesla shares fell 8% after disappointing earnings</li>
                          <li>Sensex fell by 73 points (0.09%) to 80,429.04, and Nifty 50 dropped 30 points (0.12%) to 24,479.05 after Budget 2024 announcements</li>
                          <li>Union Budget emphasizes employment, skilling, and infrastructure investment, with fiscal targets of 4.9% in FY25 and 4.5% in FY26</li>
                          <li>Japan’s factory activity contracted in July while the service sector expanded, with the composite PMI rising to 52.6</li>
                          <li>Crude oil prices rose slightly; Brent crude futures up 0.54% to $81.45 a barrel, US West Texas Intermediate crude up 0.49% to $77.34 per barrel</li>
                          <li>US dollar index remains strong; dollar/yen fell nearly 1%, China’s yuan steady, euro and sterling firm</li>
                          <li>Gold rates stable; spot gold at $2,409.66 per ounce, US gold futures at $2,410.50 ahead of US economic data</li>
                          <li>Securities in ban period: GNFC, India Cements, Sail.</li>
                        </ul>
                      </div>
                    </div>

                    <div className='col-12'>
                      <div className='row g-4'>
                      <div className="col-12">
                      <h2 className='font-24 text-start mb-3'>Technical Analysis</h2>

                      <h3 className='font-20 text-start mb-3 d-flex align-items-center'><i className='custom-icon purple nifty fst-normal font-14'>50</i> <span>Nifty</span></h3>

                      <div className='body-green-tick'>
                        <ul className='text-black'>
                          <li>Nifty saw a dip post the Budget speech but recovered swiftly, closing just below the 24,500 mark  </li>
                          <li>FMCG sector led the recovery with ITC, Titan, and Tata Consumer Products as top gainers</li>
                          <li>ITC received a boost due to no changes in tobacco tax, nearing the ₹500 mark</li>
                          <li>Immediate support levels for Nifty are at 24,200 and 24,000, with resistance at 24,700 - 24,800</li>
                          <li>The short-term trend is negative with high volatility, but a move above 24,650 - 24,700 can lead to further upside.</li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-12">

                      <h3 className='font-20 text-start mb-3 d-flex align-items-center'><i className='custom-icon green bank-nifty'><i className="fa-solid fa-layer-group"></i></i> <span>Bank Nifty</span></h3>

                      <div className='body-green-tick'>
                        <ul className='text-black'>
                            <li>Bank Nifty dropped over 500 points, with HDFC Bank and ICICI Bank as major laggards</li>
                            <li>The index has formed a lower top, lower bottom pattern, indicating weakness</li>
                            <li>Key resistance levels are at 52,000 and 52,500, with support at 51,200 and 51,000</li>
                            <li>Slipped below the 21-Day Exponential Moving Average, indicating a weakening trend. Resistance band is 52,100 - 52,550, and support band is 51,200 - 51,000</li>
                            <li>Bank Nifty will also be in focus for the results of Axis Bank and the monthly options expiry.</li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-12">

                      <h3 className='font-20 text-start mb-3 d-flex align-items-center'><i className='custom-icon mint-green'><i className="fa-solid fa-chevron-up"></i></i> <span>Nifty Call Options Data</span></h3>

                      <div className='body-green-tick'>
                        <ul className='text-black'>
                            <li>Key Resistance: 25,000 strike with the highest open interest (1.12 crore contracts)</li>
                            <li>Significant Call Writing: At 25,000 strike (44.05 lakh contracts added)</li>
                            <li>Call Unwinding: Major unwinding at 23,900 strike (1.11 lakh contracts)</li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-12">

                      <h3 className='font-20 text-start mb-3 d-flex align-items-center'><i className='custom-icon lite-red'><i className="fa-solid fa-chevron-down"></i></i> <span> Nifty Put Options Data</span></h3>

                      <div className='body-green-tick'>
                        <ul className='text-black'>
                            <li>Key Support: 24,000 strike with the highest open interest (81.97 lakh contracts) </li>
                            <li>Significant Put Writing: At 23,900 strike (23.41 lakh contracts added)</li>
                            <li>Put Unwinding: Major unwinding at 23,600 strike (17.63 lakh contracts).</li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className='border p-4 rounded max-800'>
                            <h4 className='font-20 text-start font-600'> Technical Analysis Source:</h4>
                            <p className='font-16 line-height-18 text-start m-0 text-mint-hard-green font-500'>The above technical analysis is sourced from various popular news articles. This is 
                            provided for reference purposes only; please conduct your own due diligence</p>
                      </div>
                    </div>


                      </div>
                    </div>

                    

                    <div className="col-12">
                      <h2 className='font-24 text-start mb-3 d-flex align-items-center'><i className='custom-icon grd-green'></i> Stocks in the News</h2>

                      <div className='body-green-tick'>
                        <ul className='text-black'>
                          <li><span className='d-block text-blue font-500'>Bajaj Finance: </span>
                            Net profit up 13.8% to ₹3,912 crore; Net Interest Income up 24.5% to ₹8,365 crore  </li>

                          <li><span className='d-block text-blue font-500'>Hindustan Unilever:</span>
                            Revenue at ₹15,339 crore; EBITDA at ₹3,606 crore; Home care revenue up 4.6%; Volume growth at 4% </li>

                          <li><span className='d-block text-blue font-500'>Thyrocare Technologies: </span>
                          Net profit up 38.2% to Q3.9 crore; Revenue up 16.3% to e156.9 crore; EBITDA up 23.4% to *42.7 crore.
                          </li>

                          <li><span className='d-block text-blue font-500'>Schaeffler India: </span>
                          Net profit up 3.5% to ?245.4 crore; Revenue up 15.2% to Z2,106.8 crore; EBITDA up 10% to ?376.3 crore.
                          </li>

                          <li><span className='d-block text-blue font-500'>Torrent Pharma:</span>
                            Revenue up 10.3% to 22,859 crore; India business revenue up 15% to e 1,635 crore.
                          </li>

                          <li><span className='d-block text-blue font-500'>Suven Pharmaceuticals: </span>
                          Received 'no adverse observations' from BSE and 'no observations' from NSE for the scheme of amalgamation with Cohance Lifesciences.
                          </li>

                          <li><span className='d-block text-blue font-500'>IOL Chemicals And Pharmaceuticals:</span>
                           Received EDQM certificate of suitability for Valsartan tablets, used to treat high blood pressure</li>
                           
                           <li><span className='d-block text-blue font-500'>Titagarh Rail Systems:</span> 
                           Began the export of traction converters with the first shipment to Italy</li>

                          <li><span className='d-block text-blue font-500'>Shilpa Medicare: </span>
                          Unit manufacturing facility in Karnataka received GMP certification from COFEPRIS-Mexico</li>

                          <li><span className='d-block text-blue font-500'>Infosys:</span> 
                          Signed a contract with UVC Partners to co-create next generation solutions using AI and deep technology</li>

                          <li><span className='d-block text-blue font-500'>Bajel Projects : </span> 
                          Received an order worth ₹568 crore from PowerGrid Corp. for a transmission system project in Rajasthan</li>

                          <li><span className='d-block text-blue font-500'>Tide Water Oil:</span>
                           Pre-Market Pulse Decided to change its name to Veedol Corporation.</li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-12">
                      <h2 className='font-24 text-start mb-3 d-flex align-items-center'><i className='custom-icon lite-red'></i> Stocks in the News</h2>

                      <div className='body-green-tick'>
                        <ul className='text-black'>
                          <li><span className='d-block text-blue font-500'>ICICI Prudential: </span>
                          Value of New Business at Q472 crore, below expectation of ?558 crore; VNB margin at 24%, down from 30% last year.</li>

                          <li><span className='d-block text-blue font-500'>United Spirits:</span>
                           Net profit up 1.7% to ?485 crore; Revenue up 3.5% to Q,761 crore; EBITDA unchanged at e 713 crore; EBITDA margin at 25.8%, down from 26.7%.</li>

                          <li><span className='d-block text-blue font-500'>Vedanta:</span>
                          Announced board meeting to consider second interim dividend, with no financial performance highlights
                          </li>

                          <li><span className='d-block text-blue font-500'>Tata Consumer Products: </span>
                          Rights issue price fixed at ₹818 per share, a 35% discount to Tuesday's close.
                          </li>

                         
                        </ul>
                      </div>
                    </div>

                    <div className='col-12'>
                        <div className='row g-4'>
                        <div className="col-12">
                          <div className='hightlight lite-red px-3 px-sm-4 px-md-4 py-3 py-sm-4'>
                              <div className='row g-2 g-sm-2 g-md-4 align-items-center'>
                                  <div className='col-sm-auto'>
                                    <div className='icon'>FII</div>
                                  </div>
                                  <div className='col-sm'>
                                    <p>FII were net sellers with total sales amounting to Rs -2975.3 Cr on 23, Jul 2024 (Cash)</p>
                                  </div>
                              </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className='hightlight green px-3 px-sm-4 px-md-4 py-3 py-sm-4'>
                              <div className='row g-2 g-sm-2 g-md-4 align-items-center'>
                                  <div className='col-sm-auto'>
                                    <div className='icon'>DII</div>
                                  </div>
                                  <div className='col-sm'>
                                    <p>DII were net buyers with total sales amounting to Rs 1418.8 Cr on 23, Jul 2024 (Cash)</p>
                                  </div>
                              </div>
                          </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-12">
                      <h2 className='font-24 text-start mb-3'>PCR of Indices <span className='font-400'>(weekly expiry)</span></h2>

                      <div className='body-green-tick'>
                        <table className='noBgTable' width={750}>
                          <thead>
                            <tr>
                              <th className='text-gray-8b'>Indices</th>
                              <th  colspan="2" className='text-gray-8b'>PCR</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td width={200} className='font-18 font-600'>Nifty</td>
                              <td width={150} className='font-18 font-600 text-green'> 0.7676</td>
                              <td  rowspan="2" className='font-16 font-500'>A PCR above 1 indicates that the put volume 
                          has exceeded the call volume. It indicates an 
                          increase in the bearish sentiment.</td>
                            </tr>
                            <tr>
                              <td className='font-18 font-600'>Bank Nifty</td>
                              <td className='font-18 font-600 text-green'>0.4801</td>
                            </tr>
                            <tr>
                              <td className='font-18 font-600'>Fin Nifty</td>
                              <td className='font-18 font-600 text-green'> 0.6436</td>
                              <td  rowspan="2" className='font-16 font-500'>A PCR below 1 indicates that the call volume 
                          exceeds the put volume. It signifies a bullish 
                          market ahead. </td>
                            </tr>
                            <tr>
                              <td className='font-18 font-600'> Midcap Nifty</td>
                              <td className='font-18 font-600 text-green'>0.98</td>
                            </tr>
                          </tbody>
                        </table>                   
                      </div>
                    </div>

                    <div className="col-12">
                      <div className='border p-3 rounded max-600'>
                          <div className='row g-3 align-items-center'>
                              <div className='col-sm-auto'><img src={qrImg} width={150} alt="" className='img-fluid' /></div>

                              <div className='col-sm'>
                                  <div className='font-24 font-700 lineHeight-26 mb-3'>Do you want to receive our daily report?</div>
                                  <div className='font-18 font-600 mb-0'>Scan this, or visit</div>
                                  <a href="https://www.kasdeals.com/" className='font-18 font-500 text-green'>https://www.kasdeals.com/</a>
                              </div>
                          </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <h3 className='font-18 font-600 text-gray3'>Contact Us</h3>

                      <ul className='contactus'>
                        <li><i class="fa-solid fa-envelope me-2"></i> <a href="mailto:premarketpulse@gmail.com"> premarketpulse@gmail.com</a> <i class="fa-solid fa-arrow-up-right-from-square"></i></li>
                        <li><i class="fa-brands fa-whatsapp me-2"></i> <a href="tel:+917386666422"> +91 7386666422</a> <i class="fa-solid fa-arrow-up-right-from-square"></i></li>
                        <li><i class="fa-solid fa-globe me-2"></i> <a href="https://www.kasdeals.com/"> www.kasdeals.com</a> <i class="fa-solid fa-arrow-up-right-from-square"></i></li>

                      </ul>

                      <h5 className='font-600 mb-2'>Disclaimer</h5>
                      <p className='text-start text-gray-8b font-16 mb-4'>By utilising "Pre-Market Pulse," it's important to acknowledge that our service does not offer investment tricks, tips, or
specific buy or sell recommendations, Our reports are crafted for informational purposes only. While we strive for
accuracy, the contents of the PDF may contain errors; therefore, we strongly encourage you to conduct your own
research before making any investment decisions. "Pre-Market pulse" accepts no responsibility for the outcomes of
your investment choices. It is your responsibility to perform due diligence.<br/>
The information we provide is sourced from publicly available data; we do not engage in independent research or
endorse any specific investment strategies. If at any point you decide that "Pre-Market Pulse" does not align with your
informational needs, you have the option to discontinue receiving our reports and exit our community.</p>

<h5 className='font-600 mb-2'>Terms & Conditions:</h5>
<p className='text-start text-gray-8b font-16 mb-4'>By subscribing to "Pre-Market Pulse," you're choosing to stay informed with the latest market news. We diligently work
to deliver regular reports on market trends, but please understand that there may occasionally be days when we are
unable to send a report. Recognizing the need for flexibility, we offer a refund policy that allows for cancellations within
three days of your purchase. It's crucial to note that Tre-Market Pulse" does not provide financial analysis or expert
advice; our service is focused on consolidating and presenting the latest market news. Your subscription to our services
indicates your agreement to these terms. We reserve the right to modify these terms at any time, and your continued
engagement with "Pre-Market Pulse" reflects your acceptance of any such changes.</p>
<h5 className='font-600 mb-2'>Source:</h5>
<p className='text-start text-gray-8b font-16'>Information sourced from Investing.com, Moneycontrol, and many other online publications.</p>
                    </div>
                </div>
            </div>
      </div>
    </>
  )
}

export default PreMarketPulse;
