import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './Home/Home';
import AlumniImpactProgram from './AlumniImpactProgram/AlumniImpactProgram';
import CareerGuidanceProgram from './CareerGuidanceProgram/CareerGuidanceProgram';
import AboutUs from './AboutUs/AboutUs';
import OurClientele from './OurClientele/OurClientele';
import QuestionsPage from './Faq/QuestionsPage';
import ContactUs from './ContactUs/ContactUs';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import TermsConditions from './TermsConditions/TermsConditions';
import RefundPolicy from './RefundPolicy/RefundPolicy';
import PreMarketPulse from './PreMarketPulse/PreMarketPulse.tsx';
import TalentDetail from './TalentDetail/TalentDetail';
import ProfilePage from './ProfilePage/ProfilePage';
import BlogList from './Blog/BlogList';
import BlogTemplate1 from './Blog/Template1/BlogTemplate1';
import BlogTemplate2 from './Blog/Template2/BlogTemplate2';
import BlogTemplate3 from './Blog/Template3/BlogTemplate3';
import BlogTemplate4 from './Blog/Template4/BlogTemplate4';
import BlogsTab from './BlogEdit/BlogsTab';
import Template1Edit from './BlogEdit/Template1Edit/Template1Edit';
import Template2Edit from './BlogEdit/Template2Edit/Template2Edit';
import Template3Edit from './BlogEdit/Template3Edit/Template3Edit';
import Template4Edit from './BlogEdit/Template4Edit/Template4Edit';

import PropertyShowCase from './PropertyShowCase/PropertyShowCase.tsx';

function Main() {
  return (
    <main className='flex-1'>
      <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />  
                <Route path="/Alumni-Impact-Programl" element={<AlumniImpactProgram />} />  
                <Route path="/Career-Guidance-Program" element={<CareerGuidanceProgram />} />  
                <Route path="/About-Us" element={<AboutUs />} />  
                <Route path="/PropertyShowCase" element={<PropertyShowCase/>} />  
                <Route path="/Our-Clientele" element={<OurClientele />} />  
                <Route path="/Faq" element={<QuestionsPage/>} />  
                <Route path="/Contact-Us" element={<ContactUs/>} />  
                <Route path="/Privacy-Policy" element={<PrivacyPolicy/>} /> 
                <Route path="/Terms-and-Conditions" element={<TermsConditions/>} /> 
                <Route path="/Refund-Policy" element={<RefundPolicy/>} />  
                <Route path="/Pre-Market-Pulse" element={<PreMarketPulse/>} />  
                <Route path="/Talent-Detail" element={<TalentDetail/>} />  
                <Route path="/Profile" element={<ProfilePage/>} />  
                <Route path="/Blog" element={<BlogList/>} />  
                <Route path="/BlogTemplate1" element={<BlogTemplate1/>} />  
                <Route path="/BlogTemplate2" element={<BlogTemplate2/>} />  
                <Route path="/BlogTemplate3" element={<BlogTemplate3/>} />  
                <Route path="/BlogTemplate4" element={<BlogTemplate4/>} />  
                <Route path="/BlogEdit" element={<BlogsTab/>} />  
                <Route path="/BlogEdit/Template1Edit" element={<Template1Edit/>} /> 
                <Route path="/BlogEdit/Template2Edit" element={<Template2Edit/>} /> 
                <Route path="/BlogEdit/Template3Edit" element={<Template3Edit/>} /> 
                <Route path="/BlogEdit/Template4Edit" element={<Template4Edit/>} /> 
                {/* ... add more routes as needed */}
            </Routes>
        </BrowserRouter>



    </main>
  )
}

export default Main;
