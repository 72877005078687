
import './css/univariety.css';


import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';

import '../node_modules/react-bootstrap/'

import Header from './Component/Header';
import Main from './Component/Main';
import Footer from './Component/Footer';


function App() {

  return (
    <>
      <Header />
      <Main/>
      <Footer />
    </>
  );
}

export default App;
