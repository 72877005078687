import React from 'react';
import FormRegisterPopUp from '../FormRegisterPopUp';

function ParentInformation() {
  return (
    <>
      <div className="parentInformation-detail tab-white-block">
                            {/* <a className="pencil-edit" href="javascript:;" data-bs-toggle="modal" data-bs-target="#basic_info_popup"></a> */}
                            <FormRegisterPopUp className="pencil-edit" />
                            <div className="customRow">
                               <div className="customRow-header">
                                    <div className="icon icon-father"></div>
                                    <div className="customRow-header-title">
                                        <span className="font-20 font-500">Father Details</span>
                                    </div>
                               </div>
                               <div className="customRow-detail">
                                <div className="row g-3">
                                    <div className="col-md-4">
                                        <div className="d-flex flex-column">
                                            <div className="font-16 font-400 lineHeight-22 mb-1">Name</div>
                                            <div className="font-20 font-500 lineHeight-22">Abel George</div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="d-flex flex-column">
                                            <div className="font-16 font-400 lineHeight-22 mb-1">Contact Email</div>
                                            <div className="font-20 font-500 lineHeight-22">abel.g@yopmail.com</div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="d-flex flex-column">
                                            <div className="font-16 font-400 lineHeight-22 mb-1">Contact Mobile</div>
                                            <div className="font-20 font-500 lineHeight-22">+91 8812381781</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>

                            <div className="customRow">
                                <div className="customRow-header">
                                     <div className="icon icon-mother"></div>
                                     <div className="customRow-header-title">
                                         <span className="font-20 font-500">Mother Details</span>
                                     </div>
                                </div>
                                <div className="customRow-detail">
                                    <div className="row g-3">
                                        <div className="col-md-4">
                                            <div className="d-flex flex-column">
                                                <div className="font-16 font-400 lineHeight-22 mb-1">Name</div>
                                                <div className="font-20 font-500 lineHeight-22">Veronica Andrews</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="d-flex flex-column">
                                                <div className="font-16 font-400 lineHeight-22 mb-1">Contact Email</div>
                                                <div className="font-20 font-500 lineHeight-22">veron.a@yopmail.com</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="d-flex flex-column">
                                                <div className="font-16 font-400 lineHeight-22 mb-1">Contact Mobile</div>
                                                <div className="font-20 font-500 lineHeight-22">+91 7781991891</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                             </div>

                             <div className="customRow">
                                <div className="customRow-header">
                                     <div className="icon icon-guardian"></div>
                                     <div className="customRow-header-title">
                                         <span className="font-20 font-500">Guardian Details</span>
                                     </div>
                                </div>
                                <div className="customRow-detail">
                                     <div className="row g-3">
                                         <div className="col-md-4">
                                             <div className="d-flex flex-column">
                                                 <div className="font-16 font-400 lineHeight-22 mb-1">Name</div>
                                                 <div className="font-18 font-500 lineHeight-22">-</div>
                                             </div>
                                         </div>
                                         <div className="col-md-4">
                                             <div className="d-flex flex-column">
                                                 <div className="font-16 font-400 lineHeight-22 mb-1">Contact Email</div>
                                                 <div className="font-18 font-500 lineHeight-22">-</div>
                                             </div>
                                         </div>
                                         <div className="col-md-4">
                                             <div className="d-flex flex-column">
                                                 <div className="font-16 font-400 lineHeight-22 mb-1">Contact Mobile</div>
                                                 <div className="font-18 font-500 lineHeight-22">-</div>
                                             </div>
                                         </div>
                                     </div>
                                </div>
                             </div>                          
                        </div>
    </>
  )
}

export default ParentInformation;
