import React from 'react';
import FormRegisterPopUp from '../FormRegisterPopUp';

function EntranceExams() {
  return (
    <>
      <div className="entranceExams-detail tab-white-block">
                            {/* <a className="pencil-edit" href="javascript:;" data-bs-toggle="modal" data-bs-target="#basic_info_popup"></a> */}
                            <FormRegisterPopUp className="pencil-edit"/>
                            <div className="row g-4">
                                <div className="col-md-3">
                                    <div className="customRow-header">
                                        <div className="icon icon-exam-note"></div>
                                        <div className="customRow-header-title d-flex flex-column justify-content-center lineHeight-22">
                                            <span className="font-20 font-600">JEE Main</span>
                                            <span className="font-16 text-gray-41">Started (Self Study)</span>
                                        </div>
                                   </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="customRow-header">
                                        <div className="icon icon-exam-note"></div>
                                        <div className="customRow-header-title d-flex flex-column justify-content-center lineHeight-22">
                                            <span className="font-20 font-500">ACT</span>
                                            <span className="font-16 text-gray-41">Yet to start preparation</span>
                                        </div>
                                   </div>
                                </div>
                            </div>                                                    
                        </div>
    </>
  )
}

export default EntranceExams;
