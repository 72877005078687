import React from 'react';

import OwlCarousel from 'react-owl-carousel';
import '../../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';

import daisSImg from '../../images/dais.png';
import greenwoodSImg from '../../images/greenwood.png';
import ryanSImg from '../../images/ryan.png';
import testlerSImg from '../../images/testler.png';
import podarSImg from '../../images/podar.png';
import birlaSImg from '../../images/birla.png';
import ssrvmSImg from '../../images/ssrvm.png';
import isdSImg from '../../images/isd.png';
import cambrianHallSImg from '../../images/cambrian-hall.png';
import newEraHighSchoolSImg from '../../images/new-era-high-school.png';

function LeftSideSlider() {

    const options = {
            merge: true,
            rtl:true,
            loop: true,
            video: true,
            lazyLoad: true,
            autoWidth:true,
            nav: false,
            dots: false,
            margin: 20,
            slideTransition: 'linear',
            autoplay:true,
            autoplayTimeout: 5000,
            autoplaySpeed: 5000,            
            autoplayHoverPause:false
        };

  return (
    <>
      <OwlCarousel class="leftsideSlider owl-carousel owl-theme mb-4" id="leftside_carousel" {...options}>
                    
            <div className="item"><div className="logo-block"><img src={daisSImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={greenwoodSImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={ryanSImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={testlerSImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={podarSImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={birlaSImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={ssrvmSImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={isdSImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={cambrianHallSImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={newEraHighSchoolSImg} alt=""/></div></div>

            <div className="item"><div className="logo-block"><img src={daisSImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={greenwoodSImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={ryanSImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={testlerSImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={podarSImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={birlaSImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={ssrvmSImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={isdSImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={cambrianHallSImg} alt=""/></div></div>
            <div className="item"><div className="logo-block"><img src={newEraHighSchoolSImg} alt=""/></div></div>
                    
        </OwlCarousel>
    </>
  )
}

export default LeftSideSlider;
