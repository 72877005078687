import React from 'react';

import OwlCarousel from 'react-owl-carousel';
import '../../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';

import alumniNetworkingManagement from '../../images/alumni-networking-management.svg';
import dataCollection from '../../images/data-collection.svg';
import alumniEngagement from '../../images/alumni-engagement.svg';
import admissionMarketingSupport from '../../images/admission-marketing-support.svg';
import alumniMentorship from '../../images/alumni-mentorship.svg';
import reportsAnalytics from '../../images/reports-analytics.svg';

function AlumniImpactProgramSlider() {
    const options = {
        margin: 20,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: false,
        smartSpeed: 1000,
        responsive: {
            0: { items: 1, dots: true },
            400: { items: 1, dots: true },
            600: { items: 2, dots: true },
            700: { items: 2, dots: true },
            1000: { items: 3, dots: false },
        },
    };
  return (
    <>
        <OwlCarousel className="alumniImpactProgramSlider owl-carousel owl-theme" id="alumniImpactProgram_carousel" {...options}>                    
            <div className="item">
                <div className="alumniImpactProgramBlock">
                    <div className="icon"><img src={alumniNetworkingManagement} width="48" className="img-fluid" alt=""/></div>
                    <h4>Alumni Management & Networking Platform</h4>
                    <p>A dedicated digital platform that connects your School, Students, and Alumni in one ecosystem</p>
                    <a href="https://www.univariety.com/resources/featured-insights/detail.php?name=alumni-networking-management" className="btn-knowmore" target="_blank">KNOW MORE</a>
                </div>
            </div>

            <div className="item">
                <div className="alumniImpactProgramBlock">
                    <div className="icon"><img src={dataCollection} width="48" className="img-fluid" alt=""/></div>
                    <h4>Alumni Deep Data Collection - Reporting & Analytics</h4>
                    <p>Discover your lost alumni and their journeys - college information, competitive exams, career paths, global presence, rewards and accolades.</p>
                    <a href="https://www.univariety.com/resources/featured-insights/detail.php?name=alumni-data-collection" className="btn-knowmore" target="_blank">KNOW MORE</a>
                </div>
            </div>

            <div className="item">
                <div className="alumniImpactProgramBlock">
                    <div className="icon"><img src={alumniEngagement} width="48" className="img-fluid" alt=""/></div>
                    <h4>Alumni Engagement</h4>
                    <p>Following the mantra ‘Give to Get’, re-forge the connection with your Alumni.</p>
                    <a href="https://www.univariety.com/resources/featured-insights/detail.php?name=meaningful-alumni-engagement" className="btn-knowmore" target="_blank">KNOW MORE</a>
                </div>
            </div>

            <div className="item">
                <div className="alumniImpactProgramBlock">
                    <div className="icon"><img src={admissionMarketingSupport} width="48" className="img-fluid" alt=""/></div>
                    <h4>Marketing & Brand Building</h4>
                    <p>Re-imagine your school's success by utilising your alumni stories and making them part of your brand.</p>
                    <a href="https://www.univariety.com/resources/featured-insights/detail.php?name=institute-marketing-and-brand-building" className="btn-knowmore" target="_blank">KNOW MORE</a>
                </div>
            </div>

            <div className="item">
                <div className="alumniImpactProgramBlock">
                    <div className="icon"><img src={admissionMarketingSupport} width="48" className="img-fluid" alt=""/></div>
                    <h4>Admission Support</h4>
                    <p>Train your admission officers to communicate better with millennial parents using your Alumni Success Stories </p>
                    <a href="https://www.univariety.com/resources/featured-insights/detail.php?name=admission-support" className="btn-knowmore" target="_blank">KNOW MORE</a>
                </div>
            </div>

            <div className="item">
                <div className="alumniImpactProgramBlock">
                    <div className="icon"><img src={alumniMentorship} width="48" className="img-fluid" alt=""/></div>
                    <h4>Alumni Mentorship Program</h4>
                    <p>Provide your students with an opportunity to learn from the best and brightest in your alumni community.</p>
                    <a href="https://www.univariety.com/resources/featured-insights/detail.php?name=alumni-mentors-for-student-success" className="btn-knowmore" target="_blank">KNOW MORE</a>
                </div>
            </div>

            <div className="item">
                <div className="alumniImpactProgramBlock">
                    <div className="icon"><img src={dataCollection} width="48" className="img-fluid" alt=""/></div>
                    <h4>Financially Sustainable Model</h4>
                    <p>Alumni Impact Program follows a financial model that becomes self-sustaining over the years. </p>
                    <a href="https://www.univariety.com/resources/featured-insights/detail.php?name=running-financially-sustainable-alumni-program" className="btn-knowmore" target="_blank">KNOW MORE</a>
                </div>
            </div>

            <div className="item">
                <div className="alumniImpactProgramBlock">
                    <div className="icon"><img src={reportsAnalytics} width="48" className="img-fluid" alt=""/></div>
                    <h4>Alumni Value Calculator</h4>
                    <p>Calculate how much your Alumni are worth.</p>
                    <a href="https://www.univariety.com/app/alumniValue" className="btn-knowmore" target="_blank">KNOW MORE</a>
                </div>
            </div>
        </OwlCarousel>
    </>
  )
}

export default AlumniImpactProgramSlider;
