import React from 'react';

function PublishedArticles() {
  return (
    <>
            <div className="pageHeader edit-header">
              <div className="row justify-content-between">
                <div className="col-auto">
                  <h1 className="text-dark font-24">Published Articles</h1>
                </div>
           
                
              </div>
            </div>

            <div className="template-date-table">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" width="80">Sr. No</th>
                        <th scope="col" width="500" className="text-start">Article Name</th>
                        <th scope="col" className="text-start">Date</th>
                        <th scope="col" width="200" className="text-end pe-4">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><div>1.</div></td>
                        <td className="text-start"><div><b>The Real Gully Boy</b></div></td>
                        <td className="text-start"><div><b>5-09-2022</b></div></td>
          
                        <td>
                          <div className="d-flex flex-wrap justify-content-end align-items-center pe-3">
                            <a className="btn-active" href="javascript:;">Active</a>
                            
                            
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td><div>2.</div></td>
                        <td className="text-start"><div><b>Five Star Chef From Dharavi - An Alumni Story</b></div></td>
                        <td className="text-start"><div><b>5-09-2022</b></div></td>          
                        <td>
                          <div className="d-flex flex-wrap justify-content-end align-items-center pe-3">
                            <a className="btn-deactive" href="javascript:;">Deactive</a>                                                   
                          </div>
                        </td>
                      </tr>
        
                      <tr>
                        <td><div>3.</div></td>
                        <td className="text-start"><div><b>The Career Counsellor Advise</b></div></td>
                        <td className="text-start"><div><b>5-09-2022</b></div></td>
          
                        <td>
                          <div className="d-flex flex-wrap justify-content-end align-items-center pe-3">
                            <a className="btn-active" href="javascript:;">Active</a>
                            
                            
                          </div>
                        </td>
                      </tr>
        
                     
                    </tbody>
                  </table>
                </div>
              </div>
            
          
    </>
  )
}

export default PublishedArticles;
