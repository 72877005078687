import React from 'react';

import BannerImg from '../../../images/plane-img.png';
import DummyAddImgLg from '../../../images/added-img-lg.png';

function BlogTemplate4() {
  return (
    <>
      <div className="bg-gray-f5 template_4">
      

      <div className="container-fluid">
          <div className="container max-1210 py-100">
              <div className="row">
          
                  <div className="col-lg-6 col-md-7 mb-5 mb-md-0">
                      <div className="pe-md-4">
                          <h1 className="title mb-2 mb-sm-4">Taking A Leaf<br className="d-none d-lg-block"/> Out Of the Indigo Airlines Approach</h1>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus, ac blandit elit tincidunt id. Sed rhoncus, tortor sed eleifend tristique, tortor mauris molestie elit, et lacinia ipsum quam nec dui. Quisque nec mauris sit amet elit iaculis pretium sit amet quis magna. Aenean velit odio, elementum in tempus ut, vehicula eu diam. </p>
                          <p>Pellentesque rhoncus aliquam mattis. Ut vulputate eros sed felis sodales nec vulputate justo hendrerit. Vivamus varius pretium ligula, a aliquam odio euismod sit amet. Quisque laoreet sem sit amet orci ullamcorper at ultricies metus viverra. Pellentesque arcu mauris, malesuada quis ornare accumsan, blandit sed diam.</p>
                          <a className="btn btn-green mt-2" href="javascript:;" data-bs-toggle="modal" data-bs-target="#downloadForm">Stories From My School</a>
                      </div>
                  </div>
                  <div className="col-lg-5 col-md-5" data-img="480px * 501px">
                      <img src={BannerImg} className="img-fluid" width="570"/>
                  </div>
              </div>
          </div>
          <div className="customfooter">

          </div>
      </div>

      <div className="fold-wrap">

       

            <div className="fold-section p-0">
          
              <div className="fold-section-ui">
                  <div className="row">
                      <div className="col-md-6 mb-4 mb-md-0 text-center" data-img="550px * 450px">
                          <img src={DummyAddImgLg} className="img-fluid" width="550"/>
                      </div>
                 
                      <div className="col-md-6">
                          <h1>Heading Goes Here</h1>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus…</p>
                          <ul className="listed-points">
                              <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                              <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                              <li>Lorem ipsum dolor sit amet, consectetur.</li>
                              <li>Lorem ipsum dolor sit amet, consectetur.</li>
                          </ul>
                          <a className="btn-green" href="javascript:;" data-bs-toggle="modal" data-bs-target="#downloadForm">CTA BUTTON</a>
                      </div>
                      
                  </div>
              </div>
            </div>

      </div>

      
  </div>
    </>
  )
}

export default BlogTemplate4;
